import React, { useEffect, useState } from 'react';
import { useStore } from '../../store';


interface CandlestickChartProps {
  rank: string;
  address: string;
  activeReferrals: number;
  totalReferrals: number;
}

const CandlestickChart: React.FC<CandlestickChartProps> = ({ rank, address, activeReferrals, totalReferrals }) => {
  const {
    isDarkMode,
  } = useStore();

  
  
  // const [displayTradeVolume, setDisplayTradeVolume] = useState<string>('');
  // useEffect(() => {
  // if (tradeVolume >= 1000000000000) {
  //   setDisplayTradeVolume(Math.floor((tradeVolume / 1000000000000) * 10) / 10 + 'T');
  // }
  // else if (tradeVolume >= 1000000000) {
  //   setDisplayTradeVolume(Math.floor((tradeVolume / 1000000000) * 10) / 10 + 'B');
  // }
  // else if (tradeVolume >= 1000000) {
  //   setDisplayTradeVolume(Math.floor((tradeVolume / 1000000) * 10) / 10 + 'M');
  // }
  // else if (tradeVolume >= 1000) {
  //   setDisplayTradeVolume(Math.floor((tradeVolume / 1000) * 10) / 10 + 'K');
  // }
  // else {
  //   setDisplayTradeVolume(Math.floor(tradeVolume * 10) / 10 + '');
  // }
  // }, [tradeVolume]);
 
       
  return (
  <div className={`w-[100%] px-[1.5rem] py-[1.5rem] font-[400] ${isDarkMode ? 'text-[#A5A5A6] border-[#E3E8EF]' : 'text-[#FFFFFF] border-[#FFFFFF1A]'} border-b-2  flex h-[7.6%] items-center sm:text-[0.7rem] sm:px-3`}>
    <div className='w-[8%] flex justify-start sm:w-[7%]'>{rank}</div>
    <a href={`https://explorer.aptoslabs.com/account/${address}?network=mainnet`} target='blank' className='w-[40%] flex justify-center overflow-hidden sm:w-[31%] cursor-pointer'>{address?.slice(0, 13) + '..' + address?.toString()?.slice(-13)}</a>
    <div className='w-[26%] flex justify-end sm:w-[31%]'>{`${activeReferrals ? activeReferrals : 0}`}</div>
    <div className='w-[26%] flex justify-end sm:w-[31%]'>{`${totalReferrals ? totalReferrals : 0}`}</div>
  </div>
  );
};

export default CandlestickChart;