import React, { useState } from 'react';
import kana from '../../assets/icons/kanalabs-icon.svg';
import { useTranslation } from "react-i18next";
import SwapDark from '../../assets/icons/swap_dark.svg';
import LeaderboardDark from '../../assets/icons/leader_board_dark.svg';
import Trade_dark from '../../assets/icons/bar-chart-square-dark.svg';
import ReferralDark from '../../assets/icons/referral_dark.svg';
import ChevronDownDark from '../../assets/icons/chevron-down-dark.svg';
import StakeDarkIcon from '../../assets/icons/stack_icon_dark.svg';
import { useStore } from '../../store';
import EconiaImg from '../../assets/icons/Econia-Primary-Logo-White.svg';
import StakeIcon from '../../assets/icons/stack_icon.svg';
import Swap from '../../assets/icons/swap.svg';
import Referral from '../../assets/icons/referral.svg';
import Leaderboard from '../../assets/icons/leader_board.svg';
import BarMenu from '../../assets/icons/bar_menu.svg';
import WrongRed from '../../assets/icons/x-close.svg';
import ChevronDown from '../../assets/icons/chevron-down.svg';
import EconiaBg from '../../assets/icons/econia_bg.svg';
import SettingDark from '../../assets/icons/sun.svg';
import SettingGreen from '../../assets/icons/moon.svg';
import BarMenuDark from '../../assets/icons/bar_menu_dark.svg';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import ArrowLeftDark from '../../assets/icons/arrow-left-dark.svg';
import SwitchDark from '../../assets/icons/switch_dark.svg';
import Switch from '../../assets/icons/switch_icon.svg';
import TradeIcon from '../../assets/icons/trade_icon.svg';

interface HeaderInterface {
    openDeposit: any;
    handleCloseDeposit(): void;
}

const LeaderboardHeader = (props: HeaderInterface) => {
    const { t } = useTranslation();
    const { isDarkMode, updateIsDarkMode } = useStore()
    const [activeItemSidebar, setActiveItemSidebar] = useState(1);
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
    const handleMobileMenu = () => {
        setIsMobileMenuActive(!isMobileMenuActive);
    }

    const handleClick = (id: any) => {
        if (id === 1) {
            if (id === activeItemSidebar) {
                return
            } else {
                setActiveItemSidebar(id);
            }
        } else {
            setActiveItemSidebar(id);
        }
    }
    const [isLeaderBoard, setIsLeaderBoard] = useState(false);
    const handleLeaderBoard = () => {
        if (isLeaderBoard === false) {
            setIsLeaderBoard(true);
        } else {
            setIsLeaderBoard(false);
        }
    }
    return (
        <>
            <div className={`${isDarkMode ? "!bg-[#FCFDFE]" : "!bg-[#111213]"} h-auto z-[1]  !fixed !top-0 flex flex-col justify-between items-start !font-inter w-full xxl:ml-4 bxl:ml-4 xl:ml-4 sxl:ml-4 lg:ml-0 md:ml-0 sm:ml-0 xd:ml-0 `}>
                <div className="!h-24 flex flex-row  items-center justify-between  w-full xxl:!px-4 bxl:!px-4 xl:!px-4 sxl:!px-4 lg:!px-0 md:!px-0 w-full ">
                    <div className=" xxl:ml-16 bxl:ml-16 xl:ml-16 sxl:ml-16 lg:ml-0 md:ml-0 sm:ml-0 xd:ml-0 !gap-[1rem]  xxl:p-[1rem] bxl:p-[1rem] xl:p-[1rem] sxl:p-[1rem] lg:p-[1rem] md:py-[1rem] sm:py-[1rem] xd:py-[1rem]  flex flex-row    !items-center !justify-between top-[0] w-full      ">
                        <a href='/'
                            className={`${isDarkMode ? '!bg-[#EFF7F8] text-[#0E9384]' : '!bg-[#1D1E20] text-[#2ED3B7]'} common-pointer  !w-[25%]   xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-row justify-between items-center gap-[0.5rem]     cursor-pointer   h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]    font-inter font-[800]  text-[0.875rem]`}
                        >
                            <div className='flex flex-row  justify-start items-center gap-[20px] '>
                                {isDarkMode ? <img src={ArrowLeftDark} alt='UserCircle' className='!bg-transparent' /> : <img src={ArrowLeft} alt='UserCircle' className='!bg-transparent' />}
                                Use Trade
                            </div>
                        </a>
                        <div className=" px-[1rem]  !w-full justify-between items-center xxl:!hidden max-xxl:!hidden bxl:!hidden xl:!hidden sxl:!hidden lg:!flex md:!flex sm:!flex xd:!flex lg:!flex-row gap-[1rem]">
                            <img src={kana} alt="kana-logo" className='w-[2.5rem] h-[2.5rem]' />
                            <a href='/'
                                className={`${isDarkMode ? '!bg-[#EFF7F8] text-[#0E9384]' : '!bg-[#1D1E20] text-[#2ED3B7]'} common-pointer  !w-[80%]    flex flex-row justify-between items-center gap-[0.5rem]     cursor-pointer   h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]    font-inter font-[800]  text-[0.875rem]`}
                            >
                                <div className='flex flex-row  justify-start items-center gap-[20px] '>
                                    {isDarkMode ? <img src={ArrowLeftDark} alt='UserCircle' className='!bg-transparent' /> : <img src={ArrowLeft} alt='UserCircle' className='!bg-transparent' />}
                                    Use Trade
                                </div>
                            </a>
                            <div
                                className="text-[2rem] !float-right text-[rgba(255,255,255,0.6)] cursor-pointer xxl:!hidden xl:hidden lg:flex md:flex sm:flex"
                                onClick={handleMobileMenu}
                            >
                                {isMobileMenuActive ? <img src={isDarkMode ? WrongRed : WrongRed} alt='WrongRed' /> : <img src={isDarkMode ? BarMenuDark : BarMenu} alt='BarMenu' />}
                            </div>
                        </div>
                        <div
                            className="xxl:flex  xl:flex lg:flex md:hidden sm:hidden max-sm:hidden"
                            onClick={() => updateIsDarkMode(!isDarkMode)}
                        >
                            <div
                                className={`${isDarkMode ? 'bg-[#EFF7F8] !rounded-[1rem] ' : 'bg-[#1D1E20] !rounded-[1rem] '
                                    } w-[3.875rem]     h-[3.875rem]  flex justify-center items-center  cursor-pointer 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden max-sm:hidden`}
                            >
                                <img src={isDarkMode ? SettingGreen : SettingDark} alt="sun" />
                            </div>
                        </div>
                    </div>
                </div>
                {isMobileMenuActive ? (
                    <ul
                        className={`flex-1 flex flex-col w-full justify-between items-start py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:flex md:flex md:pb-0 md:mt-24 sm:mt-24 xd:mt-24 z-[9] ${isDarkMode ? '!bg-[#FCFDFE]' : '!bg-[#17181A]'} ${isMobileMenuActive ? '!fixed !right-0 !left-0   lg:!bottom-0 md:!bottom-24 sm:!bottom-24 xd:!bottom-24 !top-0	 ' : 'hidden'
                            }`}
                    >
                        <div className="flex flex-col  items-start justify-between align-middle  h-full  w-full lg:!px-[1rem] md:!px-[1rem] sm:!px-[1rem] xd:!px-[1rem] !bg-transparent">
                            <div className='flex flex-col justify-start items-start bg-transparent w-full gap-[8px]'>
                                <a rel="noreferrer" href=' https://app.kanalabs.io/' className='w-full flex  h-[52px] flex-row justify-start items-center    bg-transparent gap-[16px]'>
                                    <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 5
                                        ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                                        : "opacity-[0.5]"
                                        }`}
                                    >
                                        <div className=' flex flex-row justify-start items-center gap-[16px]'>
                                            <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? SwapDark : Swap} alt="TradeIcon" />
                                            <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('swap')}</div>
                                        </div>
                                    </div>
                                </a>

                                <a href='/' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                                    <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 7
                                        ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                                        : "opacity-[0.5]"
                                        }`}
                                    >
                                        <div className=' flex flex-row justify-start items-center gap-[16px]'>
                                            <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? Trade_dark : TradeIcon} alt="TradeIcon" />
                                            <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Trade')}</div>
                                        </div>
                                    </div>
                                </a>
                                <a rel="noreferrer" href='https://switch.kanalabs.io/' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                                    <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 4
                                        ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                                        : "opacity-[0.5]"
                                        }`}
                                    >
                                        <div className=' flex flex-row justify-start items-center gap-[16px]'>
                                            <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? SwitchDark : Switch} alt="TradeIcon" />
                                            <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Switch')}</div>
                                        </div>
                                    </div>
                                </a>
                                <a rel="noreferrer" href='https://app.kanalabs.io/stake-yield' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                                    <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                                        ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                                        : "opacity-[0.5]"
                                        }`}
                                    >
                                        <div className=' flex flex-row justify-start items-center gap-[16px]'>
                                            <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? StakeDarkIcon : StakeIcon} alt="TradeIcon" />
                                            <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Stake')}</div>
                                        </div>
                                    </div>
                                </a>

                                <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 1
                                    ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                                    : "opacity-[0.5]"
                                    }`} onClick={() => handleClick(1)}
                                >
                                    <div className=' flex flex-row justify-between w-full items-center' onClick={handleLeaderBoard}>
                                        <div className=' flex flex-row justify-start items-center gap-[16px]'>
                                            <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                                            <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Leaderboard</div>
                                        </div>
                                        <img src={isDarkMode ? ChevronDownDark : ChevronDown} alt="ChevronDown" className={isLeaderBoard ? ' rotate-180 ml-2' : ' ml-2 rotate-0'} />
                                    </div>
                                </div>
                                {isLeaderBoard && <div className="submenu w-full">
                                    <a rel="noreferrer" href='https://app.kanalabs.io/leader-board' className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]`}>
                                        <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 6
                                            ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                                            : "opacity-[0.5]"
                                            }`} onClick={() => handleClick(6)}
                                        >
                                            <div className=' flex flex-row justify-start items-center gap-[16px]'>
                                                <img className='bg-transparent  !h-[1rem] opacity-0' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                                                <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Swap Leaderboard</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a rel="noreferrer" href='/leaderboard' className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px] `}>
                                        <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 1
                                            ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                                            : "opacity-[0.5]"
                                            }`} onClick={() => handleClick(1)}
                                        >
                                            <div className=' flex flex-row justify-start items-center gap-[16px]'>
                                                <img className='bg-transparent  !h-[1rem] opacity-0' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                                                <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Trade Leaderboard</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                }

                                <a rel="noreferrer" href='https://app.kanalabs.io/referrals' className='w-full  h-[52px] flex flex-row justify-start items-center  bg-transparent gap-[16px]'>
                                    <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 8
                                        ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                                        : "opacity-[0.5]"
                                        }`} onClick={() => handleClick(8)}
                                    >
                                        <div className=' flex flex-row justify-start items-center gap-[16px]'>
                                            <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? ReferralDark : Referral} alt="TradeIcon" />
                                            <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Referral</div>
                                        </div>
                                    </div>
                                </a>

                            </div>

                            <div
                                className="xxl:hidden w-full xl:hidden lg:hidden md:flex sm:flex max-sm:flex  "
                                onClick={() => updateIsDarkMode(!isDarkMode)}
                            >
                                <div

                                    className={`${isDarkMode ? 'bg-[#EFF7F8] !rounded-[2rem] text-[#0C0C0D] ' : 'bg-[#1D1E20] !rounded-[2rem] text-[#FFF]'
                                        } w-[100%]  font-[800]  h-[3.875rem]  py-[1rem] px-[1.5rem] flex flex-row justify-between gap-[1rem] items-center  cursor-pointer `}
                                >
                                    <div>Theme</div>
                                    <img src={isDarkMode ? SettingGreen : SettingDark} alt="sun" />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-center items-center gap-[1rem] w-full '>
                            <div className={`${isDarkMode ? 'text-[#A5A5A6]' : 'text-[rgba(255,255,255,0.50)]'} text-[12px]  py-5 font-inter`}>Powered by</div>
                            <div>
                                {isDarkMode ?
                                    <img src={EconiaBg} alt='EconiaImg' className='w-[6.6rem] h-[1rem]' /> :
                                    <img src={EconiaImg} alt='EconiaImg' className='w-[6.6rem] h-[1rem]' />
                                }
                            </div>
                        </div>
                    </ul>
                ) : (
                    <ul></ul>
                )}

            </div >

        </>
    );
};

export default LeaderboardHeader;