import React, { useEffect, useState } from 'react';
import { Button } from '../components';
import WithdrawModel from './WithdrawModel';
import AddCurrencyModel from './AddCurrencyModel';
import PowerButton from '../assets/icons/powerbutton.svg';
import Copy from '../assets/icons/copy.svg';
import kana from '../assets/icons/kanalabs-icon.svg';
import DropdownMenu from '../pages/DropdownMenu';
import { useTranslation } from "react-i18next";
import SwapDark from '../assets/icons/swap_dark.svg';
import LeaderboardDark from '../assets/icons/leader_board_dark.svg';
import Trade_dark from '../assets/icons/bar-chart-square-dark.svg';
import ReferralDark from '../assets/icons/referral_dark.svg';
import ChevronDownDark from '../assets/icons/chevron-down-dark.svg';
import OtherProductsDark from '../assets/icons/other_products_dark.svg';
import StakeDarkIcon from '../assets/icons/stack_icon_dark.svg';
import Deposit from './Deposit';
import { useAptosContext } from '../providers/AptosWalletContext';
import ConnectWallet from './ConnectWallet';
import { useStore } from '../store';
import { toast } from 'react-toastify';
import EconiaImg from '../assets/icons/Econia-Primary-Logo-White.svg';
import SwitchVertical from '../assets/icons/switch-vertical.svg';
import UserCircle from '../assets/icons/user-circle.svg';
import UserCircleWhite from '../assets/icons/user-circle-white.svg';
import StakeIcon from '../assets/icons/stack_icon.svg';
import Swap from '../assets/icons/swap.svg';
import Referral from '../assets/icons/referral.svg';
import Leaderboard from '../assets/icons/leader_board.svg';
import { CopyToClipboard } from "react-copy-to-clipboard";
import NewMessageNotification from '../components/NewMessageNotification';
import { handleConnectedWalletNetwork, handleGetShortenURL, isObjectNonEmpty } from '../utils/helper';
import Facebook from "../assets/icons/facebook__waitlist.svg";
import Twitter from "../assets/icons/twitter__waitlist.svg";
import Google from "../assets/icons/google__waitlist.svg";
import Discord from "../assets/icons/discord_white.svg";
import UserCircleGrey from '../assets/icons/user_circle_grey.svg'
import UserCircleGreen from '../assets/icons/user_circle_green.svg'
import BarMenu from '../assets/icons/bar_menu.svg';
import WrongRed from '../assets/icons/x-close.svg';
import ChevronDown from '../assets/icons/chevron-down.svg';
import OtherProducts from '../assets/icons/other_products.svg';
import userCircleDark from '../assets/icons/user-circle-black.svg';
import UserCircleGreenDark from '../assets/icons/user-circle-dark-green.svg';
import EconiaBg from '../assets/icons/econia_bg.svg';
import ArrowVertical from '../assets/icons/arrow_vertical.svg';
import SettingDark from '../assets/icons/sun.svg';
import SettingGreen from '../assets/icons/moon.svg';
import BarMenuDark from '../assets/icons/bar_menu_dark.svg';
import copyDark from '../assets/icons/copy_dark.svg';
import Settings from './Settings';
import SwitchDark from '../assets/icons/switch_dark.svg';
import Switch from '../assets/icons/switch_icon.svg';
import TradeIcon from '../assets/icons/trade_icon.svg';

import { econia } from '../utils/Constants';
import { ENVIRONMENT, eventId, subscribeEvents } from '@kanalabs/trade';
import { useQuery } from '@tanstack/react-query';
import ReferralContest from './ReferralContest';
// import ConnectSocialLogin from './ConnectSocialLogin';
interface HeaderInterface {
  openDeposit: any;
  handleCloseDeposit(): void;
}

const Header = (props: HeaderInterface) => {
  const { openDeposit, handleCloseDeposit } = props;
  const { t } = useTranslation();

  const { updateNeedToUpdateGlobalTrades, isDarkMode, address: socialAddress, updateIsDarkMode, sdk, aptosWeb3Instance, updateAddress, updateSdk, isWalletConnected, isLoading: isLoadingFromStore } = useStore()
  const { connected, disconnect, wallet, account }: any = useAptosContext()
  const [isWithdrawClicked, setIsWithdrawClicked] = useState(false);
  const [isAddCurrencyClicked, setIsAddCurrencyClicked] = useState(false);
  const [isDepositClicked, setIsDepositClicked] = useState(false);
  const [isConnectWalletisClicked, setIsConnectWalletClicked] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<any>();
  const [activeItemSidebar, setActiveItemSidebar] = useState(1);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [isMobileButtonsActive, setIsMobileButtonActive] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [isChevronCorrect, setIsChevronCorrect] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isReferralContestOpen, setIsReferralContestOpen] = useState<boolean>(true);
  const [referralURL, setReferralURL] = useState<string>('');
  // const [openSocialWallet, setOpenSocialWallet] = useState<boolean>(false);

  useEffect(() => {
    if (openDeposit) {
      setIsDepositClicked(true)
    } else {
      setIsDepositClicked(false)
      handleCloseDeposit()
    }
  }, [openDeposit])

  const handleDeposit = () => {
    toast.dismiss()
    if (isLoadingFromStore) return
    if (connected) {
      const { error } = handleConnectedWalletNetwork(wallet)
      if (error) {
        toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Switch Network From Testnet To  Mainnet  in ${wallet.adapter.name} extension and try again!`} />);
        disconnect()
        return;
      }
      setIsWithdrawClicked(false);
      setIsDepositClicked(true);
    }
    else if (aptosWeb3Instance.status === 'connected') {
      setIsWithdrawClicked(false);
      setIsDepositClicked(true);
    } else {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Connect Wallet`} />);
    }
  }

  const handleDisconnect = () => {
    if (isLoadingFromStore) return
    if (connected) {
      disconnect()
      return
    } else if (aptosWeb3Instance.status === 'connected') {
      aptosWeb3Instance.logout();
      updateAddress("")
      updateSdk({})
    }
  }

  const handleConnect = () => {
    setIsConnectWalletClicked(true);
  }

  const handleCloseWalletConnect = (type: string) => {
    updateNeedToUpdateGlobalTrades(true);
    setIsConnectWalletClicked(false);
    // if (type === 'social') {
    //   setOpenSocialWallet(true)
    // }
  };

  // const handleCloseSocialWallet = () => {
  //   setOpenSocialWallet(false)
  // }
  const handleCloseModal = (value: boolean) => {
    if (value) {
      setIsWithdrawClicked(false);
      setIsDepositClicked(false);
      handleCloseDeposit()
    }
  }

  const handleWithDrawModal = (value: boolean) => {
    if (value) {
      setIsDepositClicked(false);
      handleCloseDeposit()
      setIsWithdrawClicked(true);
    }
  }

  const handleDepositModal = (value: boolean) => {
    if (value) {
      setIsWithdrawClicked(false);
      setIsDepositClicked(true);
    }
  }

  const onCopy = async () => {
    toast.dismiss()
    toast(<NewMessageNotification link={"none"} type={"success"} message={`Address Copied!`} />);
  };

  const handleGetLogo = async () => {
    const userInfo = await aptosWeb3Instance.getUserInfo()
    switch (userInfo.typeOfLogin) {
      case 'google': setProfileImage(Google);
        break;
      case 'facebook': setProfileImage(Facebook);
        break;
      case 'twitter': setProfileImage(Twitter);
        break;
      default: setProfileImage(Discord)
        break;
    }
  }
  useEffect(() => {
    if (isWalletConnected)
      handleGetLogo()
  }, [isWalletConnected])

  const handleMobileButton = () => {
    setIsMobileMenuActive(false);
    setIsMobileButtonActive(!isMobileButtonsActive);
  }

  const handleMobileMenu = () => {
    setIsMobileButtonActive(false);
    setIsMobileMenuActive(!isMobileMenuActive);
  }

  const handleClick = (id: any) => {
    if (isLoadingFromStore) return
    if (id === 1) {
      if (id === activeItemSidebar) {
        return
      } else {
        setActiveItemSidebar(id);
      }
    }
    if (id === 2) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
    if (id === 3) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
    if (id === 4) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
    if (id === 5) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
    if (id === 6) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
    if (id === 7) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
  }

  const handleSubMenu = () => {
    if (isSubMenu === false) {
      setIsChevronCorrect(!isChevronCorrect);
      setIsSubMenu(true);
    } else {
      setIsChevronCorrect(!isChevronCorrect);
      setIsSubMenu(false);
    }
  }

  const handleCloseSettings = () => {
    setIsSetting(false)
  }

  const [isLeaderBoard, setIsLeaderBoard] = useState(false);

  const handleLeaderBoard = () => {
    if (isLeaderBoard === false) {
      setIsLeaderBoard(true);
    } else {
      setIsLeaderBoard(false);
    }
  }

  const handleReferralContestClose = () => {
    setIsReferralContestOpen(false)
  }

  // const handleGetReferralURL = async () => {
  //   const { error, data } = await handleGetShortenURL(account.address)
  //   if (error) return
  //   setReferralURL(data)
  //   setIsReferralContestOpen(true)
  // }

  // useEffect(() => {
  //   if (connected) {
  //     handleGetReferralURL()
  //     return
  //   }
  //   setIsReferralContestOpen(false)
  // }, [connected])



  return (
    <>
      <div className={`${isDarkMode ? "!bg-[#FCFDFE]" : "!bg-[#111213]"} h-auto z-[1]  !fixed !top-0 flex flex-col justify-between items-start !font-inter w-full xxl:ml-4 bxl:ml-4 xl:ml-4 sxl:ml-4 lg:ml-0 md:ml-0 sm:ml-0 xd:ml-0 `}>
        <div className="!h-24 flex flex-row  items-center justify-between  w-full xxl:!px-4 bxl:!px-4 xl:!px-4 sxl:!px-4 lg:!px-0 md:!px-0 w-full ">
          <div className=" xxl:ml-16 bxl:ml-16 xl:ml-16 sxl:ml-16 lg:ml-0 md:ml-0 sm:ml-0 xd:ml-0 !gap-[1rem]  xxl:p-[1rem] bxl:p-[1rem] xl:p-[1rem] sxl:p-[1rem] lg:p-[1rem] md:py-[1rem] sm:py-[1rem] xd:py-[1rem]  flex flex-row    !items-center !justify-start top-[0] w-full      ">
            <div className='  xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden !w-[25%]'>
              <DropdownMenu />
            </div>
            {/* <div className='   xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden !w-[20%]'>
              <FaucetDropdownMenu />
            </div> */}
            <Button
              className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} && ${isDarkMode ? '!bg-[#EFF7F8] text-[#0E9384]' : '!bg-[#1D1E20] text-[#2ED3B7]'} !w-[25%] font-[800]  xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-row justify-start items-center gap-[8px]   h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]    font-inter   text-[0.875rem]`}
              onClick={() => handleDeposit()}
            >
              {isDarkMode ? <img src={ArrowVertical} alt='ArrowVertical' className='!bg-transparent' /> : <img src={SwitchVertical} alt='ArrowVertical' className='!bg-transparent' />}
              Deposit / Withdraw
            </Button>
            <Button
              onClick={() => isWalletConnected || connected ? '' : handleConnect()}
              className={`${isDarkMode ? '!bg-[#EFF7F8] text-[#0E9384]' : '!bg-[#1D1E20] text-[#2ED3B7]'} common-pointer  !w-[25%]   xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-row justify-between items-center gap-[8px]     cursor-pointer   h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]    font-inter font-[800]  text-[0.875rem]`}
            >
              {isWalletConnected || connected ?
                <div className='flex flex-row justify-start items-center gap-[0.5rem]'>
                  <div className='!w-5 !h-5' ><img src={connected ? wallet?.adapter?.icon : profileImage} alt='wallet' /></div>
                  {connected ? account?.address?.slice(0, 7) + '..' + account?.address?.toString()?.slice(-7) : socialAddress?.slice(0, 7) + '..' + socialAddress?.toString()?.slice(-7)}
                  <CopyToClipboard
                    text={connected ? account.address : socialAddress}
                    onCopy={() => onCopy()}
                  >
                    <img src={isDarkMode ? copyDark : Copy} alt='copy' />
                  </CopyToClipboard>
                  <img src={PowerButton} alt='PowerButton' className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => isWalletConnected || connected ? handleDisconnect() : ''} />
                </div>
                : <div className='flex flex-row justify-start items-center gap-[20px] '>
                  {isDarkMode ? <img src={UserCircleWhite} alt='UserCircle' className='!bg-transparent' /> : <img src={UserCircle} alt='UserCircle' className='!bg-transparent' />}
                  Connect Wallet
                </div>
              }
            </Button>
            <div className={`${isDarkMode ? '!bg-[#EFF7F8]' : '!bg-[#1D1E20]'} !w-[25%] xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-row justify-start items-center gap-[8px]   h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]  `}>
              <div className={`${isDarkMode ? 'text-[#A5A5A6]' : 'text-[rgba(255,255,255,0.50)]'} text-[12px]   font-inter flex flex-row justify-start items-center gap-[0.2rem]`}><div>Powered</div><div>by</div> </div>
              <div>
                {isDarkMode ?
                  <img src={EconiaBg} alt='EconiaImg' className='w-[6.6rem] h-[1rem]' /> :
                  <img src={EconiaImg} alt='EconiaImg' className='w-[6.6rem] h-[1rem]' />
                }
              </div>
            </div>

            <div className=" px-[1rem]  !w-full justify-between items-center xxl:!hidden max-xxl:!hidden bxl:!hidden xl:!hidden sxl:!hidden lg:!flex md:!flex sm:!flex xd:!flex lg:!flex-row gap-[1rem]">
              <img src={kana} alt="kana-logo" className='w-[2.5rem] h-[2.5rem]' />
              <div className='md:flex sm:flex xd:flex w-[60%]'>
                <DropdownMenu />
              </div>
              <div
                className="text-[2rem] !float-right text-[rgba(255,255,255,0.6)] cursor-pointer xxl:!hidden xl:hidden lg:flex md:flex sm:flex"
                onClick={handleMobileButton}
              >
                {isMobileButtonsActive ? <img src={isDarkMode ? UserCircleGreenDark : UserCircleGreen} alt='WrongRed' /> : <img src={isDarkMode ? userCircleDark : UserCircleGrey} alt='BarMenu' />}
              </div>
              <div
                className="text-[2rem] !float-right text-[rgba(255,255,255,0.6)] cursor-pointer xxl:!hidden xl:hidden lg:flex md:flex sm:flex"
                onClick={handleMobileMenu}
              >
                {isMobileMenuActive ? <img src={isDarkMode ? WrongRed : WrongRed} alt='WrongRed' /> : <img src={isDarkMode ? BarMenuDark : BarMenu} alt='BarMenu' />}
              </div>
            </div>
            <div
              className="xxl:flex  xl:flex lg:flex md:hidden sm:hidden max-sm:hidden"
              onClick={() => updateIsDarkMode(!isDarkMode)}
            // onClick={() => setIsSetting(true)}
            >
              <div
                className={`${isDarkMode ? 'bg-[#EFF7F8] !rounded-[1rem] ' : 'bg-[#1D1E20] !rounded-[1rem] '
                  } w-[3.875rem]     h-[3.875rem]  flex justify-center items-center  cursor-pointer 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden max-sm:hidden`}
              >
                <img src={isDarkMode ? SettingGreen : SettingDark} alt="sun" />
              </div>
            </div>
          </div>
        </div>
        {isMobileMenuActive ? (
          <ul
            className={`flex-1 flex flex-col w-full justify-between items-start py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:flex md:flex md:pb-0 md:mt-24 sm:mt-24 xd:mt-24 z-[9] ${isDarkMode ? '!bg-[#FCFDFE]' : '!bg-[#17181A]'} ${isMobileMenuActive ? '!fixed !right-0 !left-0   lg:!bottom-0 md:!bottom-24 sm:!bottom-24 xd:!bottom-24 !top-0	 ' : 'hidden'
              }`}
          >
            <div className="flex flex-col  items-start justify-between align-middle  h-full  w-full lg:!px-[1rem] md:!px-[1rem] sm:!px-[1rem] xd:!px-[1rem] !bg-transparent">
              <div className='flex flex-col justify-start items-start bg-transparent w-full gap-[8px]'>
                <a rel="noreferrer" href=' https://app.kanalabs.io/' className='w-full flex  h-[52px] flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 5
                    ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? SwapDark : Swap} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('swap')}</div>
                    </div>
                  </div>
                </a>

                <div className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 1
                    ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? Trade_dark : TradeIcon} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Trade')}</div>
                    </div>
                  </div>
                </div>
                <a rel="noreferrer" href='https://switch.kanalabs.io/' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 4
                    ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? SwitchDark : Switch} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Switch')}</div>
                    </div>
                  </div>
                </a>
                <a rel="noreferrer" href='https://app.kanalabs.io/stake-yield' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                    ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? StakeDarkIcon : StakeIcon} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Stake')}</div>
                    </div>
                  </div>
                </a>

                <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 2
                  ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(2)}
                >
                  <div className=' flex flex-row justify-between w-full items-center' onClick={handleLeaderBoard}>
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Leaderboard</div>
                    </div>
                    <img src={isDarkMode ? ChevronDownDark : ChevronDown} alt="ChevronDown" className={isLeaderBoard ? ' rotate-180 ml-2' : ' ml-2 rotate-0'} />
                  </div>
                </div>
                {isLeaderBoard && <div className="submenu w-full">
                  <a rel="noreferrer" href='https://app.kanalabs.io/leader-board' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                    <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 6
                      ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                      : "opacity-[0.5]"
                      }`} onClick={() => handleClick(6)}
                    >
                      <div className=' flex flex-row justify-start items-center gap-[16px]'>
                        <img className='bg-transparent  !h-[1rem] opacity-0' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                        <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Swap Leaderboard</div>
                      </div>
                    </div>
                  </a>
                  <a rel="noreferrer" href='https://tradebook.kanalabs.io/leaderboard' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                    <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 7
                      ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                      : "opacity-[0.5]"
                      }`} onClick={() => handleClick(7)}
                    >
                      <div className=' flex flex-row justify-start items-center gap-[16px]'>
                        <img className='bg-transparent  !h-[1rem] opacity-0' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                        <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Trade Leaderboard</div>
                      </div>
                    </div>
                  </a>
                </div>
                }

                <a rel="noreferrer" href='https://app.kanalabs.io/referrals' className='w-full  h-[52px] flex flex-row justify-start items-center  bg-transparent gap-[16px]'>
                  <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 5
                    ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                    : "opacity-[0.5]"
                    }`} onClick={() => handleClick(3)}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? ReferralDark : Referral} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Referral</div>
                    </div>
                  </div>
                </a>

              </div>
              <div
                className="xxl:hidden w-full xl:hidden lg:hidden md:flex sm:flex max-sm:flex mt-1.5 "
                onClick={() => updateIsDarkMode(!isDarkMode)}
              // onClick={() => setIsSetting(true)}
              >
                <div

                  className={`${isDarkMode ? 'bg-[#EFF7F8] !rounded-[2rem] text-[#0C0C0D] ' : 'bg-[#1D1E20] !rounded-[2rem] text-[#FFF]'
                    } w-[100%]  font-[800]  h-[3.875rem]  py-[1rem] px-[1.5rem] flex flex-row justify-between gap-[1rem] items-center  cursor-pointer `}
                >
                  <div>Theme</div>
                  <img src={isDarkMode ? SettingGreen : SettingDark} alt="sun" />
                </div>
              </div>
            </div>
            <div className='flex flex-row justify-center items-center gap-[1rem] w-full '>
              <div className={`${isDarkMode ? 'text-[#A5A5A6]' : 'text-[rgba(255,255,255,0.50)]'} text-[12px]  py-5 font-inter`}>Powered by</div>
              <div>
                {isDarkMode ?
                  <img src={EconiaBg} alt='EconiaImg' className='w-[6.6rem] h-[1rem]' /> :
                  <img src={EconiaImg} alt='EconiaImg' className='w-[6.6rem] h-[1rem]' />
                }
              </div>
            </div>
          </ul>
        ) : (
          <ul></ul>
        )}
        {isMobileButtonsActive ? (
          <ul
            className={`flex-1 flex flex-col  justify-between items-start py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:flex md:flex md:pb-0 md:mt-24 sm:mt-24 xd:mt-24 z-[9] ${isDarkMode ? '!bg-[#FCFDFE]' : '!bg-[#17181A]'} ${isMobileButtonsActive ? '!fixed !right-0 !left-0 lg:!bottom-0 md:!bottom-24 sm:!bottom-24 xd:!bottom-24 !top-0	' : 'hidden'
              }`}   >
            <div className="flex flex-col  items-start justify-between align-middle  h-full  w-full lg:!px-[1rem] md:!px-[1rem] sm:!px-[1rem] xd:!px-[1rem] !bg-transparent">

              <div className="flex flex-col  items-start justify-start align-middle  h-full  w-full  !bg-transparent">
                <div onClick={() => isWalletConnected || connected ? '' : handleConnect()}
                  className={` my-2 flex w-[100%] flex-row justify-start items-center gap-[8px] !bg-[#1D1E20]   cursor-pointer  h-[3.875rem] leading-[normal]   py-[1rem]     font-inter   text-[1rem]  gap-[8px]     cursor-pointer   h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]  ${isDarkMode ? '!bg-[#EFF7F8] text-[#0E9384]' : '!bg-[#1D1E20] text-[#2ED3B7]'} font-inter   text-[1rem]  `}>
                  {isWalletConnected || connected ?
                    <div className='flex flex-row justify-start items-center gap-[0.625rem]'>
                      <div ><img className='!w-5 !h-5' src={connected ? wallet?.adapter?.icon : profileImage} alt='wallet' /></div>
                      {connected ? account?.address?.slice(0, 7) + '..' + account?.address?.toString()?.slice(-7) : socialAddress?.slice(0, 7) + '..' + socialAddress?.toString()?.slice(-7)}
                      <img src={isDarkMode ? copyDark : Copy} alt='copy' />
                      <img src={PowerButton} alt='PowerButton' onClick={() => isWalletConnected || connected ? handleDisconnect() : ''} />
                    </div>
                    : <div className='flex flex-row justify-start items-center gap-[20px]'>
                      {isDarkMode ? <img src={UserCircleWhite} alt='UserCircle' className='!bg-transparent' /> : <img src={UserCircle} alt='UserCircle' className='!bg-transparent' />}
                      Connect Wallet
                    </div>
                  }
                </div>
                <div
                  className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} ${isDarkMode ? '!bg-[#EFF7F8] text-[#0E9384]' : '!bg-[#1D1E20] text-[#2ED3B7]'}  my-2 flex w-[100%] flex-row justify-start items-center gap-[8px]    h-[3.875rem] leading-[normal]   py-[1rem]     font-inter  text-[1rem]  gap-[8px]      h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]  font-inter text-[1rem]  `}
                  onClick={() => handleDeposit()}
                >
                  {isDarkMode ? <img src={ArrowVertical} alt='ArrowVertical' className='!bg-transparent' /> : <img src={SwitchVertical} alt='ArrowVertical' className='!bg-transparent' />}
                  Deposit / Withdraw
                </div>
              </div>

              {/* <Button
                className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} && ${isDarkMode ? '!bg-[#EFF7F8] text-[#0E9384]' : '!bg-[#1D1E20] text-[#2ED3B7]'} !w-[25%] font-[800]  xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-row justify-start items-center gap-[8px]   h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]    font-inter   text-[0.875rem]`}
                onClick={() => handleDeposit()}
              >
                {isDarkMode ? <img src={ArrowVertical} alt='ArrowVertical' className='!bg-transparent' /> : <img src={SwitchVertical} alt='ArrowVertical' className='!bg-transparent' />}
                Deposit / Withdraw
              </Button> */}
              <Button
                onClick={() => isWalletConnected || connected ? '' : handleConnect()}
                className={`${isDarkMode ? '!bg-[#EFF7F8] text-[#0E9384]' : '!bg-[#1D1E20] text-[#2ED3B7]'} common-pointer  !w-[25%]   xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-row justify-between items-center gap-[8px]     cursor-pointer   h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[1rem]    font-inter font-[800]  text-[0.875rem]`}
              >
                {isWalletConnected || connected ?
                  <div className='flex flex-row justify-start items-center gap-[0.5rem]'>
                    <div  ><img className='!w-5 !h-5' src={connected ? wallet?.adapter?.icon : profileImage} alt='wallet' /></div>
                    {connected ? account?.address?.slice(0, 7) + '..' + account?.address?.toString()?.slice(-7) : socialAddress?.slice(0, 7) + '..' + socialAddress?.toString()?.slice(-7)}
                    <CopyToClipboard
                      text={connected ? account.address : socialAddress}
                      onCopy={() => onCopy()}
                    >
                      <img src={Copy} alt='copy' />
                    </CopyToClipboard>
                    <img src={PowerButton} alt='PowerButton' className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => isWalletConnected || connected ? handleDisconnect() : ''} />
                  </div>
                  : <div className='flex flex-row justify-start items-center gap-[20px] '>
                    {isDarkMode ? <img src={UserCircleWhite} alt='UserCircle' className='!bg-transparent' /> : <img src={UserCircle} alt='UserCircle' className='!bg-transparent' />}
                    Connect Wallet
                  </div>
                }
              </Button>
              {/* <div className='w-full my-1'>
                <FaucetDropdownMenu />
              </div> */}
            </div>
            <div className='flex flex-row justify-center items-center gap-[1rem] w-full '>
              <div className={`${isDarkMode ? 'text-[#A5A5A6]' : 'text-[rgba(255,255,255,0.50)]'} text-[12px]  py-5 font-inter`}>Powered by</div>
              <div>
                {isDarkMode ?
                  <img src={EconiaBg} alt='EconiaImg' className='w-[6.6rem] h-[1rem]' /> :
                  <img src={EconiaImg} alt='EconiaImg' className='w-[6.6rem] h-[1rem]' />
                }
              </div>
            </div>
          </ul>
        ) : (<ul></ul>)}
      </div >
      {isSetting && <Settings handleClose={handleCloseSettings} />}
      {isConnectWalletisClicked && <ConnectWallet handleClose={handleCloseWalletConnect} />}
      {isWithdrawClicked && <WithdrawModel handleCloseModal={handleCloseModal} handleDepositModal={handleDepositModal} />}
      {isAddCurrencyClicked && <AddCurrencyModel />}
      {isDepositClicked && <Deposit handleCloseModal={handleCloseModal} handleWithDrawModal={handleWithDrawModal} />}
      {isReferralContestOpen && <ReferralContest handleClose={handleReferralContestClose} referralURL = {referralURL}/>}
      {/* {openSocialWallet && <ConnectSocialLogin handleClose={handleCloseSocialWallet}/>} */}
    </>
  );
};

export default Header;