
import React, { useEffect } from 'react'
import { useStore } from '../../store'
const NewMessageNotification = (props: any) => {
  const { isDarkMode } = useStore()

  const { type, link, message } = props
  const handleOpenExplorer = () => {
    if (link === "none") {
      return
    } else {
      window.open(`https://explorer.aptoslabs.com/txn/${link}?network=mainnet`, '_blank', 'noreferrer')
    }
  }

  useEffect(() => {
    setTimeout(() => {
      props.closeToast()
    }, 5000)
  }, [])

  return (
    <div className={`flex flex-col justify-start items-start text-[0.8rem] font-[400]  gap-[0.5rem] ${isDarkMode ? 'text-[black]  ' : '   text-[white]'}`}>
      <div className={`${type === 'error' ? ' border-[#B42318]' : '  border-[#027A48]'
        } flex flex-row justify-start items-center gap-[0.5rem] border-l-2 px-[0.5rem] `}  >
        <div>{message}</div>
      </div>
      {link !== 'none' && <div onClick={() => handleOpenExplorer()} className={`p-[0.5rem_1rem] border-[1px]  rounded-[0.5rem]  text-[12px] font-[700] ${type === 'error' ? 'text-[#B42318] border-[#B42318]' : 'text-[#2ED3B7] border-[rgba(1,252,206,0.20)'} `}>View on Explorer</div>}
    </div>
  );
}

export default NewMessageNotification