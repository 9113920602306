import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TradeDashboard from '../../pages/TradeDashboard';
import { useTranslation } from "react-i18next";
import Kana from "../../assets/icons/Logo.svg";
import Swap from '../../assets/icons/swap.svg';
import SwapDark from '../../assets/icons/swap_dark.svg';
import LeaderboardPage from '../../pages/Leaderboard'
import Leaderboard from '../../assets/icons/leader_board.svg';
import LeaderboardDark from '../../assets/icons/leader_board_dark.svg';
import kanaBlack from '../../assets/icons/kanaBlack.svg';
import Referral from '../../assets/icons/referral.svg';
import ReferralDark from '../../assets/icons/referral_dark.svg';
import help from '../../assets/icons/help.svg';
import helpDark from '../../assets/icons/help_dark.svg';
import ChevronDown from '../../assets/icons/chevron-down.svg';
import ChevronDownDark from '../../assets/icons/chevron-down-dark.svg';
import StakeIcon from '../../assets/icons/stack_icon.svg';
import StakeDarkIcon from '../../assets/icons/stack_icon_dark.svg';
import KanaLogo from '../../assets/icons/kana_logo.svg';
import { useStore } from '../../store';
import SwitchDark from '../../assets/icons/switch_dark.svg';
import Switch from '../../assets/icons/switch_icon.svg';
import Trade_dark from '../../assets/icons/bar-chart-square-dark.svg';
import TradeIcon from '../../assets/icons/trade_icon.svg';

export const LeaderboardSidebar: React.FC = () => {
  const { t } = useTranslation();
  const [activeItemSidebar, setActiveItemSidebar] = useState(1);
  const { isDarkMode } = useStore()

  const handleClick = (id: any) => {
    setActiveItemSidebar(id);
  }

  const [isLeaderBoard, setIsLeaderBoard] = useState(false);

  const handleLeaderBoard = () => {
    if (isLeaderBoard === false) {
      setIsLeaderBoard(true);
    } else {
      setIsLeaderBoard(false);
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };
  const handleMouseLeave = () => {
    setIsLeaderBoard(false);
    setIsOpen(false);
  };

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={` ${isDarkMode ? 'bg-[#FCFDFE]' : 'bg-[#17181A]'} !z-[2]  !font-inter !fixed   h-full  flex flex-col gap-4   items-center justify-between  py-5  !top-0 !bottom-0 !left-0  xxl:flex xl:flex lg:hidden md:hidden sm:hidden xd:hidden w-auto ${isOpen ? '!w-[320px] ' : '!w-[95px]'}`}
      >
        <div className="w-auto hover:w-full bg-transparent flex flex-col text-left items-center h-full justify-between p-[1rem] w-full  ">
          <div className='flex flex-col text-left items-center h-full justify-start w-full'>
            <div className={` ${isDarkMode ? 'bg-[#FCFDFE]' : 'bg-[#17181A]'} p-[1rem]   w-full top-0 !sticky  mb-[36px]   !z-[2]`} >
              {isOpen ? (
                <img className='bg-transparent !h-[32px] !w-auto' src={isDarkMode ? kanaBlack : KanaLogo} alt="KanaLogo" />
              ) : (
                <img className='bg-transparent !h-[32px] !w-auto' src={Kana} alt="Kana" />
              )}
            </div>
            <div className={`  flex flex-col justify-start items-start bg-transparent w-full gap-[8px]`}>
              <a href='https://app.kanalabs.io/' rel="noreferrer" className={`   h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${isOpen ? 'py-[1rem] px-[1.4rem]' : 'py-[1.2rem] px-[1.4rem]'}  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 2
                  ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(2)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? SwapDark : Swap} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Swap')}</div>
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? SwapDark : Swap} alt="TradeIcon" />
                    </div>
                  )}

                </div>
              </a>
              <a href='/' className={`   h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${isOpen ? 'py-[1rem] px-[1.4rem]' : 'py-[1.2rem] px-[1.4rem]'}  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 7
                  ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(7)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? Trade_dark : TradeIcon} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Trade')}</div>
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? Trade_dark : TradeIcon} alt="TradeIcon" />
                    </div>
                  )}
                </div>
              </a>
              <a href='https://switch.kanalabs.io/' rel="noreferrer" className={` cursor-pointer   h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${isOpen ? 'py-[1rem] px-[1.4rem]' : 'py-[1.2rem] px-[1.4rem]'}  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 4
                  ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(4)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? SwitchDark : Switch} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Switch')}</div>
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? SwitchDark : Switch} alt="TradeIcon" />
                    </div>
                  )}

                </div>
              </a>


              <a href='https://app.kanalabs.io/stake-yield' rel="noreferrer" className={`   h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${isOpen ? 'py-[1rem] px-[1.4rem]' : 'py-[1.2rem] px-[1.4rem]'}  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                  ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(3)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? StakeDarkIcon : StakeIcon} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('Stake')}</div>
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? StakeDarkIcon : StakeIcon} alt="TradeIcon" />
                    </div>
                  )}

                </div>
              </a>


              <div className={` cursor-pointer   h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${isOpen ? 'py-[1rem] px-[1.4rem]' : 'py-[1.2rem] px-[1.4rem]'}  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 1
                  ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(1)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-between w-full items-center' onClick={handleLeaderBoard}>
                      <div className=' flex flex-row justify-start items-center gap-[16px]'>
                        <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                        <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Leaderboard</div>
                      </div>
                      <img src={isDarkMode ? ChevronDownDark : ChevronDown} alt="ChevronDown" className={isLeaderBoard ? ' rotate-180 ml-2' : ' ml-2 rotate-0'} />
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                    </div>
                  )}

                </div>
              </div>
              {isLeaderBoard ? <div className="submenu w-full gap-2 flex flex-col">
                <a href='https://app.kanalabs.io/leader-board' rel="noreferrer" className={`   h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                  <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${isOpen ? 'py-[1rem] px-[1.4rem]' : 'py-[1.2rem] px-[1.4rem]'}  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 6
                    ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                    : "opacity-[0.5]"
                    }`} onClick={() => handleClick(6)}
                  >
                    {isOpen ? (
                      <div className=' flex flex-row justify-start items-center gap-[16px]'>
                        <img className='bg-transparent  !h-[1rem] opacity-0' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                        <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Swap Leaderboard</div>
                      </div>
                    ) : (
                      <div className=' flex flex-row justify-center items-center w-full'>
                        <img className='bg-transparent  !h-[1rem] opacity-0' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                      </div>
                    )}

                  </div>
                </a>
                <a href='/leaderboard' rel="noreferrer" className={`   h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                  <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${isOpen ? 'py-[1rem] px-[1.4rem]' : 'py-[1.2rem] px-[1.4rem]'}  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 1
                    ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                    : "opacity-[0.5]"
                    }`} onClick={() => handleClick(1)}
                  >
                    {isOpen ? (
                      <div className=' flex flex-row justify-start items-center gap-[16px]'>
                        <img className='bg-transparent  !h-[1rem] opacity-0' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                        <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Trade Leaderboard</div>
                      </div>
                    ) : (
                      <div className=' flex flex-row justify-center items-center w-full'>
                        <img className='bg-transparent  !h-[1rem] opacity-0' src={isDarkMode ? LeaderboardDark : Leaderboard} alt="TradeIcon" />
                      </div>
                    )}

                  </div>
                </a>
              </div> : <></>
              }

              <a href='https://app.kanalabs.io/referrals' rel="noreferrer" className={`   h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                <div className={` ${isDarkMode ? 'hover:bg-[#EFF7F8]' : 'hover:bg-[rgba(255,255,255,0.06)]'} bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${isOpen ? 'py-[1rem] px-[1.4rem]' : 'py-[1.2rem] px-[1.4rem]'}  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 8
                  ? `!font-[800] !opacity-[1]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '}`
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(8)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? ReferralDark : Referral} alt="TradeIcon" />
                      <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>Referral</div>
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>
                      <img className='bg-transparent  !h-[1rem]' src={isDarkMode ? ReferralDark : Referral} alt="TradeIcon" />
                    </div>
                  )}

                </div>
              </a>
            </div>
          </div>
          <a rel="noreferrer" href=' https://kanalabs.io/contact-us-dev' className='w-full'>
            {isOpen ? (
              <div className={` ${isDarkMode ? 'bg-[#EFF7F8]' : 'bg-[#0C0C0D]'}   py-[1rem] px-[1.4rem]  rounded-[1rem]  w-full text-[white] text-[16px] font-[400] gap-[1rem] flex flex-row justify-start items-center`}>
                <img className='bg-transparent !w-[1.5rem]  !h-[1.1rem]' src={isDarkMode ? helpDark : help} alt="Swap" />
                <div className={isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'}>{t('help')}</div>
              </div>
            ) : (
              <div className={` ${isDarkMode ? 'bg-[#EFF7F8]' : 'bg-[#0C0C0D]'}  py-[1.2rem] px-[1.4rem]  rounded-[1rem] w-full text-[white] text-[16px] font-[400] gap-[1rem] flex flex-row justify-center items-center`}>
                <img className='bg-transparent !w-[1.5rem]  !h-[1.1rem]' src={isDarkMode ? helpDark : help} alt="Swap" />
              </div>
            )}
          </a>
        </div>
      </div>
    </>
  );
};