import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals.js";
import "./styles/color.css";
import "./styles/font.css";
import "./styles/index.css";
import "./styles/tailwind.css";
import './index.css';
import './i18n';
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'

Sentry.init({
  dsn: `https://871ca2d405a0a9f13394723eac8153d4@o1344811.ingest.sentry.io/${process.env.REACT_APP_SENTRY_KEY}`,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost","https://tradebook.kanalabs.io/","https://tradebook-dev.kanalabs.io/", /^https:\/\/tradebook.kanalabs\.io\/api/],
    }),
  //   new Sentry.Replay({
  //     maskAllText: false,
  //     blockAllMedia: false,
  //   }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();