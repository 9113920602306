/* eslint-disable no-useless-escape */
import BigNumber from 'bignumber.js'
import { NetworkNames, initializeSdkGateway } from '@kanalabs/mirai';
import { AptosAccount, BCS, HexString } from 'aptos';
import axios from 'axios'
import { client, referralLink, rewardsLink } from './Constants';
const TEN = new BigNumber(10);

export const toDecimalPriceCalculate = ({
  price,
  lotSize,
  tickSize,
  baseCoinDecimals,
  quoteCoinDecimals,
}: {
  price: BigNumber;
  lotSize: BigNumber;
  tickSize: BigNumber;
  baseCoinDecimals: BigNumber;
  quoteCoinDecimals: BigNumber;
}) => {
  const lotsPerUnit = TEN.exponentiatedBy(baseCoinDecimals).div(lotSize);
  const pricePerLot = price
    .multipliedBy(tickSize)
    .div(TEN.exponentiatedBy(quoteCoinDecimals));
  return pricePerLot.multipliedBy(lotsPerUnit);
};

export const toDecimalSizeCalulate = ({
  size,
  lotSize,
  baseCoinDecimals,
}: {
  size: BigNumber;
  lotSize: BigNumber;
  baseCoinDecimals: BigNumber;
}) => {
  try {
    const val = size && lotSize && baseCoinDecimals && size.multipliedBy(lotSize).div(TEN.exponentiatedBy(baseCoinDecimals))
    return val;
  } catch (e) {
    return 0
  }
};

export const isObjectNonEmpty = (data: any): boolean => {
  return data && typeof data == 'object' && Object.keys(data).length > 0 ? true : false;
};

export const onTokenAmountChange = (e: any) => {
  const value: any = e;
  // only accept digit . and numbers
  if (
    /^[0-9\.]*$/gm.test(value) &&
    ((value.match(/[\.,]/gm) && value.match(/^[0-9]{0,9}(\.|,)?[0-9]{0,8}$/gm)) ||
      (!value.match(/[\.,]/gm) &&
        value.match(/^[0-9]{0,9}$/gm) &&
        (!value.match(/\./gm) || value.match(/\./gm)?.length <= 1) &&
        (!value.match(/,/gm) || value.match(/,/gm)?.length <= 1)))
  ) {
    // replace duplication if needed
    const amount = value
      .replace(/\.+/gm, '.')
      .replace(/,+/gm, ',')
      .replace(/^0+/gm, '0')
      // if first character is . then replace them with 0.
      .replace(/^\./, '0.');
    return amount
  }
  return ""
};

export function getUiAmount(number: number, tokenDecimal: number) {
  return Math.floor(number * Math.pow(10, tokenDecimal));
}

export function getUiAmountReverse(number: number, tokenDecimal: number) {
  return number / Math.pow(10, tokenDecimal);
}

export const preventPasteNegativeNumber = (e: any) => {
  const clipboardData = e.clipboardData || (window as any).clipboardData;
  const value = clipboardData.getData("text");
  if (!value || value.includes("-")) {
    e.preventDefault();
  } else {
    const pastedData = parseFloat(value);
    if (pastedData < 0) {
      e.preventDefault();
    }
  }
};

export const getPercentageAmount = (percentage: any, value: any, decimals: any, typeOfCoin: string, typeOfTransaction: string) => {
  let amount = "";
  if (percentage === "25%") {
    amount = ((value / 100) * 25).toString();
    return parseFloat(amount)?.toFixed(9).replace(/\.?0*$/, '').toString()
  } else if (percentage === "50%") {
    amount = ((value / 100) * 50).toString();
    return parseFloat(amount)?.toFixed(9).replace(/\.?0*$/, '').toString()
  } else if (percentage === "Max") {
    if (typeOfTransaction === 'Deposit') {
      if (typeOfCoin === 'USDC') {
        return parseFloat(value)?.toFixed(9).replace(/\.?0*$/, '').toString()
      } else if (typeOfCoin === "APT") {
        const decimalValue = getUiAmount(value, decimals)
        const maxValue = decimalValue - 600000
        if (maxValue > 0) {
          const originalValue = getUiAmountReverse(maxValue, decimals)
          const valid = getUiAmount(originalValue, decimals)
          if (valid >= 600000) {
            return originalValue?.toFixed(9).replace(/\.?0*$/, '').toString()
          }
          return "false"
        }
        return "false"
      } else {
        return parseFloat(value)?.toFixed(9).replace(/\.?0*$/, '').toString()
      }
    }
    return parseFloat(value)?.toFixed(9).replace(/\.?0*$/, '').toString()
  } else if (percentage === "Clear") {
    return amount
  }
};

export const handleInitWallet = async (web3Instance: any) => {
  try {
    const privateKey = await web3Instance.provider.request({
      method: "private_key",
    });
    const formatedKey = new Uint8Array(
      privateKey.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16)))
    const wallet = new AptosAccount(formatedKey);
    const address = wallet.address().hex();
    return { error: false, wallet: wallet, address: address }
  } catch (e) {
    return { error: true, wallet: "", address: "" }
  }
};

export const handleAddToWaitlist = async (address: string) => {
  try {
    const gas_usage_limit = 100000000;
    const url = `${process.env.REACT_APP_BASE_URL}/add-to-whitelist`;
    const data = {
      address,
      gas_usage_limit: gas_usage_limit,
    };
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "pay-key": process.env.REACT_APP_PAYMASTER_SPONSER_API_KEY as string,
      },
    });
    if (response.status === 201) {
      await initAccount(address)
      return { waitlistError: false, response: 'whitelisted' }
    }
    return { waitlistError: true, response: '' }
  } catch (e: any) {
    return { waitlistError: true, response: '' }
  }
};

export const delay = (ms: any) => new Promise(
  resolve => setTimeout(resolve, ms)
);

export const handleSignWeb3AuthTransaction = async (sdk: any, payload: any) => {
  try {
    const feePayerTxn = await client.generateFeePayerTransaction(
      sdk.address(),
      payload,
      process.env.REACT_APP_FEE_PAYER
    );
    const senderAuth = await client.signMultiTransaction(sdk, feePayerTxn);
    const serializer = new BCS.Serializer();
    feePayerTxn.serialize(serializer);
    senderAuth.serialize(serializer);
    const serializerdata = serializer.getBytes();
    const hexStringData = HexString.fromUint8Array(serializerdata).toString();
    const stringifiedData = JSON.stringify(hexStringData, null, 3);
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/pay-gas`, stringifiedData, {
      headers: {
        "Content-Type": "application/json",
        "pay-key": process.env.REACT_APP_PAYMASTER_SPONSER_API_KEY as string,
      },
    });
    if (res.data.code === 200) {
      return { error: false, hash: res.data.hash, status: res.data.vm_status }
    } else if (res.data.code === 500) {
      if (res?.data?.error?.transaction?.vm_status.includes('E_WAIT_TIME(0x0)')) {
        return { error: true, hash: "", status: "You have not waited long enough between a deposit or mint." }
      } else {
        return { error: true, hash: "", status: "Please Try Again" }
      }
    }
    return { error: true, hash: "", status: "Transaction Failed. Please Try Again!" }
  } catch (e: any) {
    if (e.name === 'Rejected') {
      return { error: true, hash: "", status: "Rejected by User" }
    }
    const error = e as Error
    return { error: true, hash: "", status: error.message }
  }
}



export const initAccount = async (address: string) => {
  try {
    const acc = await client.getAccount(address);
    return { error: true, hash: "" }
  } catch (e: any) {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/init-account`;
      const data = {
        address,
      };
      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "pay-key": process.env.REACT_APP_PAYMASTER_SPONSER_API_KEY as string,
        },
      });
      if (res.data.code === 200) {
        return { error: false, hash: res.data.hash }
      }
      return { error: true, hash: "" }
    } catch (e: any) {
      return { error: true, hash: "" }
    }
  }
};

export const handleConnectedWalletNetwork = (wallet: any) => {
  if (wallet && wallet?.adapter?.name === 'Martian' && wallet?.adapter?.network?.name === 'Testnet' && wallet?.adapter?.network?.name === 'Custom') {
    return { error: true }
  }
  if (wallet && wallet?.adapter?.name === 'Petra' && wallet?.adapter?.network?.name !== 'Mainnet') {
    return { error: true }
  }
  if (wallet && wallet?.adapter?.name === 'Pontem' && wallet?.adapter?.network?.name !== 'Aptos mainnet') {
    return { error: true }
  }
  if (wallet && wallet?.adapter?.name === 'Rise Wallet' && wallet?.adapter?.network?.name !== 'mainnet') {
    return { error: true }
  }
  return { error: false }
}

export const handleGetReferralLink = async (data: any) => {
  const headers = {
    "x-api-key": process.env.REACT_APP_REFERRAL_API_KEY as string,
  }
  const response = await axios.post(
    `${referralLink}/fetchshortenerUrl`,
    data,
    { headers: headers }
  );
  if (response.status === 201) {
    return { error: false, data: response?.data?.data };
  } else {
    return {
      error: true,
      data: `Unexpected response status: ${response.status}`,
    };
  }
}

export const handleGetShortenURL = async (referralAddress: any) => {
  const link = `${rewardsLink}${referralAddress}`;
  const referral = {
    referralLink: link,
  };
  const { error, data } = await handleGetReferralLink(referral);
  if (error) return { error: true, data: '' }
  return { error: false, data: data[0].shortenerUrl }
}

export const openTwitter = async (url: any) => {
  if (typeof window !== 'undefined') {
    window.open(`https://twitter.com/intent/tweet?url=${url}&text=Kana%20Tradebook%20Referral%20Contest%20is%20Live%20!%20Refer%20friends%20and%20family%20and%20earn%201%20APT%20for%20every%2010%20referrals.%20Hurry!%20Open%20only%20for%20first%2020,000%20referrals%20!&via=`);
  }
};
export const openTelegram = async (url: any) => {
  if (typeof window !== 'undefined') {
    window.open(`https://telegram.me/share/url?url=${url}&text=Kana%20Tradebook%20Referral%20Contest%20is%20Live%20!%20Refer%20friends%20and%20family%20and%20earn%201%20APT%20for%20every%2010%20referrals.%20Hurry!%20Open%20only%20for%20first%2020,000%20referrals%20!`)
  }
};
export const openFacebook = async (url: any) => {
  if (typeof window !== 'undefined') {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
  }
};
export const openWhatsapp = async (url: any) => {
  if (typeof window !== 'undefined') {
    window.open(`whatsapp://send?text=${url}`, '_blank');
  }
};
export const openReddit = async (url: any) => {
  if (typeof window !== 'undefined') {
    window.open(`https://reddit.com/submit?url=${url}&title=Kana%20Tradebook%20Referral%20Contest%20is%20Live%20!%20Refer%20friends%20and%20family%20and%20earn%201%20APT%20for%20every%2010%20referrals.%20Hurry!%20Open%20only%20for%20first%2020,000%20referrals%20!`, '_blank');
  }
};
export const openLinkedin = async (url: any) => {
  if (typeof window !== 'undefined') {
    window.open(`https://www.linkedin.com/shareArticle?url=${url}&title=Kana%20Tradebook%20Referral%20Contest%20is%20Live%20!%20Refer%20friends%20and%20family%20and%20earn%201%20APT%20for%20every%2010%20referrals.%20Hurry!%20Open%20only%20for%20first%2020,000%20referrals%20!}`, '_blank');
  }
};