import { StateSlice, Store } from '../types';

export type AppSlice = {
    isDarkMode: boolean;
    registeredMarkets: any;
    availableMarkets: any;
    marketDataInstance: any;
    tradePairInfo: any;
    needToUpdateGlobalTrades: boolean;
    orderBook: any;
    globalTrades: any;
    isLoading: boolean;
    balanceUpdate: boolean;
    aptosWeb3Instance: any;
    address: string;
    sdk: any;
    isWalletConnected: boolean;
    lastPlacedPrice: any;
    resolutions: any;
    selectedMarketID: number;
    dropdownValues: any;
    updateIsDarkMode(payload: boolean): void;
    updateRegisteredMarkets(payload: any): void;
    updateAvailableMarkets(payload: any): void;
    updateMarketDataInstance(payload: any): void;
    updateTradePairInfo(payload: any): void;
    updateNeedToUpdateGlobalTrades(payload: any): void;
    updateOrderBook(payload: any): void;
    updateGlobalTrades(payload: any): void;
    updateIsLoading(payload: any): void;
    updateBalanceUpdate(payload: any): void;
    updateAptosWeb3Instance(payload: any): void;
    updateAddress(payload: any): void;
    updateSdk(payload: any): void;
    updateIsWalletConnected(payload: any): void;
    updateLastPlacedPrice(payload: any): void;
    updateResolutions(payload: any): void;
    updateSelectedMarketID(payload: any): void;
    updateDropdownValues(payload: any): void;
};

export const createAppSlice: StateSlice<Store, AppSlice> = (set: any) => ({
    isDarkMode: localStorage.getItem('isDarkMode') === 'true',
    registeredMarkets: [],
    availableMarkets: [],
    marketDataInstance: {},
    needToUpdateGlobalTrades: true,
    orderBook: {},
    globalTrades: [],
    isLoading: false,
    balanceUpdate: true,
    aptosWeb3Instance: {},
    address: "",
    sdk: {},
    isWalletConnected: false,
    tradePairInfo: {},
    lastPlacedPrice: {},
    resolutions: {},
    dropdownValues: [
        {
            item: "APT",
            selected: false,
            icon: "",
            balance: 0,
            decimals: 0,
            marketAccountBalance: 0,
            marketAccountDecimals: 0
          },
          {
            item: "USDC",
            selected: false,
            icon: "",
            balance: 0,
            decimals: 0,
            marketAccountBalance: 0,
            marketAccountDecimals: 0
          },
    ],
    selectedMarketID: 7,
    updateIsDarkMode(payload: AppSlice['isDarkMode']) {
        set({ isDarkMode: payload });
        localStorage.setItem('isDarkMode', payload.toString());
    },
    updateRegisteredMarkets(payload: AppSlice['registeredMarkets']) {
        set({ registeredMarkets: payload });
    },
    updateAvailableMarkets(payload: AppSlice['availableMarkets']) {
        set({ availableMarkets: payload });
    },
    updateMarketDataInstance(payload: AppSlice['marketDataInstance']) {
        set({ marketDataInstance: payload });
    },
    updateTradePairInfo(payload: AppSlice['tradePairInfo']) {
        set({ tradePairInfo: payload });
    },
    updateNeedToUpdateGlobalTrades(payload: AppSlice['needToUpdateGlobalTrades']) {
        set({ needToUpdateGlobalTrades: payload });
    },
    updateOrderBook(payload: AppSlice['orderBook']) {
        set({ orderBook: payload });
    },
    updateGlobalTrades(payload: AppSlice['globalTrades']) {
        set({ globalTrades: payload });
    },
    updateIsLoading(payload: AppSlice['isLoading']) {
        set({ isLoading: payload });
    },
    updateBalanceUpdate(payload: AppSlice['balanceUpdate']) {
        set({ balanceUpdate: payload });
    },
    updateAptosWeb3Instance(payload: AppSlice['aptosWeb3Instance']) {
        set({ aptosWeb3Instance: payload });
    },
    updateAddress(payload: AppSlice['address']) {
        set({ address: payload });
    },
    updateSdk(payload: AppSlice['sdk']) {
        set({ sdk: payload });
    },
    updateIsWalletConnected(payload: AppSlice['isWalletConnected']) {
        set({ isWalletConnected: payload });
    },
    updateLastPlacedPrice(payload: AppSlice['lastPlacedPrice']) {
        set({ lastPlacedPrice: payload });
    },
    updateResolutions(payload: AppSlice['resolutions']) {
        set({ resolutions: payload });
    },
    updateSelectedMarketID(payload: AppSlice['selectedMarketID']){
        set({selectedMarketID: payload});
    },
    updateDropdownValues(payload: AppSlice['dropdownValues']){
        set({dropdownValues: payload});
    }
});
