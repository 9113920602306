import React from 'react'
import { useStore } from '../store';
import closeIcon from '../assets/icons/close.svg';
import CloseDark from '../assets/icons/close_black.svg';
import ReferralContestImg from '../assets/icons/referral_contest.png';
import SwitchGreen from '../assets/icons/switch_green.svg';
import SwitchGreenDark from '../assets/icons/switch_green_dark.svg';
import copy from '../assets/icons/copy.svg';
import copyDark from '../assets/icons/copy_dark.svg';
import FbLight from '../assets/icons/fb_light.svg';
import FbDark from '../assets/icons/fb_dark.svg';
import Reddit_Dark from '../assets/icons/reddit_dark.svg';
import Rddit_Light from '../assets/icons/reddit_light.svg';
import Telegram from '../assets/icons/telegram_dark.svg';
import TelegramLight from '../assets/icons/telegram_light.svg';
import TwitterDark from '../assets/icons/twitter_dark.svg';
import TwitterLight from '../assets/icons/twitter_light.svg';
import LinkedinDark from '../assets/icons/linkedin_dark.svg';
import LinkedinLight from '../assets/icons/linkedin_light.svg';
import ClickAwayListener from 'react-click-away-listener';
import { openFacebook, openLinkedin, openReddit, openTelegram, openTwitter } from '../utils/helper';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import NewMessageNotification from '../components/NewMessageNotification';

interface ReferralContestInterface {
    handleClose(): void;
    referralURL: string
}
const ReferralContest = (props: ReferralContestInterface) => {
    const { handleClose, referralURL } = props;
    const { isDarkMode } = useStore();

    const handleReferralContestClose = () => {
        handleClose()
    }

    const onCopy = async () => {
        toast.dismiss()
        toast(<NewMessageNotification link={"none"} type={"success"} message={`Link Copied!`} />);
    };

    return (
            <div className={`fixed z-[2] inset-0 h-full w-full ${isDarkMode ? 'bg-[rgba(255,255,255,0.20)]' : 'bg-[rgba(0,0,0,0.20)]'} backdrop-blur-lg flex flex-row xxl:items-center bxl:items-center xl:items-center sxl:items-center lg:items-center md:items-end sm:items-end xd:items-end  justify-center  `}>
                <ClickAwayListener onClickAway={() => { handleReferralContestClose() }} >
                    <div
                        className={` min-h-[18rem] max-h-auto relative ${isDarkMode ? '!bg-[#FCFDFE] border-[#E3E8EF]' : '!bg-[#17181A] border-[#1e1f22] '} xxl:w-[35rem] bxl:w-[35rem] xl:w-[35rem] sxl:w-[35rem] lg:w-[35rem] md:w-[80%] sm:w-[100%] xd:w-[100%]  border-2 xxl:rounded-[1rem] bxl:rounded-[1rem] xl:rounded-[1rem] sxl:rounded-[1rem] lg:rounded-[1rem] md:xl:rounded-t-[1rem] sm:rounded-t-[1rem] xd:rounded-t-[1rem] `}
                    >
                        <div className={`   bg-full relative `}>
                            <img src={ReferralContestImg} alt='' className='h-[12rem] w-full rounded-t-[1rem] absolute bg-blend-overlay ' />
                            <img
                                src={isDarkMode ? CloseDark : closeIcon}
                                onClick={() => handleReferralContestClose()}
                                alt="close"
                                className="cursor-pointer absolute  right-[1rem] top-[1rem] z-[2] "
                            />
                            <div className='text-[2rem] bg-gradient-to-b py-[5rem] from-[#0C0C0D] to-transparent rounded-t-[1rem]  font-[700]  font-manrope  text-[white] flex xxl:flex-col bxl:flex-col xl:flex-col sxl:flex-col lg:flex-col md:flex-col sm:flex-col xd:flex-col  justify-center items-center gap-[0.3rem] w-full text-center  absolute'><span className='bg-gradient-to-r from-[rgba(0,255,255,1)]  to-[rgba(1,148,255,1)] inline-block text-transparent bg-clip-text '> Tradebook Referral Contest</span> is over</div>
                        </div>
                        <div className='p-[1rem] absolute bottom-0 flex flex-col gap-[1rem] w-full'>
                            {/* <div className={` ${isDarkMode ? ' bg-[#EFF7F8] border-[#E3E8EF]  text-[#4A4B4D]  ' : 'border-[rgba(255,255,255,0.10)] bg-[#111213] text-[rgba(255,255,255,0.80)] '} border-2 rounded-[1rem] p-[1rem] flex flex-col gap-[0.5rem] text-[0.875rem] font-[400] font-manrope `}>
                                Copy and share your referral link
                                <div className={` ${isDarkMode ? ' bg-[#EFF7F8] border-[#0E9384]  text-[#0E9384]  ' : 'border-[#2ED3B7] bg-[#111213] text-[#2ED3B7] '} border-dashed border-2 rounded-[1rem] p-[1.5rem] flex flex-row justify-between items-center gap-[0.5rem] text-[0.875rem] font-[800] font-manrope outline-none bg-transparent w-full `} >
                                    <div className={` text-[0.875rem] font-[800] font-manrope outline-none bg-transparent w-full `} >{referralURL}</div>
                                    <CopyToClipboard text={referralURL} onCopy={onCopy}>
                                            <img src={isDarkMode ? copyDark : copy} className='cursor-pointer' alt='' />
                                    </CopyToClipboard>
                                </div>
                                <div className='flex flex-row justify-start items-center gap-[0.5rem] w-full cursor-pointer' >
                                    <div className={` ${isDarkMode ? '     text-[#777879]  ' : ' text-[#A5A5A6] '} font-[400] font-manrope `}>
                                        Or share to
                                    </div>
                                    <div onClick={() => openTwitter(referralURL)} className={` ${isDarkMode ? 'bg-[#FCFDFE]' : 'bg-[#17181A]'} p-[1rem] flex flex-row justify-center items-center rounded-[0.5rem]`}>
                                        <img src={isDarkMode ? TwitterDark : TwitterLight} className='w-[1rem]  h-[1rem] ' alt='' />
                                    </div>
                                    <div onClick={() => openTelegram(referralURL)} className={` ${isDarkMode ? 'bg-[#FCFDFE]' : 'bg-[#17181A]'} p-[1rem] flex flex-row justify-center items-center rounded-[0.5rem]`}>
                                        <img src={isDarkMode ? Telegram : TelegramLight} className='w-[1rem]  h-[1rem] ' alt='' />
                                    </div>
                                    <div onClick={() => openFacebook(referralURL)} className={` ${isDarkMode ? 'bg-[#FCFDFE]' : 'bg-[#17181A]'} p-[1rem] flex flex-row justify-center items-center rounded-[0.5rem]`}>
                                        <img src={isDarkMode ? FbDark : FbLight} className='w-[1rem]  h-[1rem] ' alt='' />
                                    </div>
                                    <div onClick={() => openReddit(referralURL)} className={` ${isDarkMode ? 'bg-[#FCFDFE]' : 'bg-[#17181A]'} p-[1rem] flex flex-row justify-center items-center rounded-[0.5rem]`}>
                                        <img src={isDarkMode ? Reddit_Dark : Rddit_Light} className='w-[1rem]  h-[1rem] ' alt='' />
                                    </div>
                                    <div onClick={() => openLinkedin(referralURL)} className={` ${isDarkMode ? 'bg-[#FCFDFE]' : 'bg-[#17181A]'} p-[1rem] flex flex-row justify-center items-center rounded-[0.5rem]`}>
                                        <img src={isDarkMode ? LinkedinDark : LinkedinLight} className='w-[1rem]  h-[1rem] ' alt='' />
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className={` ${isDarkMode ? ' bg-[#EFF7F8] border-[#E3E8EF]  text-[#777879]  ' : 'border-[rgba(255,255,255,0.10)] bg-[#111213] text-[#A5A5A6] '} border-2 rounded-[1rem] p-[1rem] flex flex-row gap-[0.5rem] text-[0.75rem] font-[800] font-manrope `}><div className={` ${isDarkMode ? 'bg-[#EFF7F8]' : 'bg-[#0C0C0D]'} p-[1rem] rounded-[1rem] `}><img src={isDarkMode ? SwitchGreenDark : SwitchGreen} alt='' /></div>The referred person should trade above 500 USD to mark the referral as complete</div>
                            */}
                            <button
                                onClick={() => handleReferralContestClose()}
                                className={`  buy-button w-full `}
                            >
                                <div className={` ${isDarkMode ? 'buy-button-inner_blue' : 'buy-button-inner'} w-full py-[1rem] rounded-[1rem] flex flex-row justify-center items-center`}>
                                    <span className={`${isDarkMode ? 'button-text_dark' : 'button-text'} font-[700]`}>Proceed</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </ClickAwayListener>
            </div >
    )
}

export default ReferralContest
