import React from "react";
import { useStore } from "../../store";

interface PaginationProps {
  totalPage: number;
  currentPage: number;
  setPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalPage, currentPage, setPage }) => {
  const { isDarkMode } = useStore();

  const MAX_PAGE_NUMBERS = 5;
  const middle = Math.floor(MAX_PAGE_NUMBERS / 2);
  let start = Math.max(currentPage - middle, 1);
  const end = Math.min(start + MAX_PAGE_NUMBERS - 1, totalPage);

  if (totalPage - start < MAX_PAGE_NUMBERS) {
    start = Math.max(totalPage - MAX_PAGE_NUMBERS + 1, 1);
  }

  const pageNumbers = [];
  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`${currentPage === number ? (isDarkMode ? 'bg-[#E4F2F3] text-[#4A4B4D]': 'bg-[#FFFFFF0F] text-[#FFFFFFCC]' ): ''} w-[3rem] h-[3rem] rounded-xl sm:w-[2rem] sm:h-[2rem] sm:text-[0.8rem] sm:rounded-lg`}
        >
          {number}
        </button>
      ))}
    </div>
  );
  };
  

export default Pagination;