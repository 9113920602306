import React, { useEffect, useMemo, useState } from 'react';
import { useAptosContext } from '../providers/AptosWalletContext';
import { WalletReadyState } from '@manahippo/aptos-wallet-adapter';
import ConnectWallet from './ConnectWallet';
import TradingViewWidget from '../components/TradingView';
import { TradeTable } from '@kanalabs/trade';
import BuySell from './BuySell';
import OrderBook from './OrderBook';
import AllTrades from './AllTrades';
import Loader from '../components/Loader';
import Header from './Header';
import { useStore } from '../store';
import { isObjectNonEmpty } from '../utils/helper';
import GlobalTrades from './GlobalTrades';
import Footer from './Footer';
import Information from './Information';
import TradePoster from '../assets/icons/trade_poster.jpg';
import Close from '../assets/icons/close.svg'
import CloseDark from '../assets/icons/close_black.svg';
import LeaderBoard from './Leaderboard';

const TradeFivePage = () => {
  const imgElement = document.createElement('img');
  const { wallets, account } = useAptosContext();
  const { marketDataInstance, needToUpdateGlobalTrades, updateIsLoading, updateNeedToUpdateGlobalTrades, isDarkMode, updateIsDarkMode } = useStore()
  const [isConnectWalletisClicked, setIsConnectWalletClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openDeposit, setOpenDeposit] = useState<boolean>(false)
  const [globalTrades, setGlobalTrades] = useState<TradeTable[]>();
  const [socialLoginClicked, setIsSocialLoginClicked] = useState<boolean>(false)
  const [graphData, setGraphData] = useState<any[]>();
  const [selectedPair, setSelectedPair] = useState<any>({});
  const [isPosterVisible, setIsPosterVisible] = useState<boolean>(true);
   
  const [detected, undetected] = useMemo(() => {
    const detected: any[] = [];
    const undetected: any[] = [];
    for (const wallet of wallets) {
      if (wallet.readyState === WalletReadyState.Installed || wallet.readyState === WalletReadyState.Loadable) {
        detected.push(wallet);
      } else if (wallet.readyState === WalletReadyState.NotDetected) {
        undetected.push(wallet);
      }
    }
    return [detected, undetected];
  }, [wallets]);

  const handleGetGlobalTrades = async () => {
    if (isObjectNonEmpty(marketDataInstance)) {
      try {
        const getAllTrades = await marketDataInstance?.getAllTrades(false) as TradeTable[];
        const uniqueSet = new Set();
        const result: TradeTable[] = [];
        getAllTrades.forEach((obj: any) => {
          const propertyValue = obj["txn_version"];
          if (!uniqueSet.has(propertyValue)) {
            uniqueSet.add(propertyValue);
            result.push(obj);
          }
        });
        const sortedAsks = [...result].sort((a, b) => b.txn_version - a.txn_version);
        setGlobalTrades(sortedAsks)
        setGraphData(sortedAsks)

      } catch (e) {
        console.log()
      }
    }
  }

  // useEffect(() => {
  //   if (needToUpdateGlobalTrades) {
  //     handleGetGlobalTrades();
  //   }
  // }, [marketDataInstance, account, needToUpdateGlobalTrades])

  const handleWalletConnect = () => {
    setIsConnectWalletClicked(true);
  };

  const handleCloseWalletConnect = (typeOfLogin: string) => {

    updateNeedToUpdateGlobalTrades(true)
    setIsConnectWalletClicked(false);

  };

  const handleSocialLoginModelClose = () => {
    setIsSocialLoginClicked(false)
  }
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => { setIsLoading(false) }, 5000)
    }
  }, [isLoading])

  useEffect(() => {
    updateIsLoading(isLoading)
  }, [isLoading])

  const handleOpenDepositModal = () => {
    setOpenDeposit(true)
  }
  const handleCloseDeposit = () => {
    setOpenDeposit(false)
  }

  const handleSelectedPair = (pair: any) => {
    setSelectedPair(pair)
  }

  const handleSettingPairEmptyAgain = () => {
    setSelectedPair({})
  }

  const handlePosterClose = () => {
    setIsPosterVisible(false)
  }
  return (
    <>
      {isLoading ? <Loader /> : 
      <div className={` ${isDarkMode ? ' !bg-[#E4F2F3] ' : ' !bg-[#0C0C0D] '} h-screen `}>
        <Header openDeposit={openDeposit} handleCloseDeposit={handleCloseDeposit} />
        <div className={` ${isDarkMode ? ' !bg-[#E4F2F3] ' : ' !bg-[#0C0C0D] '} xxl:w-auto bxl:w-auto xl:w-auto sxl:w-auto lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%] !mt-[5.5rem] xxl:!ml-[5.5rem] bxl:!ml-[5.5rem] xl:!ml-[5.5rem] sxl:!ml-[5.5rem] lg:!ml-0 md:!ml-0 sm:!ml-0 xd:!ml-0 gap-[0.5rem] flex flex-col justify-start items-start py-[0.5rem] flex pb-24  md:hidden sm:hidden xd:hidden`}>
          <Information />
          <div className="!w-[100%] xxl:px-[1rem] bxl:px-[1rem] xl:px-[1rem] sxl:px-[1rem] lg:px-[1rem] md:px-[0rem] sm:px-[0rem] xd:px-[0rem] h-auto flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-col md:flex-col sm:flex-col xd:flex-col   items-start justify-start  gap-[0.5rem] w-full">
            <div className=' xxl:!w-[75%] bxl:!w-[75%] xl:!w-[75%] sxl:!w-[75%] lg:!w-full md:!w-full sm:!w-full xd:!w-full flex flex-col justify-start items-start gap-[2rem]'>
              <div className='flex gap-[0.5rem] xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-col sm:flex-col xd:flex-col justify-start items-start w-[100%] h-full'>
                <div className="!bg-black-900_19   rounded-[1rem] border-[rgba(255,255,255,0.10)] border-[1px] h-full   xxl:w-[75%] bxl:w-[75%] xl:w-[75%] sxl:w-[75%] lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%]">
                  <TradingViewWidget />
                </div>
                <div className='  xxl:w-[25%] bxl:w-[25%] xl:w-[25%] sxl:w-[25%] lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%] '>
                  <OrderBook handleSelectedPair={handleSelectedPair} />
                </div>
              </div>
              {/* <AllTrades /> */}
            </div>
            <div className='   xxl:!w-[25%] bxl:!w-[25%] xl:!w-[25%] sxl:!w-[25%] lg:!w-full md:!w-full sm:!w-full xd:!w-full flex flex-col'>
              <BuySell
                handleWalletConnect={handleWalletConnect}
                handleOpenDepositModal={handleOpenDepositModal}
                selectedPairFromOrderBook={selectedPair}
                handleSettingPairEmptyAgain={handleSettingPairEmptyAgain}
              />
            </div>
          </div>
          <div className='flex xxl:flex-row xxl:px-[1rem] bxl:px-[1rem] xl:px-[1rem] sxl:px-[1rem] lg:px-[1rem] md:px-[0rem] sm:px-[0rem] xd:px-[0rem] bxl:flex-row xl:flex-row  sxl:flex-row  lg:flex-col md:flex-col sm:flex-col xd:flex-col w-full gap-[0.5rem]   items-start justify-between w-full'>
            <div className='xxl:flex-[0.68] bxl:flex-[0.68] xl:flex-[0.68] sxl:flex-[0.68] lg:flex-[1] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full'><AllTrades /></div>
            <div className='xxl:flex-[0.32] bxl:flex-[0.32] xl:flex-[0.32] sxl:flex-[0.32] lg:flex-[1] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full'><GlobalTrades /></div>
          </div>
           {/* {isPosterVisible && <div className='fixed !right-0 !bottom-0 w-[20rem] h-auto m-3 ' onClick={() => { handlePosterClose() }}>
            <div className={`rounded-[0.8rem] border-[1px]  p-[0.5rem] flex flex-col justify-start items-center gap-[1rem] backdrop-blur-[4rem] ${isDarkMode ? ' !bg-[#FCFDFE] border-[#E3E8EF]' : ' !bg-[#1f1e1e] border-[rgba(255,255,255,0.10)]'} `}>
              <div className='w-full flex flex-row justify-between px-2 pt-2 items-center'>
                <div></div>
                <img src={isDarkMode ? CloseDark : Close} alt='' className='cursor-pointer' />
              </div>
              <img src={TradePoster} alt='SwapPoster' className='rounded-[0.5rem]' />
              <div

                className={` buy-button cursor-pointer  w-full `}
              >
                <a href='https://kanalabs.medium.com/join-kana-trade-referral-contest-win-exclusive-nfts-2k-apt-tokens-ab0fe442e2b8' target='_blank' rel="noreferrer">
                  <div className={`${isDarkMode ? 'buy-button-inner_blue' : 'buy-button-inner'} w-full h-full py-[0.938rem] flex flex-row justify-center items-center    font-bold leading-[normal] py-[0.938rem] rounded-[1rem] text-center text-gray-900 text-xl w-full`}>
                    <span className={`  ${isDarkMode ? 'button-text_dark' : 'button-text'}  `}>
                    Click To See Rules
                    </span>

                  </div>
                </a>
              </div>
            </div>
          </div>} */}
        </div>
        <Footer />
       

        {isConnectWalletisClicked && <ConnectWallet handleClose={handleCloseWalletConnect} />}
      </div>}
    </>
  );
};

export default TradeFivePage;
