import React, { useEffect, useState } from 'react';
import { client } from '../utils/Constants';
import { useStore } from '../store';
import { useAptosContext } from '../providers/AptosWalletContext';
import { getUiAmountReverse, isObjectNonEmpty } from '../utils/helper';
import aptos from '../assets/icons/AptosLogo.svg';
import usdc from '../assets/icons/usdc.svg';
import _ from 'lodash';
import { toDecimalPrice, toDecimalSize } from '@kanalabs/trade';
import BigNumber from 'bignumber.js';
import AptosBlack from '../assets/images/AptosBlack.png';
import eth from '../assets/icons/eth.svg';
import axios from 'axios';

const Information = () => {
  const {
    address: socialAddress,
    updateBalanceUpdate,
    balanceUpdate,
    tradePairInfo,
    isWalletConnected,
    marketDataInstance,
    lastPlacedPrice,
    needToUpdateGlobalTrades,
    resolutions,
    isDarkMode,
    updateIsDarkMode,
    dropdownValues: dropdDownValuesFromStore,
    updateResolutions,
    selectedMarketID
  } = useStore();
  const { connected, account }: any = useAptosContext();
  const [dropdownValues, updateDropdownValues] = useState<any>(dropdDownValuesFromStore);
  const [changePercentage, setChangePercentage] = useState<any>();
  const [highPrice, setHighPrice] = useState<any>(0.00);
  const [lowPrice, setLowPrice] = useState<any>(0.00);
  const [volume, setVolume] = useState<any>();
  const [lastPlacedTrade, setLastPlacedTrade] = useState<any>();




  const handleGetAccountBalance = async (tradePairInfoResponse: any) => {
    try {
      const resources = await client.getAccountResources(connected ? account.address : socialAddress)
      const coinStoreType = '0x1::coin::CoinStore';
      const coinResources = resources.filter((r: any) => r.type.startsWith(coinStoreType));
      const newArray: any = []
      coinResources?.map(async (res: any) => {
        if (res.type === ("0x1::coin::CoinStore<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC>")) {
          res.cointype = tradePairInfo?.quoteCoinInfo?.symbol
          res.balance = getUiAmountReverse(res?.data?.coin?.value, tradePairInfoResponse?.quoteCoinInfo?.decimals)
          newArray.push(res)
        } else if (res.type === ("0x1::coin::CoinStore<0x1::aptos_coin::AptosCoin>")) {
          res.cointype = 'APT'
          res.decimals = 8
          res.balance = getUiAmountReverse(res?.data?.coin?.value, 8)
          res.balanceInAptDecimalFormat = res?.data?.coin?.value
          if (tradePairInfo?.baseCoinInfo?.symbol === 'APT') {
            newArray.push(res)
          }
        }
        else if (res.type === ("0x1::coin::CoinStore<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WETH>")) {
          res.cointype = 'WETH'
          res.decimals = 6
          res.balance = getUiAmountReverse(res?.data?.coin?.value, res?.decimals)
          res.balanceInAptDecimalFormat = res?.data?.coin?.value
          if (tradePairInfo?.baseCoinInfo?.symbol === 'WETH') {
            newArray.push(res)
          }
        }
        return newArray
      })
      return newArray
    } catch (e) {
      console.log("")
    }
  }

  const handleGetUserInfo = async () => {
    if (connected || isWalletConnected) {
      const tradePairInfoResponse = _.cloneDeep(tradePairInfo)
      const newArray = await handleGetAccountBalance(tradePairInfoResponse)
      if (newArray) {
        try {
          const marketInfo = await marketDataInstance.getUserMarketAccount(connected ? account.address : socialAddress);
          if (marketInfo?.length > 0) {
            const userInfoBaseBalance = getUiAmountReverse(
              marketInfo[0]?.base_available,
              tradePairInfoResponse?.baseCoinInfo?.decimals
            );
            const userInfoQuoteBalance = getUiAmountReverse(
              marketInfo[0]?.quote_available,
              tradePairInfoResponse?.quoteCoinInfo?.decimals
            );
            const updateBalanceInCoinInfo = _.cloneDeep(dropdDownValuesFromStore);
            updateBalanceInCoinInfo.map((coin: any) => {
              if (coin.item === tradePairInfo?.baseCoinInfo?.symbol) {
                coin.marketAccountBalance = userInfoBaseBalance;
                coin.marketAccountDecimals = tradePairInfoResponse?.baseCoinInfo?.decimals;
                const baseBalance = newArray?.filter((item: any) => item.cointype === tradePairInfo?.baseCoinInfo?.symbol)
                coin.balance = baseBalance.length > 0 ? baseBalance[0].balance : 0;
              } else if (coin.item === tradePairInfo?.quoteCoinInfo?.symbol) {
                coin.marketAccountBalance = userInfoQuoteBalance;
                coin.marketAccountDecimals = tradePairInfoResponse?.quoteCoinInfo?.decimals;
                const quoteBalance = newArray?.filter((item: any) => item.cointype === tradePairInfo?.quoteCoinInfo?.symbol)
                coin.balance = quoteBalance.length > 0 ? quoteBalance[0].balance : 0;
              }
            });
            updateDropdownValues(updateBalanceInCoinInfo);
            updateBalanceUpdate(false)
          }
          return
        } catch (e) {
          updateBalanceUpdate(false)
        }
        if (newArray.length > 0) {
          const updateBalanceInCoinInfo = _.cloneDeep(dropdDownValuesFromStore);
          updateBalanceInCoinInfo.map((coin: any) => {
            if (coin.item === tradePairInfo?.baseCoinInfo?.symbol) {
              coin.marketAccountBalance = 0;
              coin.marketAccountDecimals = tradePairInfo?.baseCoinInfo?.decimals;
              const isPresent = newArray.filter((coin: any) => coin.cointype === tradePairInfo?.baseCoinInfo?.symbol)
              if (isPresent.length > 0) {
                coin.balance = isPresent[0].balance
              }
            } else if (coin.item === tradePairInfo?.quoteCoinInfo?.symbol) {
              coin.marketAccountBalance = 0;
              coin.marketAccountDecimals = tradePairInfo?.quoteCoinInfo?.decimals;
              const isPresent = newArray.filter((coin: any) => coin.cointype === tradePairInfo?.quoteCoinInfo?.symbol)
              if (isPresent.length > 0) {
                coin.balance = isPresent[0].balance
              }
            }
          });
          updateDropdownValues(updateBalanceInCoinInfo);
          updateBalanceUpdate(false)
        } else {
          updateDropdownValues(dropdownValues);
          updateBalanceUpdate(false)
        }
        updateBalanceUpdate(false)
      }
    }
  };

  useEffect(() => {
    if (isObjectNonEmpty(marketDataInstance))
      handleGetUserInfo()
  }, [marketDataInstance, connected, balanceUpdate, isWalletConnected, needToUpdateGlobalTrades, tradePairInfo]);


  useEffect(() => {
    if (lastPlacedPrice && lastPlacedPrice.price && lastPlacedPrice.price !== undefined && lastPlacedPrice.price !== 'NaN') {
      const closePrice: any = Number(toDecimalPrice({
        price: new BigNumber(resolutions?.close),
        lotSize: marketDataInstance.market.lotSize,
        tickSize: marketDataInstance.market.tickSize,
        baseCoinDecimals: new BigNumber(tradePairInfo.baseCoinInfo.decimals),
        quoteCoinDecimals: new BigNumber(tradePairInfo.quoteCoinInfo.decimals),
      }))
      const percentageChange = (Number((lastPlacedPrice?.price)) - closePrice) / closePrice * 100
      setChangePercentage(percentageChange?.toFixed(2));
    }
  }, [resolutions, lastPlacedPrice])

  const handleGetResolutions = async () => {


    // setTimeout(async()=>{
      if (isObjectNonEmpty(marketDataInstance)) {
        const hourChange: any = await axios.get(`https://aptos-mainnet-econia.nodeinfra.com/markets?market_id=eq.${selectedMarketID}`).then(res => { return res.data })
        if(hourChange && hourChange.length > 0){
          const highPriceValue: any = Number(toDecimalPrice({
            price: new BigNumber(hourChange[0]?.max_price_24h),
            lotSize: marketDataInstance.market.lotSize,
            tickSize: marketDataInstance.market.tickSize,
            baseCoinDecimals: new BigNumber(tradePairInfo.baseCoinInfo.decimals),
            quoteCoinDecimals: new BigNumber(tradePairInfo.quoteCoinInfo.decimals),
          })).toFixed(9)?.replace(/\.?0*$/, '')
          setHighPrice(highPriceValue);
          const lowPriceValue: any = Number(toDecimalPrice({
            price: new BigNumber(hourChange[0]?.min_price_24h),
            lotSize: marketDataInstance.market.lotSize,
            tickSize: marketDataInstance.market.tickSize,
            baseCoinDecimals: new BigNumber(tradePairInfo.baseCoinInfo.decimals),
            quoteCoinDecimals: new BigNumber(tradePairInfo.quoteCoinInfo.decimals),
          })).toFixed(9)?.replace(/\.?0*$/, '')
          setLowPrice(lowPriceValue);
          const convertedTick = hourChange[0]?.tick_size * hourChange[0]?.quote_volume_24h
          setVolume((convertedTick * 0.000001).toFixed(3))
          const lastTrade = toDecimalPrice({
            price: new BigNumber(hourChange[0]?.last_fill_price_24hr),
            lotSize: marketDataInstance.market.lotSize,
            tickSize: marketDataInstance.market.tickSize,
            baseCoinDecimals: new BigNumber(tradePairInfo.baseCoinInfo.decimals),
            quoteCoinDecimals: new BigNumber(tradePairInfo.quoteCoinInfo.decimals),
          }).toString()
          setLastPlacedTrade(lastTrade)
        }
      }
    // },10000)
   
  }
  useEffect(() => {
    handleGetResolutions()
  }, [selectedMarketID, marketDataInstance, lastPlacedPrice])


  return (
    <div className={` ${isDarkMode ? 'bg-[#FCFDFE] border-t-[1px] border-b-[1px] border-[#E3E8EF]' : 'bg-[#111213] border-t-[1px] border-b-[1px] border-[rgba(255,255,255,0.10)]'} border border-solid  w-full  flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-between xxl:items-center bxl:items-center xl:items-center sxl:items-center lg:items-center md:items-start sm:items-start xd:items-start py-[0.75rem] xxl:px-[2rem] bxl:px-[2rem] xl:px-[2rem] sxl:px-[2rem] lg:px-[2rem] md:px-[0.5rem] sm:px-[0.5rem] xd:px-[0.5rem]  md:gap-[1rem] sm:gap-[1rem] xd:gap-[1rem]`}>
      <div className="flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col !justify-start items-center xxl:gap-[2rem] bxl:gap-[2rem] xl:gap-[2rem] sxl:gap-[2rem] lg:gap-[2rem] md:gap-[1rem] sm:gap-[1rem] xd:gap-[1rem]   w-full">
        <div className="flex  flex-row   gap-[2rem]  xxl:justify-start bxl:justify-start xl:justify-start sxl:justify-start lg:justify-start md:justify-between sm:justify-between xd:justify-between">
          <div className="flex flex-col justify-start xxl:items-start bxl:items-start  xl:items-start  sxl:items-start  lg:items-start  md:items-start sm:items-start  xd:items-start  ">
            <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} text-[0.875rem] font-manrope font-[400]`}>24h change</div>
            {changePercentage !== undefined ? <div className={`${changePercentage > 0 ? 'text-[#12B76A] ' : 'text-[#F04438]'} text-[0.875rem] font-manrope font-[800]`}>{changePercentage > 0 ? '+' : '-'}{changePercentage} %</div > : < div className='text-[0.875rem] font-manrope font-[800]'> - </div>}
          </div>
          <div className="flex flex-col justify-start xxl:items-start bxl:items-start  xl:items-start  sxl:items-start  lg:items-start  md:items-center sm:items-center  xd:items-center   ">
            <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} text-[0.875rem] font-manrope font-[400]`}>24h low</div>
            <div className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} text-[0.875rem] font-manrope font-[800]`}>{parseFloat(lowPrice)?.toFixed(2)}</div>
          </div>
          <div className="flex flex-col justify-start xxl:items-start bxl:items-start  xl:items-start  sxl:items-start  lg:items-start  md:items-end sm:items-end  xd:items-end   ">
            <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} text-[0.875rem] font-manrope font-[400]`}>24h high</div>
            <div className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} text-[0.875rem] font-manrope font-[800]`}>{parseFloat(highPrice)?.toFixed(2)}</div>
          </div>
        </div>
        {<div className="flex flex-row   gap-[2rem]  xxl:justify-start bxl:justify-start xl:justify-start sxl:justify-start lg:justify-start md:justify-between sm:justify-between xd:justify-between">
          <div className="flex flex-col   justify-start xxl:items-start bxl:items-start  xl:items-start  sxl:items-start  lg:items-start  md:items-start sm:items-start  xd:items-start   ">
            <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} text-[0.875rem] font-manrope font-[400]`}>24h volume</div>
            <div className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} text-[0.875rem] font-manrope font-[800]`}>{volume?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}  {" "} {tradePairInfo?.quoteCoinInfo?.symbol} </div>
          </div>
          <div className="flex flex-col   justify-start xxl:items-start bxl:items-start  xl:items-start  sxl:items-start  lg:items-start  md:items-end sm:items-end  xd:items-end   ">
            <div className={`${isDarkMode?'text-[#777879]':'text-[#A5A5A6]'} text-[0.875rem] font-manrope font-[400]`}>Last Price</div>
            <div  className={`${isDarkMode?'text-[#4A4B4D]':'text-[rgba(255,255,255,0.80)]'} text-[0.875rem] font-manrope font-[800]`}>  {lastPlacedPrice.price !== "NaN" && parseFloat(lastPlacedPrice?.price)?.toFixed(9).replace(/\.?0*$/, '')} {" "} {tradePairInfo?.quoteCoinInfo?.symbol}</div>
          </div>
        </div>}
      </div>
      <div className="flex flex-row xxl:justify-end bxl:justify-end xl:justify-end sxl:justify-end lg:justify-end md:justify-between sm:justify-between xd:justify-between items-center gap-[1.5rem] w-full">
        <div className={` ${isDarkMode ? '  border-[#D2D2D2] ' : ' border-[rgba(255,255,255,0.10)] '} w-full xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden  border-[1px] h-[3rem] !w-[1px] `}> </div>
        <div className="flex flex-col xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start  ">


          <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} text-[0.875rem] font-manrope font-[400]`}>Wallet Balance</div>
          <div className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} text-[0.875rem] font-manrope font-[800]`}>
            {(connected || isWalletConnected) ? dropdownValues[0]?.balance?.toFixed(9)?.replace(/\.?0*$/, '') : "0.00"} {tradePairInfo?.baseCoinInfo?.symbol} /    {(connected || isWalletConnected) ? dropdownValues[1]?.balance?.toFixed(9)?.replace(/\.?0*$/, '') : "0.00"}  {tradePairInfo?.quoteCoinInfo?.symbol}

          </div>
        </div>
        <div className={` ${isDarkMode ? '  border-[#D2D2D2] ' : ' border-[rgba(255,255,255,0.10)] '} w-full xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden  border-[1px] h-[3rem] !w-[1px] `}> </div>
        <div className="flex flex-col xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start  ">


          <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} text-[0.875rem] font-manrope font-[400]`}>Trading Balance</div>
          <div className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} text-[0.875rem] font-manrope font-[800]`}>
            {(connected || isWalletConnected) ? dropdownValues[0]?.marketAccountBalance?.toFixed(9)?.replace(/\.?0*$/, '') : "0.00"}  {tradePairInfo?.baseCoinInfo?.symbol} /  {(connected || isWalletConnected) ? dropdownValues[1]?.marketAccountBalance?.toFixed(9)?.replace(/\.?0*$/, '') : "0.00"}   {tradePairInfo?.quoteCoinInfo?.symbol}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
