import React, { useEffect, useState } from 'react';
import down_arrow from '../assets/icons/select_downarrow.svg';
import aptos from '../assets/icons/AptosLogo.svg';
import usdc from '../assets/icons/usdc.svg';
import eth from '../assets/icons/eth.svg'
import { useStore } from '../store';
import { isObjectNonEmpty } from '../utils/helper';
import ClickAwayListener from 'react-click-away-listener';
import DropdownArrow from '../assets/icons/dropdown_dark.svg';
import AptosBlack from '../assets/images/AptosBlack.png';
import { TradeEventEmitter, eventId, subscribeEvents } from '@kanalabs/trade';

interface selectedPairInterface {
    market: string;
    marketId: number;
    lotSize: number;
    tickSize: number;
    minSize: number;
    quoteLogo: string;
    baseLogo: string;
    recognized: boolean;

}
const DropdownMenu = () => {
    const { availableMarkets, isDarkMode, isLoading: isLoadingFromStore, updateGlobalTrades, updateOrderBook, selectedMarketID, updateSelectedMarketID, marketDataInstance } = useStore();
    const [markets, setMarkets] = useState<any>();
    const [isTokenSelected, setIsTokenSelected] = useState(false);
    const [selectedPair, setSelectedPair] = useState<selectedPairInterface>()

    const handleTokenSelect = () => {
        if (isLoadingFromStore) return
        setIsTokenSelected(!isTokenSelected);
    };

    useEffect(() => {
        if (availableMarkets.length > 0) {
            const marketResponse = availableMarkets.filter((market: any) => market.marketId === 7 || market.marketId === 8);
            if (isObjectNonEmpty(marketResponse)) {
                const sortedMarketResponse = [...marketResponse].sort((a, b) => a.marketId - b.marketId);
                sortedMarketResponse.map((marketResponse: any) => {
                    if (marketResponse.market === 'APT/USDC') {
                        isDarkMode ? marketResponse.baseLogo = AptosBlack : marketResponse.baseLogo = aptos;
                        marketResponse.quoteLogo = usdc;
                    } else if (marketResponse.market === 'WETH/USDC') {
                        marketResponse.baseLogo = eth;
                        marketResponse.quoteLogo = usdc;
                    }
                })
                setMarkets(sortedMarketResponse);
                const defaultPair = sortedMarketResponse.find((market: any) => market.marketId === selectedMarketID);
                if (isObjectNonEmpty(defaultPair)) setSelectedPair(defaultPair);
            }
        }
    }, [availableMarkets, isDarkMode])

    const selectedTokenMethod = (e: any) => {
        setIsTokenSelected(false);
        setSelectedPair(e);
        const triggerEventEmitter = new TradeEventEmitter(marketDataInstance);
        triggerEventEmitter.terminateOrderBookEvent();
        triggerEventEmitter.terminateOrderHistoryEvent();
        localStorage.setItem('selectedMarketId', e.marketId)
        updateSelectedMarketID(e.marketId)

    };

    const handleClickAway = () => {
        isTokenSelected && setIsTokenSelected(false);
    }

    return (
        <div className='w-full '>
            <div
                className={` ${isDarkMode ? '!bg-[#EFF7F8] ' : '!bg-[#1D1E20]  '}  &&  ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex flex-row !justify-start items-center rounded-[1rem]  w-full`}
                onClick={handleTokenSelect}
                onChange={selectedTokenMethod}
            >
                <div className={`xxl:h-[4rem] bxl:h-[4rem] xl:h-[4rem] sxl:h-[4rem] lg:h-[4rem] md:h-[2.5rem] sm:h-[2.5rem] xd:h-[2.5rem]    ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} !bg-transparent  !shadow-none !font-inter flex flex-row     items-center justify-between  xxl:px-3 bxl:px-3 xl:px-3 sxl:px-3 lg:px-3 md:px-3 sm:px-0 xd:px-0 py-1  !w-full `}>
                    <div className="!bg-transparent  flex flex-row justify-start !items-center w-[10rem] !lg:w-full !md:w-1/2 sm:!w-full sm:pl-3 sm:!mt-1">
                        <img className="!bg-transparent  xxl:h-5 bxl:h-5 xl:h-5 sxl:h-5 lg:h-5 md:h-5 sm:h-4 xd:h-4  my-auto z-[14]  rounded-[50%] w-5" src={selectedPair && selectedPair?.baseLogo} alt="usdc" />
                        <img
                            className="!bg-transparent  xxl:h-5 bxl:h-5 xl:h-5 sxl:h-5 lg:h-5 md:h-5 sm:h-4 xd:h-4  ml-[-0.625rem] z-[12]   my-auto  rounded-[50%] w-6"
                            src={selectedPair && selectedPair.quoteLogo}
                            alt="matic"
                        />
                        <span className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} !bg-transparent  ml-1 xxl:text-[1rem] bxl:text-[1rem] xl:text-[1rem] sxl:text-[1rem] lg:text-[1rem] md:text-[0.8rem] sm:text-[0.8rem] xd:text-[0.8rem] font-[700]   xxl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-1 xd:py-1`}>
                            {selectedPair && selectedPair?.market}
                        </span>
                    </div>
                    <div className=" pt-0.5   !bg-transparent ">
                        {isDarkMode ? <img src={DropdownArrow} alt="DropdownArrow" className='!bg-transparent  h-3 w-3 mr-2 md:mr-4 sm:mr-4 xd:mr-4  ' /> : <img src={down_arrow} alt="DropdownArrow" className='!bg-transparent  h-3 w-3 mr-2 md:mr-4 sm:mr-4 xd:mr-4  ' />}
                    </div>
                </div>
            </div>
            {isTokenSelected && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div className={` ${isDarkMode ? '!bg-[#EFF7F8] ' : '!bg-[#1D1E20]  '}  !backdrop-blur-2xl !fixed !z-[999] mt-3 xxl:!w-[13.5%] bxl:!w-[22%] xl:!w-[21%] sxl:!w-[20.5%] lg:!w-[53%]  md:!w-[55%]  sm:!w-[56%] xd:!w-[55%]  rounded-[1rem] `}>
                        {markets?.length > 0 && markets.map((market: any, key: number) => {
                            return <>
                                <div key={key} className=' !bg-transparent  !w-full'>
                                    <div
                                        className={`xxl:h-[4rem] bxl:h-[4rem] xl:h-[4rem] sxl:h-[4rem] lg:h-[4rem] md:h-[2.5rem] sm:h-[2.5rem] xd:h-[2.5rem] cursor-pointer rounded-[1rem] !font-inter flex flex-row gap-6   items-center justify-start  px-3 py-1 !bg-transparent  !w-full ${isDarkMode?'hover:!bg-[rgba(0,249,169,0.1)]':'hover:!bg-[rgba(0,249,169,0.1)]'}  hover:rounded-[1rem]`}
                                        onClick={() => selectedTokenMethod(market)}
                                    >
                                        <div className=" flex !bg-transparent flex-row justify-start !items-center w-[10rem] !lg:w-full md:!w-full sm:!w-full   sm:!mt-1">
                                            <img className="  xxl:h-5 bxl:h-5 xl:h-5 sxl:h-5 lg:h-5 md:h-5 sm:h-4 xd:h-4 !bg-transparent  my-auto z-[14]  rounded-[50%] w-5" src={market?.baseLogo} alt="matic" />
                                            <img className="   xxl:h-5 bxl:h-5 xl:h-5 sxl:h-5 lg:h-5 md:h-6 sm:h-4 xd:h-4 ml-[-0.625rem] !bg-transparent  z-[12]   my-auto  rounded-[50%] w-6" src={market?.quoteLogo} alt="usdc" />
                                            <span className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} ml-2 xxl:text-[1rem] bxl:text-[1rem] xl:text-[1rem] sxl:text-[1rem] lg:text-[1rem] md:text-[0.8rem] sm:text-[0.8rem] xd:text-[0.8rem] !bg-transparent font-[700] xxl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-1 xd:py-1`}>
                                                {market.market}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    )
}

export default DropdownMenu