import React, { useMemo, useState } from 'react';
import closeIcon from '../assets/icons/close.svg';
import CloseDark from '../assets/icons/close_black.svg';
import { useAptosContext } from '../providers/AptosWalletContext';
import { Wallet, WalletReadyState } from '@manahippo/aptos-wallet-adapter';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from "react-i18next";
import { useStore } from "../store";
import ConnectSocialLogin from './ConnectSocialLogin';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import VideoModal from './VideoModal';
import MetaMask from '../assets/icons/AptosLogo.svg';
import CoinbaseTrade from '../assets/icons/coinbase_trade.svg';
import WalletTrade from '../assets/icons/wallet_trade.svg';
import MetamaskTrade from '../assets/icons/metamask_trade.svg';
import RainbowTrade from '../assets/icons/rainbow_trade.svg';
import PetraTrade from '../assets/icons/petra_trade.svg';
import PontemTrade from '../assets/icons/pontem_trade.svg';
interface ConnectWalletInterface {
  handleClose(typeOfLogin: string): void;

}
const ConnectWallet = (props: ConnectWalletInterface) => {
  const { updateBalanceUpdate, isLoading: isLoadingFromStore, updateIsLoading, updateNeedToUpdateGlobalTrades, isDarkMode } = useStore()

  const [selectedTab, setSelectedTab] = useState(0);

  // Function to handle tab selection
  const handleTabSelect = (index: any) => {
    setSelectedTab(index);
  };
  const panelClasses = `  rounded-b-[1rem] 
} `;
  const { t } = useTranslation();
  const { handleClose } = props;
  const { wallets, connect, account, disconnect, wallet }: any = useAptosContext();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false)
  const [detected, undetected] = useMemo(() => {
    const detected: any[] = [];
    const undetected: any[] = [];
    for (const wallet of wallets) {
      if (wallet.readyState === WalletReadyState.Installed || wallet.readyState === WalletReadyState.Loadable) {
        detected.push(wallet);
      } else if (wallet.readyState === WalletReadyState.NotDetected) {
        undetected.push(wallet);
      }
    }
    return [detected, undetected];
  }, [wallets]);
  const [isConected, setIsConnected] = useState<any>();

  const handleConnectWallet = async (wallet: Wallet) => {
    updateIsLoading(true)
    try {
      await connect(wallet.adapter.name)
      setIsConnected(wallet)
      updateNeedToUpdateGlobalTrades(true)
    } catch (e) {
      console.log()
    } finally {
      updateBalanceUpdate(true)
      handleClose('extension')
      updateIsLoading(false)
    }
  }

  const handleConnectSocialLogin = () => {
    handleClose('social')
  }

  const handleOpenVideo = () => {
    setIsVideoModalOpen(true)
  }

  const handleCloseVideoModal = () => {
    setIsVideoModalOpen(false)
  }

  return (<div
    onClick={() => {
      handleClose('close');
    }}
    id='second' className={`fixed z-[2] inset-0 h-full w-full ${isDarkMode ? 'bg-[rgba(255,255,255,0.20)]' : 'bg-[rgba(0,0,0,0.20)]'} backdrop-blur-lg flex flex-row justify-center  items-center  `}>
    {isLoadingFromStore ? <>  <Lottie
      loop
      animationData={Kanaloader}
      play
      className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
    /></> :
      <div onClick={(e) => {
        e.stopPropagation();
      }} id='wrapper'
        className={`  min-h-[18rem] ${isDarkMode ? '!bg-[#FCFDFE] border-[#E3E8EF]' : '!bg-[#17181A] border-[#1e1f22] '} xxl:w-[35%] bxl:w-[35%] xl:w-[35%] sxl:w-[35%] lg:w-[50%] md:w-[80%] sm:w-[95%] xd:w-[95%] 
           
           border-2 rounded-[1rem] `}
      >
        <div className={`flex justify-between p-[3%_5%] rounded-t-[1rem]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '} `}>
          <div className={` ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'} font-[700] text-[1.25rem]`}>
            {t('connect_wallet')}
          </div>
          <img
            src={isDarkMode ? CloseDark : closeIcon}
            alt="close"
            className="cursor-pointer"
            onClick={() => handleClose('close')}
          />
        </div>
        {/* {selectedTab === 0 && <div className=' text-base text-[#2ED3B7] p-[3%_5%] cursor-pointer' onClick={() => handleOpenVideo()}>Enjoy fast, gasless trades using social wallets. <span className='underline'>Click Here </span> for Demo!</div>}
          */}

        <div className="flex flex-col  justify-start  items-start ">

          <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} p-[1rem_1.5rem] text-[1rem] font-[400] font-[manrope]`}>Web3 Wallets</div>

          <div className='w-full'>
            <ul className="flex flex-col overflow-y-auto">
              {detected.length > 0 ? (
                detected?.map((item: any, index: any) => (
                  <div
                    className={`flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[1.3rem_1.5rem]   cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] w-full`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
                    onClick={() => handleConnectWallet(item)}
                    key={index}
                  >
                    <img className="w-14 pl-8 bg-transparent" src={item.adapter.icon} alt="token" />
                    <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
                      <div className={`text-lg ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFF]'}`}>{item.adapter.name}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={`text-center bg-transparent ${isDarkMode ? 'text-[black] ' : 'text-[white] '} text-lg font-bold flex flex-row justify-center items-center w-full h-full`}>{t('no_wallets_found')}</div>
              )}
            </ul>
            {/* <div className='flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-col xd:flex-col justify-start items-start w-full'>
              <div
                className={`flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[24px]   cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] xxl:w-1/2 bxl:w-1/2 xl:w-1/2 sxl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full xd:w-full`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
              >
                <img className="w-[1.5rem] h-[1.5rem] ml-2  bg-transparent" src={MetamaskTrade} alt="token" />
                <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
                  <div className={`text-lg ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFF]'}`}>Metamask</div>
                </div>
              </div>
              <div
                className={`flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[24px]   cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] xxl:w-1/2 bxl:w-1/2 xl:w-1/2 sxl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full xd:w-full`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
              >
                <img className="w-[1.5rem] h-[1.5rem] ml-2  bg-transparent" src={CoinbaseTrade} alt="token" />
                <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
                  <div className={`text-lg ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFF]'}`}>Coinbase Wallet</div>
                </div>
              </div>
            </div>
            <div className='flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-col xd:flex-col justify-start items-start w-full'>
              <div
                className={`flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[24px]   cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] xxl:w-1/2 bxl:w-1/2 xl:w-1/2 sxl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full xd:w-full`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
              >
                <img className="w-[1.5rem] h-[1.5rem] ml-2  bg-transparent" src={RainbowTrade} alt="token" />
                <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
                  <div className={`text-lg ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFF]'}`}>Rainbow</div>
                </div>
              </div>
              <div
                className={`flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[24px]   cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] xxl:w-1/2 bxl:w-1/2 xl:w-1/2 sxl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full xd:w-full`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
              >
                <img className="w-[1.5rem] h-[1.5rem] ml-2  bg-transparent" src={WalletTrade} alt="token" />
                <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
                  <div className={`text-lg ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFF]'}`}>WalletConnect</div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} p-[1rem_1.5rem] text-[1rem] font-[400] font-[manrope]`}>Aptos Wallets</div>
          <div className='flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-col xd:flex-col justify-start items-start w-full'>
            <div
              className={`flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[24px]   cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] xxl:w-1/2 bxl:w-1/2 xl:w-1/2 sxl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full xd:w-full`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
            >
              <img className="w-[1.5rem] h-[1.5rem] ml-2  bg-transparent" src={PetraTrade} alt="token" />
              <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
                <div className={`text-lg ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFF]'}`}>Petra</div>
              </div>
            </div>
            <div
              className={`flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[24px]   cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] xxl:w-1/2 bxl:w-1/2 xl:w-1/2 sxl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full xd:w-full`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
            >
              <img className="w-[1.5rem] h-[1.5rem] ml-2  bg-transparent" src={PontemTrade} alt="token" />
              <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
                <div className={`text-lg ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFF]'}`}>Pontem</div>
              </div>
            </div>
          </div> */}

        </div>
        {isVideoModalOpen && <VideoModal handleCloseVideoModal={handleCloseVideoModal} />}
      </div>
    }
  </div>

  );
};

export default ConnectWallet;

