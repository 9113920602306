/* eslint-disable no-self-assign */
import React, { useEffect, useState } from 'react';
import { List } from '../components';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { TradeTable, toDecimalPrice, toDecimalSize } from '@kanalabs/trade';
import BigNumber from 'bignumber.js';
import { isObjectNonEmpty } from '../utils/helper';
import red_arrow from '../assets/icons/red_download.svg';
import Green_arrow from '../assets/icons/green_up_arrow.svg';
import _ from 'lodash';

interface OrderBookInterface {
  handleSelectedPair(payload: any): void;
}

const OrderBook = (props: OrderBookInterface) => {
  const { handleSelectedPair } = props;
  const { t } = useTranslation();
  const { marketDataInstance, orderBook, globalTrades, tradePairInfo, updateLastPlacedPrice, lastPlacedPrice, isDarkMode } = useStore();
  const [orderBookEntries, setOrderBookEntries] = useState<any>();
  const [arrayOfAsks, setArrayOfAsks] = useState<any>();
  const [arrayOfBids, setArrayOfBids] = useState<any>();
  const [lastPlacedTrade, setLastPlacedTrade] = useState({
    price: "",
    isIncreased: false,
    previousTrade: ""
  })

  const handleGetTotalSumForAsk = async (sortedAsks: any) => {
    const sumValidation = [...sortedAsks]
    sumValidation?.reverse()?.map((ask: any, key: number) => {
      if (sumValidation.length - 1 < key) return
      if (key === 0) {
        const sumSize = ask.uiSize
        ask.sumSize = sumSize
      }
      else {
        const sumSize = parseFloat(ask.uiSize) + parseFloat(sumValidation[key - 1].sumSize)
        ask.sumSize = sumSize
      }
    })
    return sumValidation?.reverse()
  }

  const handleGetTotalSumForBid = async (sortedBids: any) => {
    const sumValidation = [...sortedBids]
    sumValidation?.reverse().map((bid: any, key: number) => {
      if (sumValidation.length - 1 < key) return
      if (key === 0) {
        const sumSize = parseFloat(bid.uiSize)
        bid.sumSize = sumSize
      }
      else {
        const sumSize = parseFloat(sumValidation[key - 1].sumSize) + parseFloat(bid.uiSize)
        bid.sumSize = sumSize
      }
    })
    return sumValidation
  }

  const handleGetCalculatedSizeAndPrice = (sortedElements: any, pairInfo: any) => {
    sortedElements?.map((value: any) => {
      const uiSize: any = toDecimalSize({
        size: new BigNumber(value.remaining_size),
        lotSize: marketDataInstance.market.lotSize,
        baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
      })
      const uiPrice: any = toDecimalPrice({
        price: new BigNumber(value.price),
        lotSize: marketDataInstance.market.lotSize,
        tickSize: marketDataInstance.market.tickSize,
        baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
        quoteCoinDecimals: new BigNumber(pairInfo.quoteCoinInfo.decimals),
      })
      value.uiSize = parseFloat(uiSize)
      value.uiPrice = parseFloat(uiPrice)
    })
    return sortedElements;
  }

  // const handleGetGraphSize = (sizeElements: any, type: string) => {
  // const size = [...sizeElements]
  // size.map((value: any, key: number) => {
  //    if(key === 0){
  //     value.graphSize = 100;
  //    }else{
  //     value.graphSize = ((size[0].sumSize * value.sumsize) % 100);
  //    }
  //   })
  //   console.log("Ele: ", size)
  // }

  const handleGetUniquePrice = (elements: any) => {
    const sortedElements = [...elements].sort((a, b) => a.uiPrice - b.uiPrice);
    const result = sortedElements.reduce((acc: any, item: any) => {
      const price = item.uiPrice;
      if (acc[price]) {
        acc[price].uiSize += item.uiSize;
      } else {
        acc[price] = { ...item };
      }
      return acc;
    }, {});
    const finalResult: any = Object.values(result);
    const sortedFinal = [...finalResult].sort((a, b) => a.uiPrice - b.uiPrice);
    return sortedFinal
  }


  const handleMidTrade = (getPairDetailsResponse: any) => {
    const uniqueSet = new Set();
    const result: TradeTable[] = [];
    const stateGlobalTrades: any = _.cloneDeep(globalTrades);
    stateGlobalTrades?.forEach((obj: any) => {
      const propertyValue = obj['txn_version'];
      if (!uniqueSet.has(propertyValue)) {
        uniqueSet.add(propertyValue);
        result.push(obj);
      }
    });
    const sortedTrades: any = [...result].sort((a, b) => b.txn_version - a.txn_version);
    const lastTrade = toDecimalPrice({
      price: new BigNumber(sortedTrades[0]?.price),
      lotSize: marketDataInstance.market.lotSize,
      tickSize: marketDataInstance.market.tickSize,
      baseCoinDecimals: new BigNumber(getPairDetailsResponse.baseCoinInfo.decimals),
      quoteCoinDecimals: new BigNumber(getPairDetailsResponse.quoteCoinInfo.decimals),
    }).toString()
    const lastBeforeOne = toDecimalPrice({
      price: new BigNumber(sortedTrades[1]?.price),
      lotSize: marketDataInstance.market.lotSize,
      tickSize: marketDataInstance.market.tickSize,
      baseCoinDecimals: new BigNumber(getPairDetailsResponse.baseCoinInfo.decimals),
      quoteCoinDecimals: new BigNumber(getPairDetailsResponse.quoteCoinInfo.decimals),
    }).toString()
    const lastPlacedTradeStruct = { ...lastPlacedTrade }
    lastPlacedTradeStruct.price = lastTrade
    lastPlacedTradeStruct.previousTrade = lastBeforeOne
    lastPlacedTradeStruct.isIncreased = lastTrade > lastBeforeOne ? true : lastTrade === lastBeforeOne ? true : false
    updateLastPlacedPrice(lastPlacedTradeStruct)
    setLastPlacedTrade(lastPlacedTradeStruct)
  }

  const handleGetOrderBookDetails = async () => {
    if (isObjectNonEmpty(marketDataInstance)) {
      const getPairDetailsResponse = _.cloneDeep(tradePairInfo)
      if (isObjectNonEmpty(orderBook) && isObjectNonEmpty(getPairDetailsResponse)) {
        const stateOrderBook: any = _.cloneDeep(orderBook)
        setOrderBookEntries(stateOrderBook)
        const uiPriceAndSizeForAsks = handleGetCalculatedSizeAndPrice(stateOrderBook.asks, getPairDetailsResponse)
        const uiPriceAndSizeForBids = handleGetCalculatedSizeAndPrice(stateOrderBook.bids, getPairDetailsResponse)
        const ask = handleGetUniquePrice(uiPriceAndSizeForAsks)
        const sumValidationAsks = await handleGetTotalSumForAsk(ask.reverse())
        setArrayOfAsks(sumValidationAsks.slice(-8))
        const bid = handleGetUniquePrice(uiPriceAndSizeForBids)
        const sumValidationBids = await handleGetTotalSumForBid(bid)
        setArrayOfBids(sumValidationBids)
        handleMidTrade(getPairDetailsResponse)
      }
    }
  }

  useEffect(() => {
    handleGetOrderBookDetails()
  }, [orderBook])

  const handleRefresh = () => {
    handleGetOrderBookDetails()
  }

  const handleOnClickAsk = (ask: any) => {
    const selectedPair = {
      type: 'ASK',
      pair: ask
    }
    handleSelectedPair(selectedPair)
  }

  const handleOnClickBid = (bid: any) => {
    const selectedPair = {
      type: 'BID',
      pair: bid
    }
    handleSelectedPair(selectedPair)
  }

  return (
    <div className={`  ${isDarkMode ? ' xxl:border-[1px]  xxl:border-[#E3E8EF]  bxl:border-[1px]  bxl:border-[#E3E8EF]  xl:border-[1px]  xl:border-[#E3E8EF]  sxl:border-[1px]  sxl:border-[#E3E8EF]  lg:border-none  md:border-none sm:border-none xd:border-none xxl:bg-[#FCFDFE] bxl:bg-[#FCFDFE] xl:bg-[#FCFDFE] sxl:bg-[#FCFDFE] lg:bg-[#FCFDFE] md:bg-[#E4F2F3] sm:bg-[#E4F2F3] xd:bg-[#E4F2F3]  ' : ' xxl:border-[1px]  xxl:border-[rgba(255,255,255,0.10)]  bxl:border-[1px]  bxl:border-[rgba(255,255,255,0.10)]  xl:border-[1px]  xl:border-[rgba(255,255,255,0.10)]  sxl:border-[1px]  sxl:border-[rgba(255,255,255,0.10)]  lg:border-none  md:border-none sm:border-none xd:border-none xxl:bg-[rgba(23,24,26,0.50)] bxl:bg-[rgba(23,24,26,0.50)] xl:bg-[rgba(23,24,26,0.50)] sxl:bg-[rgba(23,24,26,0.50)] lg:bg-[rgba(23,24,26,0.50)] md:bg-[#0C0C0D] sm:bg-[#0C0C0D] xd:bg-[#0C0C0D] '}  z-[0] flex-col gap-4 xxl:rounded-[1rem] bxl:rounded-[1rem] xl:rounded-[1rem] sxl:rounded-[1rem] lg:rounded-[1rem] md:rounded-[0rem] sm:rounded-[0rem] xd:rounded-[0rem]   xxl:h-[30rem] bxl:h-[30rem] xl:h-[30rem] sxl:h-[30rem] lg:h-[30rem] md:h-full sm:h-full xd:h-full    !w-full h-full flex `}>
      <div className="  flex flex-col   items-start justify-start   rounded-3xl w-full h-full">
        <div className={` ${isDarkMode ? ' border-[1px] border-b-[#E3E8EF] border-r-transparent border-l-transparent border-t-transparent ' : ' border-[1px] border-r-transparent border-l-transparent border-t-transparent border-b-[rgba(255,255,255,0.10)] '} !bg-transparent flex flex-row justify-start items-center w-full p-[0.85rem]  flex-row  items-center justify-start w-full  `}>
          <div className="text-[14px]  !bg-transparent  font-inter font-[700] text-[#777879] w-full">{t('order_books')}</div>
        </div>
        <div className="flex flex-col gap-0.5 items-center justify-center w-full px-[0.5rem] py-[0.5rem]   lg:px-[1rem] md:px-[1rem] sm:px-[1rem] xd:px-[1rem] ">
          <div className="flex flex-row   font-manrope items-center justify-between w-full  py-[0.5rem]">
            <div className={` ${isDarkMode ? 'text-[#4A4B4D]' : 'text-white-A700_cc'}  font-[700] text-[0.75rem]  flex flex-col justify-start items-start gap-[0] text-left flex-[0.3]`}>{t('price()')}<div className={`  ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'} text-[0.625rem] `}>{"(" + `${tradePairInfo && tradePairInfo?.quoteCoinInfo?.symbol}` + ")"}</div></div>
            <div className={` ${isDarkMode ? 'text-[#4A4B4D]' : 'text-white-A700_cc'}   font-[700] text-[0.75rem]  flex flex-col justify-end items-end gap-[0] text-right flex-[0.3]`}>{t('size()')}<div className={`  ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'} text-[0.625rem]  `}>{"(" + `${tradePairInfo && tradePairInfo?.baseCoinInfo?.symbol}` + ")"}</div></div>
            <div className={` ${isDarkMode ? 'text-[#4A4B4D]' : 'text-white-A700_cc'} text-[0.75rem] font-[700]  flex flex-col justify-end items-end gap-[0] text-right flex-[0.4]`}>{t('sum()')}<div className={`  ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'} text-[0.625rem]  `}>{"(" + `${tradePairInfo && tradePairInfo?.baseCoinInfo?.symbol}` + ")"}</div></div>
          </div>
          <List className="flex flex-col gap-0.5 items-center w-full" orientation="vertical">
            {orderBookEntries && arrayOfAsks && arrayOfAsks?.map((ask: any, key: number) => {
              return <div key={key} onClick={() => { handleOnClickAsk(ask) }} className={`flex  flex-row   !items-center !justify-between !w-full cursor-pointer ${isDarkMode?'hover:bg-[rgba(240,68,56,0.10)]':'hover:bg-[rgba(240,68,56,0.10)]'} px-0.5 rounded-[2px]`}>
                <div className={`flex flex-row items-center justify-between`} style={{ maxWidth: '100%', width: `100%`, backgroundSize: '100% 100%' }}>
                  <div className="xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem] !text-left font-[400] text-[#F04438]  flex-[0.3]">
                    {parseFloat(ask?.uiPrice)?.toFixed(3)}
                  </div>
                  <div className={`${isDarkMode ? ' text-[#777879] ' : ' text-[rgba(255,255,255,0.60)] '} xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem]  !text-right  font-[400]  flex-[0.3]`}>
                    {parseFloat(ask?.uiSize)?.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                  <div className={`${isDarkMode ? ' text-[#777879] ' : ' text-[rgba(255,255,255,0.60)] '} xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem]  !text-right  font-[400]  flex-[0.4]`}>
                    {ask?.sumSize?.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                </div>
              </div>
            })}
          </List>
          {isObjectNonEmpty(lastPlacedPrice) &&
            <div className="flex flex-row gap-[8px] items-center justify-start py-2 w-full">
              {(lastPlacedTrade.price !== "NaN" || lastPlacedTrade.price !== undefined) && <>
                <div className={`text-[18px] font-manrope ${lastPlacedTrade.isIncreased ? 'text-[#12B76A]' : 'text-[#F04438]'}  w-auto font-[400] text-left flex flex-row justify-start items-center gap-[8px]`}>
                  {lastPlacedTrade.price !== "NaN" && parseFloat(lastPlacedTrade?.price)?.toFixed(3)}
                </div>
                <div>
                  {(lastPlacedTrade.price !== "NaN" || lastPlacedTrade.price !== undefined) && lastPlacedTrade?.isIncreased ? <img src={Green_arrow} alt='increased' /> : <img src={red_arrow} alt='decreased' />}
                </div>
              </>}
            </div>}
          <List className="flex flex-col gap-0.5 items-center w-full" orientation="vertical">
            {orderBookEntries && arrayOfBids?.map((bid: any, key: number) => {
              if (key > 7) return
              if (!tradePairInfo?.baseCoinInfo || !tradePairInfo?.quoteCoinInfo) return null;
              return <div key={key} onClick={() => { handleOnClickBid(bid) }} className={`flex  flex-row   !items-center !justify-between !w-full cursor-pointer ${isDarkMode?'hover:bg-[rgba(18,183,106,0.10)]':'hover:bg-[rgba(18,183,106,0.10)]'} px-0.5 rounded-[2px]`}>
                <div className={`flex flex-row items-center justify-between`} style={{ maxWidth: '100%', width: `100%`, backgroundSize: '100% 100%' }}>
                  <div className="!text-left xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem] font-[400] text-[#12B76A]   flex-[0.3]">
                    {parseFloat(bid?.uiPrice)?.toFixed(3)}
                  </div>
                  <div className={`${isDarkMode ? ' text-[#777879] ' : ' text-[rgba(255,255,255,0.60)] '} xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem]  !text-right  font-[400]  flex-[0.3]`}>
                    {parseFloat(bid?.uiSize)?.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                  <div className={`${isDarkMode ? ' text-[#777879] ' : ' text-[rgba(255,255,255,0.60)] '} xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem]  !text-right  font-[400]  flex-[0.4]`}>
                    {bid?.sumSize?.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                </div>
              </div>
            })}
          </List>
        </div>
      </div>
    </div>
  );
};
export default OrderBook;