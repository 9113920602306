import React, { useEffect, useState } from 'react'
import { useStore } from '../store';
import LeaderBoardRank from '../components/Leaderboard/LeaderboardRank';
import Pagination from '../components/Leaderboard/Pagination';
import axios from 'axios';
import LeftArrow from '../assets/icons/Leaderboard_Left_Arrow.svg';
import RightArrow from '../assets/icons/Leaderboard_Right_Arrow.svg';
import { useAptosContext } from '../providers/AptosWalletContext';
import Header from '../components/Leaderboard/LeaderboardHeader';
import { LeaderboardSidebar } from '../components/Leaderboard/LeaderboardSidebar';
import { set } from 'lodash';
import ReferralContest from './ReferralContest';

const LeaderBoard = () => {

  const {
    isDarkMode,
  } = useStore();
  const { connected, account }: any = useAptosContext()

  const [page, setPage] = useState(1);
  const [paginatedRankArray, setPaginatedRankArray] = useState([{}]);
  const [totalPage, setTotalPage] = useState(0);
  const [personalRank, setPersonalRank] = useState('NA');
  const [personalActiveReferral, setActiveReferral] = useState(0);
  const [personalTotalReferral, setTotalReferral] = useState(0);
  const [totalActiveReferral, setTotalActiveReferral] = useState(0);
  const [rankArray, setRankArray] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isReferralContestOpen, setIsReferralContestOpen] = useState<boolean>(true);

  // const [displayTotalTO, setDisplayTotalTO] = useState<string>('');
  // useEffect(() => {
  //   if (personalTotalVolume >= 1000000000000) {
  //     setDisplayTotalTO(Math.floor((personalTotalVolume / 1000000000000) * 10) / 10 + 'T');
  //   }
  //   else if (personalTotalVolume >= 1000000000) {
  //     setDisplayTotalTO(Math.floor((personalTotalVolume / 1000000000) * 10) / 10 + 'B');
  //   }
  //   else if (personalTotalVolume >= 1000000) {
  //     setDisplayTotalTO(Math.floor((personalTotalVolume / 1000000) * 10) / 10 + 'M');
  //   }
  //   else if (personalTotalVolume >= 1000) {
  //     setDisplayTotalTO(Math.floor((personalTotalVolume / 1000) * 10) / 10 + 'K');
  //   }
  //   else {
  //     setDisplayTotalTO(personalTotalVolume + '');
  //   }
  // }, [personalTotalVolume]);



  const ITEMS_PER_PAGE = 10;

  const fetchData = async () => {
    try {
      const header = {
        'X-Api-Key': process.env.REACT_APP_LEADERBOARD_X_API_KEY || '',
      }
      const response = await axios.get('https://referrals-dev.kanalabs.io/trade/leaderboardData', { headers: header });
      setIsLoading(false);
      const fetchedData = response.data.trade;
      const totalActiveReferral = fetchedData.reduce((acc: any, item: any) => acc + item.tradeStatusCount, 0);
      setTotalActiveReferral(totalActiveReferral);
      const personalData = fetchedData.filter((item: any) => item.wallet_address.toLowerCase() === account?.address?.toLowerCase());
      if (personalData.length > 0) {
        setPersonalRank(fetchedData.indexOf(personalData[0]) + 1);
        setActiveReferral(personalData[0].tradeStatusCount);
        setTotalReferral(personalData[0].totalReferralCount);
      }
      setRankArray(fetchedData);
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [account]);

  useEffect(() => {
    if (rankArray?.length > 0) {
      const paginatedData = rankArray.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
      const calculatedTotalPage = Math.ceil(rankArray.length / ITEMS_PER_PAGE);
      setPaginatedRankArray(paginatedData);
      setTotalPage(calculatedTotalPage);
    }
  }, [page, rankArray]);


  const [openDeposit, setOpenDeposit] = useState<boolean>(false)
  const handleCloseDeposit = () => {
    setOpenDeposit(false)
  }

  const handleReferralContestClose = () => {
    setIsReferralContestOpen(false)
  }
  return (
    <div >
      <LeaderboardSidebar />
      <div className={`${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'} h-full font-inter flex !w-full min-h-screen`}>
        <Header openDeposit={openDeposit} handleCloseDeposit={handleCloseDeposit} />
        <div className={` ${isDarkMode ? ' !bg-[#E4F2F3] ' : ' !bg-[#0C0C0D] '} w-[100%] !mt-[5.5rem] xxl:!ml-[5.5rem] bxl:!ml-[5.5rem] xl:!ml-[5.5rem] sxl:!ml-[5.5rem] lg:!ml-0 md:!ml-0 sm:!ml-0 xd:!ml-0 gap-[0.5rem] flex-col justify-start items-start py-[0.5rem] flex pb-24`}>
          <div className='w-full flex flex-row justify-center items-start'>
            <div className="flex flex-col w-full px-4">
              <div className='mb-[1rem]   md:px-[1rem] sm:px-[1rem] xd:px-[1rem] w-[100%] '>
                <div className=" my-[16px]  gap-[16px] flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col-reverse sm:flex-col-reverse max-sm:flex-col-reverse lg:h-full w-[100%] h-[100%] sm:h-full ">
                  <div className={`flex flex-col xxl:w-[100%] ${isDarkMode ? 'bg-[white] border-[#E3E8EF] text-[black]' : 'bg-[#17181A] border-[#FFFFFF1A] text-[#FFFFFF]'} border-[1px] rounded-[16px] bxl:w-[70%] xl:w-[70%] sxl:w-[70%] lg:w-[70%] md:w-[100%] sm:w-[100%] max-sm:w-[100%] h-[100%] lg:h-[80%] md:h-[80%] sm:h-[80%]`}>
                    <div className={`w-[100%] px-[1.5rem] py-[1.5rem] font-[800] ${isDarkMode ? 'text-[#777879] border-[#E3E8EF]' : 'border-[#FFFFFF1A] text-[#A5A5A6]'}  border-b-2  h-[7.6%] items-center flex justify-between`}>
                      <div>Leaderboard</div>
                      <div className="mt-4"><span className='ml-4 mt-4 '>Total Active Referral</span>  <span className="mx-2">-</span> {/* Adjust mx-2 to control the spacing */}
                        <span>{`${totalActiveReferral.toLocaleString()}  / 20,000`}</span></div>
                    </div>
                    <div className={`w-[100%] px-[1.5rem] py-[1.5rem] font-[400] ${isDarkMode ? 'text-[#A5A5A6] bg-[#F2F9F9] border-[#E3E8EF]' : 'border-[#FFFFFF1A] bg-[#111213] text-[#777879]'}  border-b-2  flex h-[7.6%] items-center sm:text-[0.6rem] sm:px-3 lg:text-[0.9rem]`}>
                      <div className='w-[8%] flex justify-start sm:w-[7%]'>Rank</div>
                      <div className='w-[40%] flex justify-center sm:w-[31%]'>Address</div>
                      <div className='w-[26%] flex justify-end sm:w-[31%]'>Active Referral</div>
                      <div className='w-[26%] flex justify-end sm:w-[31%]'>Total Referral</div>
                    </div>
                    <div className={`w-[100%] px-[1.5rem] py-[1.5rem] font-[400] ${isDarkMode ? 'text-[#A5A5A6] border-[#E3E8EF]' : 'text-[#FFFFFF] border-[#FFFFFF1A]'}  border-b-2  flex h-[7.6%] items-center sm:text-[0.7rem] sm:px-3 lg:text-[0.9rem] justify-center ${isLoading ? '' : 'hidden'}`}>
                      Loading...
                    </div>
                    {rankArray?.length > 0 ? paginatedRankArray.map((item: any, index: any) => (
                      <LeaderBoardRank key={index} rank={(page - 1) * 10 + (index + 1)} address={item.wallet_address} activeReferrals={item.tradeStatusCount} totalReferrals={item.totalReferralCount} />
                    )) : <div></div>}
                    {rankArray?.length > 0 ? <div className={`text-[#A5A5A6] w-[100%] px-[1.5rem] py-[1.5rem] font-[400]  flex h-[7.6%] items-center sm:px-0`}>
                      <div className='flex items-center justify-center w-[100%]'>
                        <img src={LeftArrow} alt="LeftArrow" onClick={() => setPage(page - 1)} className={`${page === 1 ? 'hidden' : ''} cursor-pointer w-[3rem] h-[3rem] sm:w-[2.5rem] sm:h-[2.5rem] `} />
                        <Pagination totalPage={totalPage} currentPage={page} setPage={setPage} />
                        <img src={RightArrow} alt="RightArrow" onClick={() => setPage(page + 1)} className={`${page === Math.ceil(rankArray.length / ITEMS_PER_PAGE) ? 'hidden' : ''} cursor-pointer w-[3rem] h-[3rem] sm:w-[2.5rem] sm:h-[2.5rem]`} />
                      </div>
                    </div> : <div></div>}
                  </div>
                  <div className='xxl:w-[30%] bxl:w-[30%] xl:w-[30%] sxl:w-[30%] lg:w-[30%] md:w-[100%] sm:w-[100%] lg:h-full md:h-full sm:h-full'>
                    <div className={`w-[100%]  ${isDarkMode ? 'border-[#E3E8EF] text-[black] bg-[white] ' : 'border-[#FFFFFF1A] text-[#FFFFFF] bg-[#17181A] '}  border-[1px] rounded-[16px] lg:h-[100%] md:h-[100%] sm:h-[100%] `}>
                      <div className={`w-[100%] px-[1.5rem] py-[1.5rem] font-[800] ${isDarkMode ? 'text-[#777879] border-[#E3E8EF]' : 'text-[#A5A5A6] border-[#FFFFFF1A]'}  border-b-2  h-[7.6%] items-center flex`}>Your Stats</div>
                      <div className={`${connected ? 'hidden' : ''} border-[1px] ${isDarkMode ? 'border-[#0E9384] text-[#0E9384]' : 'border-[#2ED3B7] text-[#2ED3B7]'}  rounded-md w-[95%] mx-auto  h-[2rem] flex justify-center m-4 cursor-pointer`}>
                        {/* {isWalletConnected || connected ? */}
                        <div className='flex flex-row justify-start items-center gap-[0.5rem] sm:text-[0.9rem]'>
                          Connect wallet to see your stats
                        </div>

                      </div>
                      <div className={`${connected ? '' : 'hidden'} flex w-full px-[1.5rem] my-[2rem] ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFFFFF]'} `}>
                        <div className='w-[50%]'>Rank</div>
                        <div className='w-[50%] flex justify-end'>{personalRank}</div>
                      </div>
                      <div className={`${connected ? '' : 'hidden'} flex w-full px-[1.5rem] my-[2rem] ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFFFFF]'}`}>
                        <div className='w-[50%]'>Address</div>
                        <div className='w-[50%] flex justify-end'>{account?.address?.slice(0, 6) + '..' + account?.address?.toString()?.slice(-6)}</div>
                      </div>
                      <div className={`${connected ? '' : 'hidden'} flex w-full px-[1.5rem] my-[2rem] ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFFFFF]'}`}>
                        <div className='w-[50%]'>Active Referral</div>
                        <div className='w-[50%] flex justify-end'>{personalActiveReferral}</div>
                      </div>
                      <div className={`${connected ? '' : 'hidden'} flex w-full px-[1.5rem] my-[2rem] ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[#FFFFFF]'}`}>
                        <div className='w-[50%]'>Total Referral</div>
                        <div className='w-[50%] flex justify-end'>{personalTotalReferral}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isReferralContestOpen && <ReferralContest handleClose={handleReferralContestClose} referralURL = {'referralURL'}/>}
    </div>
  )
}

export default LeaderBoard;