import React from 'react';
import Lottie from 'react-lottie-player';
import Kanaloader from '../../assets/kanaloader.json';
import { useStore } from '../../store';
const Loader = () => {
    const { isDarkMode } = useStore()
    return (
        <div className={`fixed inset-0 !z-[3]  backdrop-blur-2xl  font-inter  items-center overflow-y-auto  h-[100%] w-[100%] flex flex-col justify-start  bg-[black]   `}>
            <div className="flex justify-center align-middle pt-60 !bg-transparent">
                <Lottie className='!bg-transparent' loop animationData={Kanaloader} play style={{ width: 150, height: 150 }} />
            </div>
            <div className={`!bg-transparent text-center text-[0.875rem]  align-middle 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-10 sm:pt-4 max-sm:pt-4 text-[#ffffff] `}>
                This may take few seconds, don’t close the window
            </div>
        </div>
    );
};

export default Loader;
