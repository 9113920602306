import React from 'react';
import closeIcon from '../assets/icons/close.svg';
import videoDemo from '../assets/video/tradeSocialDemo.webm';
interface VideoModalInterface {
  handleCloseVideoModal(value: boolean): void;
}
const VideoModal = (props: VideoModalInterface) => {
  const { handleCloseVideoModal } = props;

  const handleClose = () => {
    handleCloseVideoModal(true);
  };

  return (
    <div
      onClick={() => {
        handleCloseVideoModal(true);
      }}
      id="videoModal"
      className="fixed z-[2] inset-0  h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-lg flex flex-row justify-center items-center  "
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`!bg-[#17181A]
           border-[#1e1f22] 
           border-2 rounded-[1rem] h-fit w-3/4 `}
      >
        <div className="flex justify-between p-[1%_2%]  !bg-[rgba(255,255,255,0.06)] rounded-t-[1rem]   ">
          <div className="text-[white] font-[700] text-[1.25rem]"></div>
          <img src={closeIcon} alt="close" className="cursor-pointer" onClick={() => handleClose()} />
        </div>
        <video controls autoPlay className='p-2 h-min'  src={videoDemo} />
      </div>
    </div>
  );
};

export default VideoModal;
