import React, { useEffect, useState } from 'react';
import close from '../assets/icons/close.svg';
import CloseBlack from '../assets/icons/close_black.svg';
import { t } from 'i18next';
import { getUiAmountReverse, handleSignWeb3AuthTransaction, isObjectNonEmpty, onTokenAmountChange, preventPasteNegativeNumber } from '../utils/helper';
import { Button, List } from '../components';
import { useStore } from '../store';
import { useAptosContext } from '../providers/AptosWalletContext';
import { toast } from 'react-toastify';
import NewMessageNotification from '../components/NewMessageNotification';
import { BUY, SELL } from '@kanalabs/trade';
import { client } from '../utils/Constants';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import _ from 'lodash';
import ClickAwayListener from 'react-click-away-listener';

interface ModifyTradeInterface {
    trade: any;
    handleCloseModifyTrade(payload: any): void;
}

const ModifyTrade = (props: ModifyTradeInterface) => {
    const { handleCloseModifyTrade, trade } = props;
    const { marketDataInstance, isWalletConnected, address: socialAddress, isLoading: isLoadingFromStore, sdk, updateIsLoading, updateNeedToUpdateGlobalTrades, updateBalanceUpdate, balanceUpdate, needToUpdateGlobalTrades, isDarkMode } = useStore();
    const { connected, account, signAndSubmitTransaction }: any = useAptosContext()
    const [userinfo, setUserInfo] = useState<any>();
    const [modifyTrade, setModifyTrade] = useState<any>(trade?.sizeString);
    const [totalValue, setTotalValue] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState<any>(false);

    const handleGetUserInfo = async () => {
        if (connected || isWalletConnected) {
            try {
                if (isObjectNonEmpty(trade.marketInstance)) {
                    const marketInfo = await trade.marketInstance.getUserMarketAccount(connected ? account.address : socialAddress);
                    if (marketInfo?.length > 0) {
                        const user = marketInfo[0]
                        const userInfoBaseBalance = getUiAmountReverse(
                            user?.base_available,
                            trade?.baseCoinInfo?.decimals
                        );
                        const userInfoQuoteBalance = getUiAmountReverse(
                            user?.quote_available,
                            trade?.quoteCoinInfo?.decimals
                        );
                        user.baseBalance = userInfoBaseBalance
                        user.baseDecimals = trade?.baseCoinInfo?.decimals;
                        user.quoteBalance = userInfoQuoteBalance
                        user.quoteDecimals = trade?.quoteCoinInfo?.decimals;
                        setUserInfo(user)
                    }
                    updateNeedToUpdateGlobalTrades(false);
                    updateBalanceUpdate(false)
                }
            } catch (e) {
                console.log("")
            }
        } else {
            updateNeedToUpdateGlobalTrades(false);
            updateBalanceUpdate(false)
            setUserInfo(undefined)
        }
    };

    // const handleMax = () => {
    //     setModifyTrade(parseFloat(userinfo?.baseBalance))
    // }

    const handleEnterModifyTrade = (enteredValue: any) => {
        if (enteredValue?.length > 8) {
            return
        }
        const validatedValue = onTokenAmountChange(enteredValue);
        setModifyTrade(validatedValue);
    }

    useEffect(() => {
        const totalValue = trade?.priceString * modifyTrade;
        setTotalValue(totalValue)
    }, [modifyTrade])

    useEffect(() => {
        handleGetUserInfo()
    }, [marketDataInstance, connected, balanceUpdate, isWalletConnected, needToUpdateGlobalTrades, trade]);

    const handleModifyOrderSize = async () => {
        if (isLoadingFromStore) return
        if (modifyTrade == trade?.sizeString) return toast(<NewMessageNotification link={"none"} type={"error"} message={"Quantity you want to modify should not be same as existing quantity"} />);
        if (trade.orderType === 'ASK') {
            if (modifyTrade > (parseFloat(trade?.sizeString) + parseFloat(userinfo.baseBalance)))
                return toast(<NewMessageNotification link={"none"} type={"error"} message={"You didn't have enough Base coin to place sell order"} />);
        }
        if (trade.orderType === 'BID') {
            if (totalValue > (parseFloat(userinfo.quoteBalance) + (parseFloat(trade?.priceString) * parseFloat(trade?.sizeString))))
                return toast(<NewMessageNotification link={"none"} type={"error"} message={"You didn't have enough Quote coin to place buy order"} />);
        }
        try {
            setIsLoading(true)
            const editOrderSize = trade?.marketInstance?.changeOrderSize(trade.orderType === 'ASK' ? SELL : BUY, trade.marketOrderId.toBigInt().toString(), modifyTrade)
            let hash = ""
            let res = ""
            if (connected) {
                const hashResponse = await signAndSubmitTransaction(editOrderSize, {
                    gas_unit_price: "100",
                    max_gas_amount: "6000"
                });
                await client.waitForTransaction(hashResponse.hash)
                hash = hashResponse.hash
                const response = await client.getTransactionByHash(hash);
                res = response.vm_status;
            } else if (isWalletConnected) {
                const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, editOrderSize)
                if (error) {
                    setIsLoading(false)
                    return toast(<NewMessageNotification link={transactionHash} type={"error"} message={status} />);
                }
                hash = transactionHash
                res = status;
            }
            if (res === 'Executed successfully') {
                setIsLoading(false)
                toast(<NewMessageNotification link={hash} type={"success"} message='Order modified successfully' />);
                updateNeedToUpdateGlobalTrades(true)
                return;
            }
            setIsLoading(false)
            toast(<NewMessageNotification link={hash} type={"error"} message='Please try again' />);
        } catch (e: any) {
            setIsLoading(false)
            if (e.name === 'Rejected') {
                return toast(<NewMessageNotification link={"none"} type={"error"} message={`Rejected by User`} />);
            }
            const error = e as Error
            return toast(<NewMessageNotification link={"none"} type={"error"} message={error?.message} />);
        }
    }


    const handleCloseTrade = async () => {
        try {
            if (isLoadingFromStore) return
            setIsDeleteLoading(true)
            const closeOrder = trade?.marketInstance.cancelOrder(trade.orderType === 'ASK' ? SELL : BUY, trade.marketOrderId.toBigInt().toString());
            let hash = ""
            let res = ""
            if (connected) {
                const hashResponse = await signAndSubmitTransaction(closeOrder, {
                    gas_unit_price: "100",
                    max_gas_amount: "6000"
                });
                await client.waitForTransaction(hashResponse.hash)
                hash = hashResponse.hash
                const response = await client.getTransactionByHash(hash);
                res = response.vm_status;
            } else if (isWalletConnected) {
                const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, closeOrder)
                if (error) {
                    setIsDeleteLoading(false)
                    return toast(<NewMessageNotification link={transactionHash} type={"error"} message={status} />);
                }
                hash = transactionHash
                res = status;
            }
            if (res === 'Executed successfully') {
                setIsDeleteLoading(false)
                toast(<NewMessageNotification link={hash} type={"success"} message='Order Cancelled Successfully' />,);
                updateNeedToUpdateGlobalTrades(true)
                return;
            }
            setIsDeleteLoading(false)
            toast(<NewMessageNotification link={hash} type={"error"} message='Please try again' />);
        } catch (e: any) {
            setIsDeleteLoading(false)
            if (e.name === 'Rejected') {
                return toast(<NewMessageNotification link={"none"} type={"error"} message={`Rejected by User`} />,);
            }
            const error = e as Error
            return toast(<NewMessageNotification link={"none"} type={"error"} message={error?.message} />);
        }
    };
    useEffect(() => {
        updateIsLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        updateIsLoading(isDeleteLoading)
    }, [isDeleteLoading])

    return (
        <div
            className={`  !font-inter !fixed !inset-0 z-[24] xxl:pt-24 xl:pt-24 lg:pt-24 md:pt-24 sm:!pt-0 max-h-[full] flex flex-row xxl:items-center bxl:items-center xl:items-center sxl:items-center lg:items-center md:items-end sm:items-end xd:items-end justify-center align-middle backdrop-blur-[1rem] overflow-y-auto bg-transparent sm:w-full md:w-full`}
        >
            <ClickAwayListener onClickAway={() => handleCloseModifyTrade(true)} >
                <div className={` ${isDarkMode ? '  bg-[rgba(252,253,254,0.70)]  xxl:border-[#E3E8EF] bxl:border-[#E3E8EF] xl:border-[#E3E8EF] sxl:border-[#E3E8EF] lg:border-[#E3E8EF] md:border-[#E3E8EF]' : 'xxl:border-[#2e2e2e] bxl:border-[#2e2e2e] xl:border-[#2e2e2e] sxl:border-[#2e2e2e] lg:border-[#2e2e2e] md:border-[#2e2e2e] bg-transparent'} !border-[1px] border-solid   sm:border-none xd:border-none rounded-[1rem] `}>
                    <div className="bg-transparent   flex flex-col   items-center justify-start   rounded-[1rem]   sm:w-full !backdrop-blur-[2.5rem]">
                        <div className={`${isDarkMode ? 'bg-[#EFF7F8]' : 'bg-[rgba(255,255,255,0.06)] '} p-[20px_24px] flex rounded-[1rem_1rem_0rem_0rem] flex-row sm:flex-row  gap-6 items-start justify-between xxl:w-[560px] bxl:w-[560px] xl:w-[560px] sxl:w-[560px] lg:w-[560px] md:w-screen sm:w-screen xd:w-screen`}>
                            <div className={`${isDarkMode ? 'text-[#0C0C0D] ' : 'text-[#fff] '} font-[800] md:text-[1.375rem]  w-auto bg-transparent`}>Modify order </div>
                            <div
                                className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex flex-row gap-2 items-center justify-start w-auto bg-transparent`}
                                onClick={() => handleCloseModifyTrade(true)}
                            >
                                <img className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} h-5 w-5 `} src={isDarkMode ? CloseBlack : close} alt="close" />
                            </div>
                        </div>
                        <div className="p-[0.5rem] w-full ">
                            <div className={` ${isDarkMode ? 'bg-[#FCFDFE] border-[#E3E8EF]' : 'bg-[#17181A] border-[rgba(255,255,255,0.10)]'} flex flex-col gap-2 items-center !justify-between w-full h-full p-[1rem]  border-[1px] rounded-[0.5rem] `}>
                                <div className="flex flex-row h-8  items-center justify-between cursor-default rounded-[1rem] !w-full ">
                                    <div className="flex flex-row items-center justify-start  ">
                                        <div className={`text-left  text-[12px]  font-[400] ${isDarkMode ? 'text-[#777879]' : 'text-[#777879]'}`}>Order Type</div>
                                    </div>
                                    <div className="flex flex-row   gap-2 items-center justify-start py-2 ">
                                        <div
                                            className={`${trade && trade?.orderType !== 'BID' ?
                                                `  ${isDarkMode ? 'bg-[#CFF9FE] !border-[#088AB2] text-[#088AB2]' : 'bg-[#0C0C0D] !border-[#088AB2] text-[rgba(255,255,255,0.80)]'}` :
                                                `${isDarkMode ? 'bg-[#E4F2F3] !border-[#0E9384] text-[#4A4B4D]' : 'bg-[#0C0C0D] !border-[#2ED3B7] text-[rgba(255,255,255,0.80)]'}`}  
                                         border-[1px]  cursor-pointer border-[1px]  text-center text-[12px] font-[500] w-auto p-[0.5rem_0.8rem] rounded-[0.5rem] border-[rgba(255,255,255,0.10)]`}
                                        >
                                            {trade && trade?.orderType === 'BID' ? 'BUY Limit' : 'SELL Limit'}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full  flex flex-col !justify-between items-center h-full">
                                    <div className="w-full  flex flex-col  gap-[1rem] ">
                                        <div className={` ${isDarkMode ? 'bg-[#EFF7F8] border-[#E3E8EF]' : 'bg-[#111213] border-[rgba(255,255,255,0.10)]'} w-full  border-[1px] rounded-[1rem] p-[0.5rem] flex flex-col items-start justify-start gap-[0.5rem] `}>
                                            <div className={` ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D] '} flex  border-none rounded-[0.5rem] flex-col items-start justify-center px-[1rem] py-[0.8rem] rounded-[1rem] w-full sm:w-full`}>
                                                <div className=" flex flex-col items-center justify-start w-full bg-transparent">
                                                    <div className="flex flex-row gap-4 h-full items-center justify-start w-full bg-transparent">
                                                        <span className={` flex flex-row justify-start items-center gap-[0.2rem] w-[50%] text-[12px] font-manrope font-[400]  text-left ${isDarkMode ? 'text-[#D2D2D2]' : 'text-[#4A4B4D]'}`}>
                                                            Price <div className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'}`}>{trade?.quoteCoinInfo?.symbol}</div>
                                                        </span>
                                                        <input
                                                            className={` ${isDarkMode ? 'text-[#4A4B4D]' : '!text-[white]'} cursor-not-allowed  text-2xl w-[50%] bg-transparent font-manrope   outline-none text-[18px] font-[400]     text-right  `}
                                                            disabled={true}
                                                            value={parseFloat(trade?.priceString)?.toFixed(9).replace(/\.?0*$/, '')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" flexflex-row justify-between items-center w-full px-[0.5rem]">
                                                <div className={` font-manrope font-[400] text-[10px] w-auto pb-1 flex flex-row justify-start items-center gap-[0.5rem] ${isDarkMode ? 'text-[#D2D2D2]' : 'text-[#4A4B4D]'}`}>
                                                    Balance

                                                    <div className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'}`}>
                                                        {userinfo?.quoteBalance ? userinfo?.quoteBalance?.toFixed(9).replace(/\.?0*$/, '') : 0.00} {""}
                                                        {trade?.quoteCoinInfo?.symbol}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <List className="flex flex-col gap-[1rem] items-start w-full bg-transparent" orientation="vertical">
                                            <div className={`w-full ${isDarkMode ? 'bg-[#EFF7F8] border-[#E3E8EF]' : 'bg-[#111213] border-[rgba(255,255,255,0.10)]'} border-[1px] rounded-[1rem]            p-[0.5rem] flex flex-col items-start justify-start gap-[0.5rem] `}>
                                                <div className={`flex ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D] '}  border-none rounded-[0.5rem] flex-col items-start justify-center px-[1rem] py-[0.8rem] rounded-[1rem] w-full sm:w-full`}>
                                                    <div className="flex flex-col items-center justify-start w-full bg-transparent">
                                                        <div className="flex flex-row gap-4 h-full items-center justify-start w-full bg-transparent">
                                                            <span className={`flex flex-row justify-start items-center gap-[0.2rem]  w-[50%] text-[12px] font-manrope font-[400]  text-left ${isDarkMode ? 'text-[#D2D2D2]' : 'text-[#4A4B4D]'}`}>
                                                                Amount
                                                                <div className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'}`}>
                                                                    {trade?.baseCoinInfo?.symbol}
                                                                </div>
                                                            </span>
                                                            <input
                                                                className={` text-2xl ${isDarkMode ? 'text-[#4A4B4D]' : '!text-[white]'} w-[50%] bg-transparent font-manrope  outline-none text-[18px] font-[400] placeholder:text-white-A700_99   text-right  `}
                                                                placeholder="0.00"
                                                                min="0"
                                                                disabled={isLoading}
                                                                autoFocus={true}
                                                                value={modifyTrade}
                                                                onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                                                onChange={(e) => { handleEnterModifyTrade(e.target.value) }}
                                                                onPaste={preventPasteNegativeNumber}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row justify-between items-center w-full px-[0.5rem]">
                                                    <div className={`font-manrope font-[400] text-[10px] w-auto pb-1  flex flex-row justify-start items-center gap-[0.5rem] ${isDarkMode ? 'text-[#D2D2D2]' : 'text-[#4A4B4D]'}`}>
                                                        Balance
                                                        <div className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'}`}>
                                                            {userinfo?.baseBalance ? userinfo?.baseBalance?.toFixed(9).replace(/\.?0*$/, '') : 0.00} {""}
                                                            {trade?.baseCoinInfo?.symbol}
                                                        </div>
                                                    </div>
                                                    {/* <div onClick={() => handleMax()} className="bg-[#121926] cursor-pointer  text-[#088AB2] font-manrope text-[10px]  rounded-[0.5rem] p-[0.125rem_0.5rem] font-[700]">
                                                    MAX
                                                </div> */}
                                                </div>
                                            </div>
                                            <div className={` ${isDarkMode ? 'border-[#E3E8EF]' : 'border-[rgba(255,255,255,0.10)]'} bg-transparent    border-[1px] rounded-[1rem]  flex flex-col gap-2 items-center justify-start   py-[0.5rem] px-[1rem]  w-full flex flex-col gap-[0.5rem]`}>
                                                <div className="flex  flex-row  items-center justify-between bg-transparent w-full">
                                                    <span className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'}  text-[0.75rem] font-manrope font-[400] text-left w-full`}>
                                                        {trade && trade?.orderType === 'BID' ? 'You Pay' : 'You Receive'}
                                                    </span>
                                                    <input
                                                        className={`text-2xl w-[50%] px-1 bg-transparent font-manrope ${isDarkMode ? '!text-[#4A4B4D]' : '!text-[rgba(255,255,255,0.80)]'} outline-none text-[0.75rem] font-[400]   placeholder:font-[400]   text-right  `}
                                                        value={totalValue && parseFloat(totalValue)?.toFixed(9).replace(/\.?0*$/, '')}
                                                        readOnly
                                                        placeholder="0.00"
                                                    />
                                                    <span className={`${isDarkMode ? '!text-[#A5A5A6]' : '!text-[#777879]'} text-[12px] font-manrope  font-bold`}>{trade?.quoteCoinInfo?.symbol}</span>
                                                </div>
                                                {/* <div className="flex  flex-row  items-center justify-between bg-transparent w-full ">
                                                <span className="text-[#777879] w-[42%] text-[0.75rem] font-manrope font-[400] text-left w-full">
                                                    Time of execution
                                                </span>
                                                <span className="!text-[rgba(255,255,255,0.80)] w-[58%] text-[0.75rem] font-manrope  font-[400] text-right">
                                                    {date}
                                                </span>
                                            </div> */}
                                            </div>
                                        </List>
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between items-center gap-[0.5rem] w-full mt-[0.5rem]">
                                    <Button onClick={() => handleCloseTrade()} className={`  cursor-pointer buy-button-red w-full `}>
                                        <div className={` ${isDarkMode ? 'buy-button-inner_blue' : 'buy-button-inner'} w-full h-full py-[0.938rem] flex flex-row justify-center items-center    font-bold leading-[normal] py-[0.938rem] rounded-[1rem] text-center text-gray-900 text-xl w-full`}>
                                            {isDeleteLoading ? <Lottie
                                                loop
                                                animationData={Kanaloader}
                                                play
                                                className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                                            /> : <span className="button-text-red"> Delete order</span>}
                                        </div>
                                    </Button>
                                    <Button onClick={() => handleModifyOrderSize()} className={`  cursor-pointer buy-button w-full `}>
                                        <div className={` ${isDarkMode ? 'buy-button-inner_blue' : 'buy-button-inner'} w-full h-full py-[0.938rem] flex flex-row justify-center items-center    font-bold leading-[normal] py-[0.938rem] rounded-[1rem] text-center text-gray-900 text-xl w-full`}>
                                            {isLoading ? <Lottie
                                                loop
                                                animationData={Kanaloader}
                                                play
                                                className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                                            /> : <span className={` ${isDarkMode ? 'button-text_dark' : 'button-text'} `}> Save</span>}
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default ModifyTrade;
