import React from 'react';
import {
  AptosWalletAdapter,
  BitkeepWalletAdapter,
  PontemWalletAdapter,
  RiseWalletAdapter,
  useWallet,
  WalletProvider,
  MartianWalletAdapter
} from '@manahippo/aptos-wallet-adapter';
import { ReactNode, useMemo } from 'react';

export const useAptosContext = useWallet;

export const AptosWalletProvider = ({ children }: { children: ReactNode }) => {
  const wallets = useMemo(
    () => [
      new AptosWalletAdapter(),
      new RiseWalletAdapter(),
      new PontemWalletAdapter(),
      new MartianWalletAdapter()
      //new BitkeepWalletAdapter(),
    ],
    []
  );

  const onErrorHandle = (e: Error) => {
  console.log()
  };

  return (
    <WalletProvider wallets={wallets} autoConnect = {true} onError={onErrorHandle}>
      {children}
    </WalletProvider>
  );
};

export default AptosWalletProvider;
