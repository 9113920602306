import { ProSidebarProvider } from 'react-pro-sidebar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SidebarPage } from './components/Sidebar';
import AptosWalletProvider, { useAptosContext } from './providers/AptosWalletContext';
import { useStore } from './store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { econia, tradebookURL } from './utils/Constants';
import { ENVIRONMENT, eventId, TradeEventEmitter, tradeEventEmitter } from '@kanalabs/trade';
import { WebAuthInit } from './Web3Auth';
import { handleInitWallet, isObjectNonEmpty } from './utils/helper';
import './index.css';
import eth from '../src/assets/icons/eth.svg';
import AptosBlack from '../src/assets/images/AptosBlack.png';
import aptos from '../src/assets/icons/AptosLogo.svg';
import usdc from '../src/assets/icons/usdc.svg';
import axios from 'axios';
import DisclaimerPopup from './pages/DisclaimerPopup';

const App = () => {
  const { connected } = useAptosContext();
  const { address, updateDropdownValues, updateSelectedMarketID, marketDataInstance, isDarkMode, selectedMarketID, updateResolutions, updateIsWalletConnected, updateAvailableMarkets, updateTradePairInfo, updateRegisteredMarkets, updateMarketDataInstance, updateOrderBook, updateGlobalTrades, isLoading, updateAptosWeb3Instance } = useStore();
  const [marketCalled, setMarketCalled] = useState<boolean>();
  const intervalRef: any = useRef(null);

  const handleGetOrderbook = async (marketID: number) => {
    const headers = {
      'x-api-key': process.env.REACT_APP_ORDERBOOK_API_KEY,
    };
    const response = await axios.get(`${tradebookURL}/orderBook?marketId=${marketID}`, {
      headers: headers as any,
    });
    if (response.status === 200) {
      return { error: false, data: response.data.order_book }
    }
    return { error: true, data: {} }
  }

  const handleGetEmitters = async () => {
    try {
      const tradeHistoryResponse = await marketDataInstance?.getAllTrades();
      updateGlobalTrades(tradeHistoryResponse)
      const marketID = marketDataInstance.market.marketId
      const { error, data } = await handleGetOrderbook(marketID)
      // const orderBookResponse = await marketDataInstance?.getOrderBook(true, {
      //   maxAsk: 100,
      //   maxBids: 100,
      // });
      // console.log("orderBookResponse: ", orderBookResponse)
      if (error) return
      updateOrderBook(data)
    }
    catch (e) {
      console.log();
    }
  }

  useEffect(() => {
    const id = setInterval(() => {
      handleGetEmitters()
    }, 1000);
    intervalRef.current = id;
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [marketDataInstance]);

  useEffect(() => { clearInterval(intervalRef.current); }, [marketCalled])

  const getMarketDetails = async () => {
    try {
      setMarketCalled(!marketCalled)
      const marketIDFromLocal = localStorage.getItem('selectedMarketId');
      const marketID = marketIDFromLocal !== null ? marketIDFromLocal : 7;
      updateSelectedMarketID(Number(marketID))
      const registeredMarkets = await econia.fetchRegisteredMarkets(ENVIRONMENT.MAINNET);
      updateRegisteredMarkets(registeredMarkets);
      const availableMarkets = await econia.getAvailableMarkets(registeredMarkets);
      updateAvailableMarkets(availableMarkets);
      const marketDataInstance = await econia.markets(
        registeredMarkets.filter((market: any) => market.marketId === Number(marketID))[0],
        ENVIRONMENT.MAINNET
      );
      if (isObjectNonEmpty(marketDataInstance)) {
        const resolutions = await marketDataInstance?.getCandleStickData(86400, Number(marketID))
        if (resolutions?.length > 0)
          updateResolutions(resolutions[0])
        updateMarketDataInstance(marketDataInstance)
        const pairInfo = await marketDataInstance?.getTradePairInfo();
        updateTradePairInfo(pairInfo);
        const dropdownValues = [
          {
            item: pairInfo?.baseCoinInfo?.symbol,
            selected: false,
            icon: pairInfo?.baseCoinInfo?.symbol === 'APT' ? isDarkMode ? AptosBlack : aptos : eth,
            balance: 0,
            decimals: 0,
            marketAccountBalance: 0,
            marketAccountDecimals: 0
          },
          {
            item: pairInfo?.quoteCoinInfo?.symbol,
            selected: false,
            icon: usdc,
            balance: 0,
            decimals: 0,
            marketAccountBalance: 0,
            marketAccountDecimals: 0
          },
        ]
        updateDropdownValues(dropdownValues)
        // const triggerEventEmitter = new TradeEventEmitter(marketDataInstance);
        // await triggerEventEmitter.triggerToOrderBook();
        // await triggerEventEmitter.triggerTradeHistory();
      }
      return 0
    } catch (e) {
      console.log("")
    }
  };



  // tradeEventEmitter.on(eventId.orderBook, (data: any) => {
  //   updateOrderBook(data)
  // });

  // tradeEventEmitter.on(eventId.tradeHistory, (data: any) => {
  //   updateGlobalTrades(data)
  // });

  useEffect(() => { getMarketDetails() }, [selectedMarketID])

  // useEffect(() => {
  //   refetch();
  // }, [selectedMarketID])



  // const socialLoginInit = useCallback(async () => {                               //commenting for now because paymaster is not available for mainnet yet
  //   const { error, web3Instance } = await WebAuthInit();
  //   if (error) return;
  //   updateAptosWeb3Instance(web3Instance);
  //   if (web3Instance?.status === "connected") {
  //     const { error, wallet, address } = await handleInitWallet(web3Instance)
  //     if (error) return;
  //     updateSdk(wallet)
  //     updateAddress(address)
  //   }
  // }, []);

  // useEffect(() => {
  //   socialLoginInit();
  // }, [socialLoginInit]);



  useEffect(() => {
    if (address !== "") {
      updateIsWalletConnected(true)
    } else {
      updateIsWalletConnected(false)
    }
  }, [address])

  return (
    <AptosWalletProvider>
      <div className="relative w-full">
        <SidebarPage />
        </div>
      <ToastContainer position="bottom-right" theme={isDarkMode ? 'light' : 'dark'} icon={false} />
    </AptosWalletProvider>
  );
};

export default App;
