import React, { useEffect, useState } from 'react';
import { Button, List } from '../components';
import close from '../assets/icons/close.svg';
import CloseBlack from '../assets/icons/close_black.svg';
import { useTranslation } from 'react-i18next';
import down_arrow from '../assets/icons/select_downarrow.svg';
import ChevronDownDark from '../assets/icons/chevron-down-dark.svg';
import aptos from '../assets/icons/AptosLogo.svg';
import usdc from '../assets/icons/usdc.svg';
import { useAptosContext } from '../providers/AptosWalletContext';
import { useStore } from '../store';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import { toast } from 'react-toastify';
import AptosBlack from '../assets/images/AptosBlack.png';
import {
  getPercentageAmount,
  getUiAmount,
  getUiAmountReverse,
  handleSignWeb3AuthTransaction,
  onTokenAmountChange,
  preventPasteNegativeNumber,
} from '../utils/helper';
import { client, percentageOptions } from '../utils/Constants';
import ClickAwayListener from 'react-click-away-listener';
import NewMessageNotification from '../components/NewMessageNotification';
import _ from 'lodash';
import eth from '../assets/icons/eth.svg';

interface withDrawInterface {
  handleCloseModal(value: boolean): void;
  handleDepositModal(value: boolean): void;
}

const WithdrawModel = (props: withDrawInterface) => {
  const { handleCloseModal, handleDepositModal } = props;
  const { t } = useTranslation();
  const { signAndSubmitTransaction, connected, account }: any = useAptosContext();
  const { marketDataInstance, balanceUpdate, updateBalanceUpdate,dropdownValues: dropdDownValuesFromStore, tradePairInfo, updateIsLoading, isLoading: isLoadingFromStore, isWalletConnected, address: socialAddress, sdk, isDarkMode } = useStore();
  const [dropdownValues, updateDropdownValues] = useState<any>(dropdDownValuesFromStore);
  const [selectedCoin, setSelectedCoin] = useState<any>(dropdownValues[0]);
  const [withdrawAmount, setWithDrawAmount] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenWithdraw, setIsOpenWithdraw] = useState(false);
  const [aptBalance, setAptBalance] = useState<any>();
  const [accountBalance, setAccountBalance] = useState<any>();

  const handleGetAccountBalance = async (tradePairInfoResponse: any) => {
    const resources = await client.getAccountResources(connected ? account.address : socialAddress)
    const coinStoreType = '0x1::coin::CoinStore';
    const coinResources = resources.filter((r: any) => r.type.startsWith(coinStoreType));
    const newArray: any = []
    coinResources?.map(async (res: any) => {
      if (res.type === ("0x1::coin::CoinStore<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC>")) {
        res.cointype = tradePairInfo?.quoteCoinInfo?.symbol
        res.balance = getUiAmountReverse(res?.data?.coin?.value, tradePairInfoResponse?.quoteCoinInfo?.decimals)
        newArray.push(res)
      } else if (res.type === ("0x1::coin::CoinStore<0x1::aptos_coin::AptosCoin>")) {
        res.cointype = 'APT'
        res.decimals = 8
        res.balance = getUiAmountReverse(res?.data?.coin?.value, 8)
        res.balanceInAptDecimalFormat = res?.data?.coin?.value
        setAptBalance(res)
        if (tradePairInfo?.baseCoinInfo?.symbol === 'APT') {
          newArray.push(res)
        }
      }
      else if (res.type === ("0x1::coin::CoinStore<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WETH>")) {
        res.cointype = 'WETH'
        res.decimals = 6
        res.balance = getUiAmountReverse(res?.data?.coin?.value, res?.decimals)
        res.balanceInAptDecimalFormat = res?.data?.coin?.value
        if (tradePairInfo?.baseCoinInfo?.symbol === 'WETH') {
          newArray.push(res)
        }
      }
      setAccountBalance(newArray)
    })
    return newArray
  }

  const handleGetUserInfo = async () => {

    if (connected || isWalletConnected) {
      const tradePairInfoResponse = _.cloneDeep(tradePairInfo)
      const newArray = await handleGetAccountBalance(tradePairInfoResponse)
      if (newArray) {
        try {
          const marketInfo = await marketDataInstance.getUserMarketAccount(connected ? account.address : socialAddress);
          if (marketInfo?.length > 0) {
            const userInfoBaseBalance = getUiAmountReverse(
              marketInfo[0]?.base_available,
              tradePairInfoResponse?.baseCoinInfo?.decimals
            );
            const userInfoQuoteBalance = getUiAmountReverse(
              marketInfo[0]?.quote_available,
              tradePairInfoResponse?.quoteCoinInfo?.decimals
            );
            const updateBalanceInCoinInfo = _.cloneDeep(dropdownValues);

            updateBalanceInCoinInfo.map((coin: any) => {
              if (coin.item === tradePairInfo?.baseCoinInfo?.symbol) {
                coin.marketAccountBalance = userInfoBaseBalance;
                coin.marketAccountDecimals = tradePairInfoResponse?.baseCoinInfo?.decimals;
                const baseBalance = newArray?.filter((item: any) => item.cointype === tradePairInfo?.baseCoinInfo?.symbol)
                coin.balance = baseBalance.length > 0 ? baseBalance[0].balance : 0;
              } else {
                coin.marketAccountBalance = userInfoQuoteBalance;
                coin.marketAccountDecimals = tradePairInfoResponse?.quoteCoinInfo?.decimals;
                const quoteBalance = newArray?.filter((item: any) => item.cointype === tradePairInfo?.quoteCoinInfo?.symbol)
                coin.balance = quoteBalance.length > 0 ? quoteBalance[0].balance : 0;
              }
            });
            updateDropdownValues(updateBalanceInCoinInfo);
            if (selectedCoin.item === tradePairInfo?.baseCoinInfo?.symbol) {
              setSelectedCoin(updateBalanceInCoinInfo[0])
            } else {
              setSelectedCoin(updateBalanceInCoinInfo[1])
            }
          }
          return
        } catch (e) {

          updateBalanceUpdate(false)
        }
        if (newArray.length > 0) {
          const updateBalanceInCoinInfo = _.cloneDeep(dropdownValues);
          updateBalanceInCoinInfo.map((coin: any) => {
            if (coin.item === tradePairInfo?.baseCoinInfo?.symbol) {
              coin.marketAccountBalance = 0;
              coin.marketAccountDecimals = tradePairInfo?.baseCoinInfo?.decimals;
              const isPresent = newArray.filter((coin: any) => coin.cointype === tradePairInfo?.baseCoinInfo?.symbol)
              if (isPresent.length > 0) {
                coin.balance = isPresent[0].balance
              }
            } else if (coin.item === tradePairInfo?.quoteCoinInfo?.symbol) {
              coin.marketAccountBalance = 0;
              coin.marketAccountDecimals = tradePairInfo?.quoteCoinInfo?.decimals;
              const isPresent = newArray.filter((coin: any) => coin.cointype === tradePairInfo?.quoteCoinInfo?.symbol)
              if (isPresent.length > 0) {
                coin.balance = isPresent[0].balance
              }
            }
          });
          updateDropdownValues(updateBalanceInCoinInfo);
          if (selectedCoin.item === tradePairInfo?.baseCoinInfo?.symbol) {
            setSelectedCoin(updateBalanceInCoinInfo[0])
          } else {
            setSelectedCoin(updateBalanceInCoinInfo[1])
          }
          updateBalanceUpdate(false)
        } else {
          updateDropdownValues(dropdownValues);
          if (selectedCoin.item === tradePairInfo?.baseCoinInfo?.symbol) {
            setSelectedCoin(dropdownValues[0])
          } else {
            setSelectedCoin(dropdownValues[1])
          }
          updateBalanceUpdate(false)
        }

      }
    }

  };

  useEffect(() => {
    handleGetUserInfo();
  }, [marketDataInstance, connected, socialAddress, balanceUpdate]);

  const handleDeposit = () => {
    if (isLoadingFromStore) return
    handleDepositModal(true);
  };

  const handleWithdrawDropdown = () => {
    if (isLoadingFromStore) return
    setIsOpenWithdraw(!isOpenWithdraw);
  };

  const handleSelectedToken = (token: any) => {
    if (isLoadingFromStore) return
    setSelectedCoin(token);
    setWithDrawAmount('')
    setIsOpenWithdraw(!isOpenWithdraw);
  };

  const handleWithdrawBaseCoin = async (tradePairInfo: any) => {
    try {
      toast.dismiss()
      const uiAmount = getUiAmount(withdrawAmount, tradePairInfo.baseCoinInfo.decimals);
      const payload = marketDataInstance.withdrawBaseCoinPayload(uiAmount.toString());
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(payload, {
          gas_unit_price: "100",
          max_gas_amount: "6000"
        });
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
        if (error) {
          return toast(<NewMessageNotification link={transactionHash} type={"error"} message={status} />);
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        toast(<NewMessageNotification link={hash} type={"success"} message={`Transaction Executed Successfully`} />);
        handleGetUserInfo();
        updateBalanceUpdate(true)
      } else {
        toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`} />);

      }
    } catch (e: any) {
      if (e.name === 'Rejected') {
        return toast(<NewMessageNotification link={"none"} type={"error"} message={`Rejected by User`} />,);
      }
      const error = e as Error
      return toast(<NewMessageNotification link={"none"} type={"error"} message={error?.message} />);

    }
  };

  const handleWithdrawQuoteCoin = async (tradePairInfo: any) => {
    try {
      toast.dismiss()
      const uiAmount = getUiAmount(withdrawAmount, tradePairInfo.quoteCoinInfo.decimals);
      const payload = marketDataInstance.withdrawQuoteCoinPayload(uiAmount.toString());
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(payload, {
          gas_unit_price: "100",
          max_gas_amount: "6000"
        });
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
        if (error) {
          return toast(<NewMessageNotification link={transactionHash} type={"error"} message={status} />);
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        toast(<NewMessageNotification link={hash} type={"success"} message={`Transaction Executed Successfully`} />);
        handleGetUserInfo();
        updateBalanceUpdate(true)
      } else {
        toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`} />);
      }
    } catch (e: any) {
      if (e.name === 'Rejected') {
        return toast(<NewMessageNotification link={"none"} type={"error"} message={`Rejected by User`} />,);
      }
      const error = e as Error
      return toast(<NewMessageNotification link={"none"} type={"error"} message={error?.message} />);
    }
  };

  const handleWithdraw = async () => {
    if (isLoadingFromStore) return;
    toast.dismiss();
    setIsLoading(true);
    if (withdrawAmount === null || withdrawAmount === undefined || withdrawAmount === 0 || withdrawAmount === "" || withdrawAmount === '0.000') {
      setIsLoading(false);
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Enter Number Of Coins To Withdraw`} />);
      return;
    }
    if (withdrawAmount > selectedCoin.marketAccountBalance) {
      setIsLoading(false);
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Insufficient Coins To Withdraw`} />);
      setWithDrawAmount('');
      return;
    }
    if (connected && parseInt(aptBalance.balanceInAptDecimalFormat) < 600000) {
      setIsLoading(false);
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Insufficient APT Coins To Pay Transaction Fee `} />);
      setWithDrawAmount('');
      return;
    }
    if (selectedCoin && selectedCoin.item === tradePairInfo?.baseCoinInfo?.symbol) {
      await handleWithdrawBaseCoin(tradePairInfo);
      setWithDrawAmount('');
      setIsLoading(false);
    } else {
      await handleWithdrawQuoteCoin(tradePairInfo);
      setWithDrawAmount('');
      setIsLoading(false);
    }
  };

  const handleClickAway = () => {
    isOpenWithdraw && setIsOpenWithdraw(false);
  };

  const handleGetPercentageAmount = (percentage: any) => {
    if (isLoadingFromStore) return;
    if (selectedCoin.marketAccountBalance === 0) return;
    const amount = getPercentageAmount(percentage, selectedCoin.marketAccountBalance, selectedCoin.marketAccountDecimals, selectedCoin.item, 'Withdraw');
    if (percentage === 'Max' && amount === 'false') {
      return toast(<NewMessageNotification link={"none"} type={"error"} message={"Insufficient Balance To Make Max Transaction"} />);
    }
    setWithDrawAmount(amount);
  };

  const handleEnteredValueForWithdraw = (enteredValue: any) => {
    if (isLoadingFromStore) return
    if (enteredValue?.length > 8) {
      return
    }
    const validatedValue = onTokenAmountChange(enteredValue);
    setWithDrawAmount(validatedValue);
  };

  useEffect(() => {
    updateIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      <div onClick={() => { if (isLoadingFromStore) { return 0 } handleCloseModal(true) }} className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} !font-inter fixed inset-0 z-[24] xxl:pt-24 xl:pt-24 lg:pt-24 md:pt-24 sm:!pt-0 max-h-[full] flex flex-row justify-center items-center align-middle backdrop-blur-[1rem] overflow-y-auto bg-transparent sm:w-full md:w-full`}>
        <div onClick={(e) => {
          e.stopPropagation();
        }} className={`border-2 border-solid  ${isDarkMode ? 'xxl:border-[#E3E8EF] bxl:border-[#E3E8EF] xl:border-[#E3E8EF] sxl:border-[#E3E8EF] lg:border-[#E3E8EF] md:border-[#E3E8EF] ' : 'xxl:border-[#2e2e2e] bxl:border-[#2e2e2e] xl:border-[#2e2e2e] sxl:border-[#2e2e2e] lg:border-[#2e2e2e] md:border-[#2e2e2e]'} sm:!border-none xd:!border-none rounded-[2.25rem] bg-transparent`}>
          <div className={`  flex flex-col gap-4 items-center justify-start  md:px-5  sm:py-4 xd:py-4 rounded-[2.25rem] min-w-[20rem] sm:w-full !backdrop-blur-[2.5rem] ${isDarkMode ? 'bg-[rgba(252,253,254,0.70)]' : 'bg-transparent'}`}>
            <div className={`p-[20px_24px] flex rounded-[2.25rem_2.25rem_0rem_0rem] flex-row sm:flex-row  gap-6 items-start justify-between w-full ${isDarkMode ? 'bg-[#EFF7F8] ' : 'bg-[rgba(255,255,255,0.06)] '}`} >
              <div className={`text-2xl font-[700] md:text-[1.375rem] ${isDarkMode ? 'text-[#0C0C0D]' : 'text-white-A700'} sm:text-xl w-auto bg-transparent`}>Withdraw </div>
              <div className="flex flex-row gap-2 items-center justify-start w-auto bg-transparent">
                <Button
                  className={` ${isDarkMode ? 'border-[#0E9384] text-[#0E9384] ' : 'border-teal-A400_19 text-cyan-A200 '} border-[2px] bg-transparent ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} font-bold flex flex-row justify-center items-center leading-[normal]  px-[1rem] py-[0.5rem] rounded-lg text-center text-sm`}
                  onClick={handleDeposit}
                >
                  Deposit
                </Button>
                <img
                  className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}  h-5 w-5 `}
                  src={isDarkMode ? CloseBlack : close}
                  alt="close"
                  onClick={() => { if (isLoadingFromStore) { return 0 } handleCloseModal(true) }}
                />
              </div>
            </div>
            <List className="flex flex-col gap-4 items-center w-full px-8 py-4" orientation="vertical">
              <div className="flex sm:flex-1 flex-col items-start justify-start w-auto sm:w-full">
                <div className="flex flex-col items-start justify-start w-[31rem] sm:w-full">
                  <div className={` flex flex-col gap-[0.5rem] items-center justify-start sm:px-5 px-6 py-4 rounded-[1.5rem] w-full ${isDarkMode ? 'bg-[#FFF]' : 'bg-[#000000]'}`}>
                    <div className="flex sm:flex-col flex-row gap-4 h-[4.5rem] md:h-auto items-center justify-start w-full">
                      <div
                        className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-white-A700_19'} flex flex-1 flex-row h-full items-center justify-between sm:px-5 px-6 py-2 rounded-[1rem] w-full`}
                        onClick={handleWithdrawDropdown}
                      >
                        <div className="flex flex-row gap-2   py-2 items-center justify-start ">
                          <img
                            className="   h-7 w-7    "
                            src={selectedCoin?.icon}
                            alt="popup_token"
                          />
                          <div className={` ${isDarkMode ? 'text-[#000]' : 'text-white-A700'}   text-xl w-auto`}>{selectedCoin?.item}</div>
                        </div>
                        <img src={isDarkMode ? ChevronDownDark : down_arrow} alt="dropdown" className='bg-transparent' />
                      </div>
                      {isOpenWithdraw && (
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <div className={` ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-white-A700_19'} !backdrop-blur-2xl !fixed !z-[999] mt-52 xxl:!w-[28rem] bxl:!w-[28rem] xl:!w-[28rem] sxl:!w-[28rem] lg:!w-[96%]  md:!w-[95%]  sm:!w-[90%] xd:!w-[88%]  rounded-[1rem] `}>
                            {dropdownValues &&
                              dropdownValues.map((asset: any, key: number) => {
                                return (
                                  <div key={key}>
                                    <div
                                      onClick={() => handleSelectedToken(asset)}
                                      className={`h-[4rem] ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}!font-inter flex flex-row gap-6   items-center justify-start  px-6 py-1  !w-full hover:bg-[rgba(0,249,169,0.1)]  hover:rounded-[1rem]`}
                                    >
                                      <div className="flex flex-row gap-2 py-2 items-center justify-start w-auto">
                                        <img
                                          className=" h-7   w-7"
                                          src={asset?.icon}
                                          alt="popup_token"
                                        />
                                        <div className={` ${isDarkMode ? 'text-[#000]' : 'text-white-A700'}  text-[1.125rem] w-auto`}>{asset?.item}</div>

                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>
                    <div className="flex flex-col gap-2 h-full md:h-auto items-center justify-between w-full">
                      <div className={`flex flex-row gap-2 items-center justify-between w-full border-solid border-[1px] p-[0.7rem_1rem] ${isDarkMode ? 'border-[#E3E8EF]' : 'border-white-A700_33'} rounded-[0.5rem]`}>
                        <div className={`text-sm ${isDarkMode ? 'text-[#4A4B4D]' : 'text-white-A700_cc'} w-auto text-left font-bold`}>Wallet balance:</div>
                        <div className={`text-sm ${isDarkMode ? 'text-[#4A4B4D]' : 'text-white-A700_cc'} w-auto text-right `}>
                          {selectedCoin ? selectedCoin?.balance?.toFixed(9).replace(/\.?0*$/, '') : 0}
                        </div>
                      </div>
                      <div className={`flex flex-row gap-2 items-center justify-between w-full border-solid border-[1px] p-[0.7rem_1rem] ${isDarkMode ? 'border-[#E3E8EF]' : 'border-white-A700_33'} rounded-[0.5rem]`}>
                        <div className={`text-sm ${isDarkMode ? 'text-[#4A4B4D]' : 'text-white-A700_cc'} w-auto text-left font-bold`}>Trading balance:</div>
                        <div className={`text-sm ${isDarkMode ? 'text-[#4A4B4D]' : 'text-white-A700_cc'} w-auto text-right`}>
                          {selectedCoin ? selectedCoin?.marketAccountBalance?.toFixed(9).replace(/\.?0*$/, '') : 0}
                        </div>
                      </div>
                      {/* {connected &&   <div className="flex flex-row gap-2 items-center justify-between w-full border-solid border-[1px] p-[0.7rem_1rem] border-white-A700_33 rounded-[0.5rem]">
                        <div className='flex flex-row'>
                          <div className="text-sm text-white-A700_cc w-auto text-left font-bold">APT Balance : </div>
                          <div className="text-sm text-white-A700_cc">
                               {aptBalance ? parseFloat(aptBalance?.balance)?.toFixed(3) : 0 } APT
                          </div>
                        </div>
                        <div className='flex flex-row'><div className="text-sm text-white-A700_cc w-auto text-left font-bold">Gas Fee : </div>
                          <div className="text-sm text-white-A700_cc ">
                               {connected ? 0.08 : 0} APT
                          </div></div>
                      </div> } */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-1 flex-col items-start justify-start w-auto sm:w-full">
                <div className="flex flex-col items-start justify-start w-[31rem] sm:w-full">
                <div className={`${isDarkMode ? 'bg-[#FFF]' : 'bg-[#000000]'} flex flex-col gap-4 items-center justify-start sm:px-5 px-6 py-4 rounded-[24px] w-full`}>
                    <div className="flex sm:flex-col flex-row gap-4 h-[4.5rem] md:h-auto items-center justify-start w-full">
                      <input
                        className={`text-left font-[700] bg-transparent outline-none border-b border-solid ${isDarkMode ? 'border-[#E3E8EF] text-[black]' : 'border-white-A700_33 text-[white]'} flex-1 h-full sm:pl-0   py-4 md:text-3xl sm:text-[28px] text-[32px]    w-full sm:!text-center`}
                        placeholder="0"
                        min="0"
                        value={withdrawAmount}
                        disabled={isLoadingFromStore}
                        autoFocus={true}
                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                        onChange={(e) => handleEnteredValueForWithdraw(e.target.value)}
                        onPaste={preventPasteNegativeNumber}
                      />
                    </div>
                    <div className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex flex-row gap-2 h-[35px] md:h-auto items-center justify-between w-full`}>
                      <div className="flex flex-row gap-2 items-start justify-start w-auto">
                        {percentageOptions &&
                          percentageOptions.map((option: any, key: number) => {
                            return (
                              <Button
                                key={key}
                                onClick={() => handleGetPercentageAmount(option)}
                                className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}    ${isDarkMode ? 'bg-[#CFF9FE] text-[#088AB2] border-black-900_19 ' : 'bg-[#121926] text-[#088AB2] border-black-900_19'} border  border-solid  font-medium leading-[normal] min-w-[56px] py-2 rounded-lg text-center   text-[12px] font-manrope font-[400] tracking-[0.24px]`} >

                                {option}
                              </Button>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </List>
            <div className='w-full px-8 pb-8'>
              <Button
                onClick={() => handleWithdraw()}
                className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'
                  }  buy-button w-full `}
              >
                <div className={` ${isDarkMode ? 'buy-button-inner_blue' : 'buy-button-inner'} w-full py-[1rem] rounded-[1rem] flex flex-row justify-center items-center`}>

                  {isLoading ? (
                    // <ColorRing
                    //   visible={true}
                    //   height="25"
                    //   width="80"
                    //   ariaLabel="blocks-loading"
                    //   wrapperStyle={{}}
                    //   wrapperClass="blocks-wrapper"
                    //   colors={['#00000', '#00000', '#00F9A9', '#00000', '#00000']}
                    // />
                    <Lottie
                      loop
                      animationData={Kanaloader}
                      play
                      className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                    />
                  ) : <span className={`${isDarkMode ? 'button-text_dark' : 'button-text'} font-[700]`}>Transfer to Wallet</span>}
                </div>
              </Button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawModel;
