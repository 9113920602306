import React, { useState } from 'react'
import { useStore } from '../store';
import close from '../assets/icons/close.svg';
import CloseBlack from '../assets/icons/close_black.svg';
import down_arrow from '../assets/icons/select_downarrow.svg';
import ChevronDownDark from '../assets/icons/chevron-down-dark.svg';
import ClickAwayListener from 'react-click-away-listener';

interface SettingsInterface {
    handleClose(): void;
}
const Settings = (props: SettingsInterface) => {
    const { handleClose } = props
    const { isLoading: isLoadingFromStore, isDarkMode, updateIsDarkMode } = useStore();
    const [isTheme, setIsTheme] = useState(false);
    const [defaultTheme, setIsDefaultTheme] = useState(isDarkMode ? 'System theme (Light)' : 'System theme (Dark)');
    const [isCurrency, setIsCurrency] = useState(false);
    const [isExplorer, setIsExplorer] = useState(false);
    const [isLanguage, setIsLanguage] = useState(false);
    const handleTheme = () => {
        setIsTheme(!isTheme);
        setIsCurrency(false);
        setIsLanguage(false);
        setIsExplorer(false);
    }
    const handleLightTheme = () => {
        updateIsDarkMode(true);
        setIsDefaultTheme('Light Theme');
        setIsTheme(false);
        setIsCurrency(false);
        setIsLanguage(false);
        setIsExplorer(false);
    }
    const handleDarkTheme = () => {
        updateIsDarkMode(false);
        setIsDefaultTheme('Dark Theme');
        setIsTheme(false);
        setIsCurrency(false);
        setIsLanguage(false);
        setIsExplorer(false);
    }
    const handleCurrency = () => {
        setIsCurrency(!isCurrency);
        setIsTheme(false);
        setIsLanguage(false);
        setIsExplorer(false);
    }
    const handleLanguage = () => {
        setIsLanguage(!isLanguage);
        setIsTheme(false);
        setIsCurrency(false);
        setIsExplorer(false);
    }
    const handleExplorer = () => {
        setIsExplorer(!isExplorer);
        setIsTheme(false);
        setIsCurrency(false);
        setIsLanguage(false);
    }
    const [isSetting, setIsSetting] = useState(true);
    const handleClickAway = () => {
        setIsTheme(false);
    }
    return (
        <div className={` !font-inter fixed inset-0 z-[24] xxl:pt-24 xl:pt-24 lg:pt-24 md:pt-24 sm:!pt-0 max-h-[full] flex flex-row justify-center items-center  backdrop-blur-[1rem] overflow-y-auto bg-transparent sm:w-full md:w-full`}>
            <ClickAwayListener onClickAway={() => { handleClose() }} >
                <div  className={`border-2 border-solid xxl:w-[35%] bxl:w-[35%] xl:w-[35%] sxl:w-[35%] lg:w-[50%] md:w-[80%] sm:w-[85%] xd:w-[95%] ${isDarkMode ? 'xxl:border-[#E3E8EF] bxl:border-[#E3E8EF] xl:border-[#E3E8EF] sxl:border-[#E3E8EF] lg:border-[#E3E8EF] md:border-[#E3E8EF]' : 'xxl:border-[#2e2e2e] bxl:border-[#2e2e2e] xl:border-[#2e2e2e] sxl:border-[#2e2e2e] lg:border-[#2e2e2e] md:border-[#2e2e2e]'} sm:!border-none xd:!border-none rounded-[1rem] bg-transparent xxl:h-[14rem] bxl:h-[14rem] xl:h-[14rem] sxl:h-[14rem] lg:h-[14rem] md:xl:h-[14rem] sm:h-[14rem] max-sm:h-[14rem]`}>
                    <div className={`flex justify-between p-[3%_5%] rounded-t-[1rem]  ${isDarkMode ? ' !bg-[#EFF7F8] ' : ' !bg-[rgba(255,255,255,0.06)] '} `}>
                        <div className={` ${isDarkMode ? 'text-[#0C0C0D]' : 'text-[white]'} font-[700] text-[1.25rem]`}>
                            Settings
                        </div>
                        <img
                            onClick={() => { handleClose() }}
                            src={isDarkMode ? CloseBlack : close}
                            alt="close"
                            className="cursor-pointer"
                        />
                    </div>
                    <div className={`flex flex-col gap-4 items-center rounded-b-[1rem] h-[74%] !backdrop-blur-[2rem] w-full p-[1.5rem] ${isDarkMode ? ' bg-[rgba(252,253,254,0.70)] ' : ' bg-[rgba(23,24,26,0.50)] '}`}  >
                        <div className={`flex flex-col gap-[1rem] items-start w-full`}>
                            <div className={`flex flex-row justify-between items-center w-full cursor-pointer `}>
                                <div className={`${isDarkMode ? ' text-[#4A4B4D] ' : ' text-[rgba(255,255,255,0.80)] '} font-[400] text-[0.875rem] font-manrope`}>Theme</div>
                                <div className={` ${isDarkMode ? ' border-[#E3E8EF] bg-[#FCFDFE] text-[#4A4B4D] ' : ' border-[#2e2e2e] bg-[#17181A] text-[rgba(255,255,255,0.80)]'} flex flex-row justify-start items-center gap-[0.5rem] border-[1px]  text-[0.75rem] font-[500] py-[0.5rem] px-[0.75rem] !h-[2rem] rounded-[0.5rem] `} onClick={handleTheme}> {defaultTheme} <img src={isDarkMode ? ChevronDownDark : down_arrow} alt='' /></div>
                            </div>
                            <div className={`  fixed mt-12    flex flex-row justify-end  items-end w-[90%]`}>
                                {isTheme ?
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <div className={` gap-[1rem] w-[7rem] ${isDarkMode ? ' border-[#E3E8EF] bg-[#FCFDFE] text-[#4A4B4D] ' : ' border-[#2e2e2e] bg-[#17181A] text-[rgba(255,255,255,0.80)]'} flex flex-col justify-start items-start gap-[0.5rem] border-[1px]  text-[0.75rem] font-[500] py-[0.5rem] px-[0.75rem] rounded-[0.5rem]`}  >
                                            <div className={` !cursor-pointer ${isDarkMode ? '   text-[#4A4B4D] ' : '   text-[rgba(255,255,255,0.80)]'} `} onClick={handleLightTheme}>Light Theme</div>
                                            <div className={` !cursor-pointer ${isDarkMode ? '   text-[#4A4B4D] ' : '   text-[rgba(255,255,255,0.80)]'} `} onClick={handleDarkTheme} >Dark Theme</div>
                                        </div>
                                    </ClickAwayListener> : <></>
                                }
                            </div>
                            {/* <div className={`flex flex-row justify-between items-center w-full`}>
                            <div className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} font-[400] text-[0.875rem] font-manrope`}>Default Currency</div>
                            <div className={` ${isDarkMode ? ' border-[#E3E8EF] bg-[#FCFDFE] text-[#4A4B4D] ' : ' border-[#2e2e2e] bg-[#17181A] text-[rgba(255,255,255,0.80)]'} flex flex-row justify-start items-center gap-[0.5rem] border-[1px]  text-[0.75rem] font-[500] py-[0.5rem] px-[0.75rem] !h-[2rem] rounded-[0.5rem]`} onClick={handleCurrency} >USD <img src={isDarkMode ? ChevronDownDark : down_arrow} alt='' /></div>
                        </div>
                        {isCurrency ?
                            <div className={`    fixed mt-24    flex flex-row justify-end  items-center w-[92%]`}>
                                <div className={` gap-[1rem] w-[5rem] ${isDarkMode ? ' border-[#E3E8EF] bg-[#FCFDFE] text-[#4A4B4D] ' : ' border-[#2e2e2e] bg-[#17181A] text-[rgba(255,255,255,0.80)]'} flex flex-col justify-start items-start gap-[0.5rem] border-[1px]  text-[0.75rem] font-[500] py-[0.5rem] px-[0.75rem] rounded-[0.5rem]`}  >
                                    <div className={`${isDarkMode ? '   text-[#4A4B4D] ' : '   text-[rgba(255,255,255,0.80)]'} `} >USD</div>
                                </div>
                            </div> : <></>
                        }
                        <div className={`flex flex-row justify-between items-center mt-3 w-full`}>
                            <div className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} font-[400] text-[0.875rem] font-manrope`}>Language</div>
                            <div className={` ${isDarkMode ? ' border-[#E3E8EF] bg-[#FCFDFE] text-[#4A4B4D] ' : ' border-[#2e2e2e] bg-[#17181A] text-[rgba(255,255,255,0.80)]'} flex flex-row justify-start items-center gap-[0.5rem] border-[1px]  text-[0.75rem] font-[500] py-[0.5rem] px-[0.75rem] !h-[2rem] rounded-[0.5rem]`} onClick={handleLanguage}>English<img src={isDarkMode ? ChevronDownDark : down_arrow} alt='' /></div>
                        </div>
                        {isLanguage ?
                            <div className={`   fixed mt-40  flex flex-row justify-end  items-center w-[92%]`}>
                                <div className={` gap-[1rem] w-[6rem] ${isDarkMode ? ' border-[#E3E8EF] bg-[#FCFDFE] text-[#4A4B4D] ' : ' border-[#2e2e2e] bg-[#17181A] text-[rgba(255,255,255,0.80)]'} flex flex-col justify-start items-start gap-[0.5rem] border-[1px]  text-[0.75rem] font-[500] py-[0.5rem] px-[0.75rem] rounded-[0.5rem]`}  >
                                    <div className={`${isDarkMode ? '   text-[#4A4B4D] ' : '   text-[rgba(255,255,255,0.80)]'} `} >English</div>
                                </div>
                            </div> : <></>
                        }
                        <div className={`flex flex-row justify-between items-center w-full`}>
                            <div className={`${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} font-[400] text-[0.875rem] font-manrope`}>Preferred Explorer</div>
                            <div className={` ${isDarkMode ? ' border-[#E3E8EF] bg-[#FCFDFE] text-[#4A4B4D] ' : ' border-[#2e2e2e] bg-[#17181A] text-[rgba(255,255,255,0.80)]'} flex flex-row justify-start items-center gap-[0.5rem] border-[1px]  text-[0.75rem] font-[500] py-[0.5rem] px-[0.75rem] !h-[2rem] rounded-[0.5rem]`} onClick={handleExplorer}> Default <img src={isDarkMode ? ChevronDownDark : down_arrow} alt='' /></div>
                        </div>
                        {isExplorer ?
                            <div className={`  fixed mt-52   flex flex-row justify-end  items-center w-[92%]`}>
                                <div className={` gap-[1rem] w-[6rem] ${isDarkMode ? ' border-[#E3E8EF] bg-[#FCFDFE] text-[#4A4B4D] ' : ' border-[#2e2e2e] bg-[#17181A] text-[rgba(255,255,255,0.80)]'} flex flex-col justify-start items-start gap-[0.5rem] border-[1px]  text-[0.75rem] font-[500] py-[0.5rem] px-[0.75rem] rounded-[0.5rem]`}  >
                                    <div className={`${isDarkMode ? '   text-[#4A4B4D] ' : '   text-[rgba(255,255,255,0.80)]'} `} >Default</div>
                                </div>
                            </div> : <></>
                        }
                        <div className={`flex flex-row justify-between items-start w-full`}>
                            <div className={`${isDarkMode ? 'text-[#D92D20]' : 'text-[#D92D20]'} font-[400] text-[0.875rem] font-manrope`}>Reset settings</div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    )
}

export default Settings
