import React, { useEffect, useRef } from 'react';
import { useStore } from '../../store';

let tvScriptLoadingPromise;

export default function TradingViewWidget() {
  const onLoadScriptRef = useRef();
  const {tradePairInfo, isDarkMode} = useStore()

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_6dc3e') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: tradePairInfo && tradePairInfo?.baseCoinInfo?.symbol === 'APT' ?  "OKX:APTUSDC" : "BITSTAMP:ETHUSDC",
            interval: "D",
            timezone: "Etc/UTC",
            theme: isDarkMode ? "light" : "dark",
            style: "1",
            locale: "in",
            enable_publishing: false,
            backgroundColor: isDarkMode ? "white" : "rgba(13, 13, 13, 1)",
            container_id: "tradingview_6dc3e",
          });
        }
      }
    },
    [tradePairInfo, isDarkMode]
  );

  return (
    <div className='tradingview-widget-container' style={{ borderRadius: '1rem', overflow: 'hidden' }} >
      <div id='tradingview_6dc3e' className='h-[30rem] ' style={{ borderRadius: '1rem', overflow: 'hidden' }}/>
    </div>
  );
} 
