import React, { useState } from 'react'
import OrderBook_icon from '../assets/icons/mobile_file.svg';
import OrderBookDark_icon from '../assets/icons/mobile_file_dark.svg';
import OrderBookGreen from '../assets/icons/mobile_file_green.svg';
import OrderBookGreenDark from '../assets/icons/mobile_file_green_dark.svg';
import Graph from '../assets/icons/mobile_chart.svg';
import GraphDark from '../assets/icons/mobile_chart_dark.svg';
import GraphGreen from '../assets/icons/mobile_chart_green.svg';
import GraphGreenDark from '../assets/icons/mobile_chart_green_dark.svg';
import BuySell_icon from '../assets/icons/mobile_buysell.svg';
import BuySellGreen from '../assets/icons/mobile_buysell_green.svg';
import BuySellDark_icon from '../assets/icons/mobile_buysell_dark.svg';
import BuySellGreenDark from '../assets/icons/mobile_buysell_green_dark.svg';
import AllTrades_icon from '../assets/icons/mobile_globe.svg';
import AllTradesGreen from '../assets/icons/mobile_globe_green.svg';
import AllTradesDark_icon from '../assets/icons/mobile_globe_dark.svg';
import AllTradesGreenDark from '../assets/icons/mobile_globe_green_dark.svg';
import Histroy from '../assets/icons/mobile_history.svg';
import HistoryGreen from '../assets/icons/mobile_history_green.svg';
import HistroyDark from '../assets/icons/mobile_history_dark.svg';
import HistoryGreenDark from '../assets/icons/mobile_history_green_dark.svg';
import TradingViewWidget from '../components/TradingView';
import GlobalTrades from './GlobalTrades';
import BuySell from './BuySell';
import OrderBook from './OrderBook';
import AllTrades from './AllTrades';
import { useStore } from '../store';
const Footer = () => {
    // const [isActiveMenu, setIsActiveMenu] = useState(false);
    const [openDeposit, setOpenDeposit] = useState<boolean>(false)
    const [isConnectWalletisClicked, setIsConnectWalletClicked] = useState<boolean>(false);
    const [isOrderBookActive, setIsOrderBookActive] = useState(false);
    const [selectedPair, setSelectedPair] = useState<any>({})
    const [isGraphActive, setIsGraphActive] = useState(false);
    const [isBuySellActive, setIsBuySellActive] = useState(true);
    const [isAllTradesActive, setIsAllTradesActive] = useState(false);
    const [isHistoryActive, setIsHistoryActive] = useState(false);

    const handleOrderBook = () => {
        setIsOrderBookActive(true);
        setIsGraphActive(false);
        setIsBuySellActive(false);
        setIsAllTradesActive(false);
        setIsHistoryActive(false);
    }

    const handleGraph = () => {
        setIsOrderBookActive(false);
        setIsGraphActive(true);
        setIsBuySellActive(false);
        setIsAllTradesActive(false);
        setIsHistoryActive(false);
    }

    const handleBuySell = () => {
        setIsOrderBookActive(false);
        setIsGraphActive(false);
        setIsBuySellActive(true);
        setIsAllTradesActive(false);
        setIsHistoryActive(false);
    }

    const handleAllTrades = () => {
        setIsOrderBookActive(false);
        setIsGraphActive(false);
        setIsBuySellActive(false);
        setIsAllTradesActive(true);
        setIsHistoryActive(false);
    }

    const handleHistory = () => {
        setIsOrderBookActive(false);
        setIsGraphActive(false);
        setIsBuySellActive(false);
        setIsAllTradesActive(false);
        setIsHistoryActive(true);
    }
    const handleWalletConnect = () => {
        setIsConnectWalletClicked(true);
    };

    const handleOpenDepositModal = () => {
        setOpenDeposit(true)
    }

    const handleSelectedPair = (pair: any) => {
        setSelectedPair(pair)
    }

    const handleSettingPairEmptyAgain = () => {
        setSelectedPair({})
    }
    const { isDarkMode } = useStore();
    return (
        <div className={` ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'}  xxl:h-auto bxl:h-auto xl:h-auto sxl:h-auto lg:h-auto md:h-screen sm:h-screen xd:h-screen w-screen `}>
            {isOrderBookActive ?
                <ul className={` ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'}  flex-1 flex flex-col  justify-start items-start py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex md:pb-0 md:mt-[5rem] md:mb-[6rem] sm:mt-[5rem] sm:mb-[5.3rem] xd:mt-[5rem] mb-[7rem] z-[9] `}>
                    <OrderBook handleSelectedPair={handleSelectedPair} />
                </ul> : <></>}
            {isGraphActive ?
                <ul className={` ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'}  flex-1 flex flex-col  justify-start items-start py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex md:pb-0  md:mt-[5rem] md:mb-[6rem] sm:mt-[5rem] sm:mb-[5.3rem] xd:mt-[5rem] mb-[7rem] z-[9] `}>
                    <TradingViewWidget />
                </ul> : <></>}
            {isBuySellActive ?
                <ul className={` ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'}  flex-1 flex flex-col  justify-start items-start py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex md:pb-0  md:mt-[5rem] md:mb-[6rem] sm:mt-[5rem] sm:mb-[5.3rem] xd:mt-[5rem] mb-[7rem] z-[9] w-full `}>
                    <BuySell handleWalletConnect={handleWalletConnect}
                        handleOpenDepositModal={handleOpenDepositModal}
                        selectedPairFromOrderBook={selectedPair} handleSettingPairEmptyAgain={handleSettingPairEmptyAgain} />
                </ul> : <></>}
            {isAllTradesActive ?
                <ul className={` ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'}   flex-1 flex flex-col  justify-start items-start py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex md:pb-0  md:mt-[5rem] md:mb-[6rem] sm:mt-[5rem] sm:mb-[5.3rem] xd:mt-[5rem] mb-[7rem] z-[9] `}>
                    <GlobalTrades />
                </ul> : <></>}
            {isHistoryActive ?
                <ul className={` ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'}   flex-1 flex flex-col  justify-start items-start py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex md:pb-0  md:mt-[5rem] md:mb-[6rem] sm:mt-[5rem] sm:mb-[5.3rem] xd:mt-[5rem] mb-[7rem] z-[9] `}>
                    <AllTrades />
                </ul> : <></>}
            <div className={` ${isDarkMode ? '!bg-[#FCFCFD]' : '!bg-[#17181A]'} !h-24 z-[1] mt-40  !fixed !bottom-0   flex flex-row  items-start justify-evenly  w-full   px-[0.5rem] w-full   !font-inter w-full  ml-0 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex`}>
                <div className={`${isOrderBookActive ? `${isDarkMode ? ' border-t-[#0E9384] border-t-[1px]' : ' border-t-[#2ED3B7] border-t-[1px]'}` : ''} w-[20%] py-6 flex flex-col justify-center items-center gap-[0.5rem]`} onClick={handleOrderBook}>
                    {isOrderBookActive ? <img src={isDarkMode ? OrderBookGreenDark : OrderBookGreen} alt='' /> : <img src={isDarkMode ? OrderBookDark_icon : OrderBook_icon} alt='' />}
                    <div className={`${isOrderBookActive ? `${isDarkMode ? 'text-[#0E9384]' : 'text-[#2ED3B7]'}` : `${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`}  font-manrope text-[10px] font-[400]`}>Order Book</div>
                </div>
                <div className={`${isGraphActive ? `${isDarkMode ? ' border-t-[#0E9384] border-t-[1px]' : ' border-t-[#2ED3B7] border-t-[1px]'}` : ''} w-[20%] py-6 flex flex-col justify-center items-center gap-[0.5rem]`} onClick={handleGraph}>
                    {isGraphActive ? <img src={isDarkMode ? GraphGreenDark : GraphGreen} alt='' /> : <img src={isDarkMode ? GraphDark : Graph} alt='' />}
                    <div className={`${isGraphActive ? `${isDarkMode ? 'text-[#0E9384]' : 'text-[#2ED3B7]'}` : `${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`} font-manrope text-[10px] font-[400]`}>Graph</div>
                </div>
                <div className={`${isBuySellActive ? `${isDarkMode ? ' border-t-[#0E9384] border-t-[1px]' : ' border-t-[#2ED3B7] border-t-[1px]'}` : ''} w-[20%] py-6 flex flex-col justify-center items-center gap-[0.5rem]`} onClick={handleBuySell}>
                    {isBuySellActive ? <img src={isDarkMode ? BuySellGreenDark : BuySellGreen} alt='' /> : <img src={isDarkMode ? BuySellDark_icon : BuySell_icon} alt='' />}
                    <div className={`${isBuySellActive ? `${isDarkMode ? 'text-[#0E9384]' : 'text-[#2ED3B7]'}` : `${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`} font-manrope text-[10px] font-[400]`}>Buy/Sell</div>
                </div>
                <div className={`${isAllTradesActive ? `${isDarkMode ? ' border-t-[#9384] border-t-[1px]' : ' border-t-[#2ED3B7] border-t-[1px]'}` : ''} w-[20%] py-6 flex flex-col justify-center items-center gap-[0.5rem]`} onClick={handleAllTrades}>
                    {isAllTradesActive ? <img src={isDarkMode ? AllTradesGreenDark : AllTradesGreen} alt='' /> : <img src={isDarkMode ? AllTradesDark_icon : AllTrades_icon} alt='' />}
                    <div className={`${isAllTradesActive ? `${isDarkMode ? 'text-[#0E9384]' : 'text-[#2ED3B7]'}` : `${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`} font-manrope text-[10px] font-[400]`}>Recent Trades</div>
                </div>
                <div className={`${isHistoryActive ? `${isDarkMode ? ' border-t-[#0E9384] border-t-[1px]' : ' border-t-[#2ED3B7] border-t-[1px]'}` : ''} w-[20%] py-6 flex flex-col justify-center items-center gap-[0.5rem]`} onClick={handleHistory}>
                    {isHistoryActive ? <img src={isDarkMode ? HistoryGreenDark : HistoryGreen} alt='' /> : <img src={isDarkMode ? HistroyDark : Histroy} alt='' />}
                    <div className={`${isHistoryActive ? `${isDarkMode ? 'text-[#0E9384]' : 'text-[#2ED3B7]'}` : `${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`} font-manrope text-[10px] font-[400]`}>Your Trades</div>
                </div>
            </div>
        </div>
    )
}

export default Footer
