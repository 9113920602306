import { Button, List } from '../components';
import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { TradeTable, toDecimalPrice, toDecimalSize } from '@kanalabs/trade';
import { isObjectNonEmpty } from '../utils/helper';
import BigNumber from 'bignumber.js';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import _ from 'lodash';

const GlobalTrades = () => {
  const { t } = useTranslation();
  const { marketDataInstance, globalTrades: globalTradesStore, tradePairInfo, isDarkMode } = useStore();
  const [globalTrades, setGlobalTrades] = useState<TradeTable[]>();

  const handleGetGlobalTrades = async () => {
    if (isObjectNonEmpty(marketDataInstance)) {
      try {
        const pairInfo = _.cloneDeep(tradePairInfo)
        const uniqueSet = new Set();
        const result: TradeTable[] = [];
        globalTradesStore?.forEach((obj: any) => {
          const propertyValue = obj['txn_version'];
          if (!uniqueSet.has(propertyValue)) {
            uniqueSet.add(propertyValue);
            result.push(obj);
          }
        });
        const sortedTrades = [...result].sort((a, b) => b.txn_version - a.txn_version);
        sortedTrades.map((trade: any) => {
          const uiSize: any = toDecimalSize({
            size: new BigNumber(trade.size),
            lotSize: marketDataInstance.market.lotSize,
            baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
          })
          const uiPrice: any = toDecimalPrice({
            price: new BigNumber(trade.price),
            lotSize: marketDataInstance.market.lotSize,
            tickSize: marketDataInstance.market.tickSize,
            baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
            quoteCoinDecimals: new BigNumber(pairInfo.quoteCoinInfo.decimals),
          })
          trade.uiSize = uiSize;
          trade.uiPrice = uiPrice
        })
        setGlobalTrades(sortedTrades);
      } catch (e) {
        console.log();
      }
    }
  };

  useEffect(() => {
    handleGetGlobalTrades();
  }, [globalTradesStore])

  return (
    <>
      <div className={` ${isDarkMode ? 'xxl:border-[1px]  xxl:border-[#E3E8EF]  bxl:border-[1px]  bxl:border-[#E3E8EF]  xl:border-[1px]  xl:border-[#E3E8EF]  sxl:border-[1px]  sxl:border-[#E3E8EF]  lg:border-none  md:border-none sm:border-none xd:border-none xxl:bg-[rgba(252,253,254,0.70)] bxl:bg-[rgba(252,253,254,0.70)] xl:bg-[rgba(252,253,254,0.70)] sxl:bg-[rgba(252,253,254,0.70)] lg:bg-[rgba(252,253,254,0.70)] md:bg-[#E4F2F3] sm:bg-[#E4F2F3] xd:bg-[#E4F2F3]' : 'xxl:border-[1px]  xxl:border-[rgba(255,255,255,0.10)]  bxl:border-[1px]  bxl:border-[rgba(255,255,255,0.10)]  xl:border-[1px]  xl:border-[rgba(255,255,255,0.10)]  sxl:border-[1px]  sxl:border-[rgba(255,255,255,0.10)]  lg:border-none  md:border-none sm:border-none xd:border-none xxl:bg-[rgba(23,24,26,0.50)] bxl:bg-[rgba(23,24,26,0.50)] xl:bg-[rgba(23,24,26,0.50)] sxl:bg-[rgba(23,24,26,0.50)] lg:bg-[rgba(23,24,26,0.50)] md:bg-[#0C0C0D] sm:bg-[#0C0C0D] xd:bg-[#0C0C0D]'}  xxl:!rounded-[1rem] bxl:!rounded-[1rem] xl:!rounded-[1rem] sxl:!rounded-[1rem] lg:!rounded-[1rem] md:!rounded-[0rem] sm:!rounded-[0rem] xd:!rounded-[0rem] flex  flex-row gap-4 items-center justify-between   !w-full md:flex-col sm:flex-col xd:flex-col`}>
        <div className={` ${isDarkMode ? 'xxl:bg-[rgba(252,253,254,0.70)] bxl:bg-[rgba(252,253,254,0.70)] xl:bg-[rgba(252,253,254,0.70)] sxl:bg-[rgba(252,253,254,0.70)] lg:bg-[rgba(252,253,254,0.70)] md:bg-transparent sm:bg-transparent xd:bg-transparent ' : 'xxl:bg-[rgba(23,24,26,0.50)] bxl:bg-[rgba(23,24,26,0.50)] xl:bg-[rgba(23,24,26,0.50)] sxl:bg-[rgba(23,24,26,0.50)] lg:bg-[rgba(23,24,26,0.50)] md:bg-transparent sm:bg-transparent xd:bg-transparent '} flex     flex-col items-start justify-center w-full rounded-[1rem] shadow-bs  `}>
          <div className="flex md:mb-2 sm:mb-2 xd:mb-2 flex-row items-center justify-between w-full md:flex-col sm:flex-col xd:flex-col">
            <div className={` ${isDarkMode ? '!border-b-[#E3E8EF] !border-t-transparent !border-r-transparent !border-l-transparent' : '!border-b-[rgba(255,255,255,0.10)] !border-t-transparent !border-r-transparent !border-l-transparent'} bg-transparent  border border-solid   flex flex-row justify-start items-center w-full xxl:justify-start bxl:justify-start xl:justify-start sxl:justify-start lg:justify-start md:justify-start sm:justify-start xd:justify-between`}>
              <div
                className={` ${isDarkMode ? 'text-[#4A4B4D]' : 'text-[#A5A5A6]'} text-[0.875rem] p-[0.75rem_1rem] bg-transparent  xxl:font-[700] bxl:font-[700] xl:font-[700] sxl:font-[700] lg:font-[700] md:font-[700] sm:font-[700] xd:font-[700] text-center !rounded-[1rem_0rem_0rem_0rem]   !justify-center !items-center md:!w-auto xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row  md:!flex-row  sm:!flex-row xd:!flex-row xd:w-auto sm:w-auto    `}
              >
                Recent Trades
              </div>
            </div>
          </div>
          <div className="w-full ">
            <List className="flex flex-col  items-start w-full   overflow-y-auto  " orientation="vertical">
              <div className={` ${isDarkMode ? 'xxl:!border-b-[#E3E8EF] bxl:!border-b-[#E3E8EF] xl:!border-b-[#E3E8EF] sxl:!border-b-[#E3E8EF] lg:!border-b-[#E3E8EF] md:!border-none sm:!border-none xd:!border-none border-r-transparent border-l-transparent border-t-transparent bg-[#EFF7F8]' : 'xxl:!border-b-[rgba(255,255,255,0.10)] bxl:!border-b-[rgba(255,255,255,0.10)] xl:!border-b-[rgba(255,255,255,0.10)] sxl:!border-b-[rgba(255,255,255,0.10)] lg:!border-b-[rgba(255,255,255,0.10)] md:!border-none sm:!border-none xd:!border-none border-r-transparent border-l-transparent border-t-transparent bg-transparent '} flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:hidden md:hidden sm:hidden xd:hidden  justify-between items-center w-full px-4 py-[0.25rem] border-b-[1px]  `}>
                <div className="!bg-transparent flex xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.6] lg:flex-[0.5] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row xd:flex-row  items-center justify-between w-full py-1">
                  <div className="!bg-transparent flex flex-[0.5] flex-col gap-1 items-start justify-center w-auto">
                    <div className={` ${isDarkMode ? ' text-[#777879]' : ' text-[#A5A5A6]'} !bg-transparent flex flex-row justify-start items-center gap-[0.5rem] xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem] w-full`}>Size <Button className={` ${isDarkMode ? ' text-[#777879] bg-[#fff] ' : ' text-[#A5A5A6] bg-[rgba(255,255,255,0.06)] '}  cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center `}>  {tradePairInfo?.baseCoinInfo?.symbol} </Button></div>
                  </div>
                  <div className="!bg-transparent flex   flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start justify-center w-full">
                    <div className={` ${isDarkMode ? ' text-[#777879]' : ' text-[#A5A5A6]'} !bg-transparent flex flex-row justify-start items-center gap-[0.5rem] xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  w-full text-left`}>Price  <Button className={` ${isDarkMode ? ' text-[#777879] bg-[#fff] ' : ' text-[#A5A5A6] bg-[rgba(255,255,255,0.06)] '}  cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center  `}>  {tradePairInfo?.quoteCoinInfo?.symbol} </Button>
                    </div>
                  </div>
                </div>
                <div className="!bg-transparent flex xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.5] md:!flex-[1]   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row   items-center justify-between w-full sm:pt-4 py-1">
                  <div className="!bg-transparent flex   xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.5] md:flex-[0.5]  sm:flex-[0.5] xd:flex-[0.5]  flex-col gap-1 xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-end md:items-start sm:items-start xd:items-start justify-center w-full">
                    <div className={` ${isDarkMode ? ' text-[#777879]' : ' text-[#A5A5A6]'} !bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]   w-full text-left`}>Time</div>
                  </div>
                  <div className="!bg-transparent flex  xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.6] lg:flex-[0.5] md:flex-[0.5]  sm:flex-[0.5] xd:flex-[0.5]  flex-col gap-1 xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-start md:items-end sm:items-end xd:items-end justify-end w-full">
                    <div className={` ${isDarkMode ? ' text-[#777879]' : ' text-[#A5A5A6]'} !bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]   w-auto text-left`}> Transaction ID</div>
                  </div>
                </div>
              </div>
              {globalTrades && globalTrades.length > 0 ? (
                globalTrades.map((trade: any, key: number) => {
                  const datetime = new Date(trade?.time);
                  const hours = datetime.getHours();
                  const minutes = datetime.getMinutes();
                  const seconds = datetime.getSeconds();
                  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                  return (
                    <div
                      key={key}
                      className={` ${isDarkMode ? ' xxl:bg-transparent bxl:bg-transparent  xl:bg-transparent sxl:bg-transparent lg:bg-transparent md:bg-[#fff] sm:bg-[#fff]  xd:bg-[#fff]' : ' xxl:bg-transparent bxl:bg-transparent  xl:bg-transparent sxl:bg-transparent lg:bg-[transparent] md:bg-[#17181A] sm:bg-[#17181A]  xd:bg-[#17181A]'} md:mx-2  sm:mx-2 xd:mx-2 md:mb-2 sm:mb-2 xd:mb-2    xxl:rounded-[0_0_1rem_1rem] bxl:rounded-[0_0_1rem_1rem] xl:rounded-[0_0_1rem_1rem] sxl:rounded-[0_0_1rem_1rem] lg:rounded-[0_0_1rem_1rem] md:rounded-[1rem] sm:rounded-[1rem] xd:rounded-[1rem] ${isDarkMode ? 'xxl:!border-t-[#E3E8EF] bxl:!border-t-[#E3E8EF] xl:!border-t-[#E3E8EF] sxl:!border-t-[#E3E8EF] lg:!border-t-[#E3E8EF]' : 'xxl:!border-t-[rgba(255,255,255,0.10)] bxl:!border-t-[rgba(255,255,255,0.10)]  xl:!border-t-[rgba(255,255,255,0.10)]  sxl:!border-t-[rgba(255,255,255,0.10)] lg:!border-t-[rgba(255,255,255,0.10)] '} md:!border-none sm:!border-none xd:!border-none border-r-transparent    border-l-transparent border-b-transparent flex flex-col   items-start justify-center  border-[1px]   my-0 p-4   xxl:w-full bxl:w-full xl:w-full sxl:w-full lg:w-full md:w-[98%]   sm:w-[95.5%] xd:w-[95.5%]`}
                    >
                      <div className="!bg-transparent flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-[1] md:!flex-col sm:flex-col xd:flex-col  justify-between items-center w-full md:gap-[1rem] sm:gap-[1rem] xd:gap-[1rem]">
                        {/* <div className="!bg-transparent flex xxl:flex-[0.2] bxl:flex-[0.2] xl:flex-[0.2] sxl:flex-[0.2] lg:flex-[0.2] md:!flex-[1]   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col gap-2 items-end justify-between w-full   py-1">
                          <div className="flex !bg-transparent flex-[0.5] flex-col gap-1  items-start  justify-start w-full">
                            <div className="!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] text-[#777879] w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope">{t('asset')}</div>
                            <div className="!bg-transparent flex flex-row gap-1 items-center justify-start w-auto  text-right">
                              <div className="!bg-transparent  xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] text-[rgba(255,255,255,0.80)] w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] text-[rgba(255,255,255,0.80)]">
                              {tradePairInfo?.baseCoinInfo?.symbol + "/" +tradePairInfo?.quoteCoinInfo?.symbol}
                              </div>
                              <Button className={` cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center  ${trade?.maker_side  ? 'bg-[#121926] text-[#088AB2]':'text-[rgba(0,255,255,0.40)] bg-[rgba(0,255,255,0.10)]' } `}>
                                {trade?.maker_side ? 'SELL' : 'BUY '}
                              </Button>
                            </div>
                          </div>
                        </div> */}
                        <div className="flex flex-row !bg-transparent xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.6] lg:flex-[0.5] md:flex-[1]  sm:flex-[1] xd:flex-[1] gap-1 xxl:items-center   bxl:items-center xl:items-center sxl:items-center lg:items-center md:items-center sm:items-center xd:items-center !justify-between w-full">
                          <div className="flex !bg-transparent flex-[0.5] flex-col gap-1 xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start justify-start w-full">
                            <div className={` ${isDarkMode ? ' text-[#777879]' : ' text-[#A5A5A6]'} !bg-transparent flex flex-row justify-start items-center gap-[0.5rem] xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem]   w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}>
                              Size
                              <Button className={` ${isDarkMode ? 'bg-[#EFF7F8]' : 'bg-[rgba(255,255,255,0.06)]'}  cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center text-[#A5A5A6]`}>   {tradePairInfo?.baseCoinInfo?.symbol} </Button>
                            </div>
                            <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] w-auto  font-manrope xxl:font-[700] bxl:font-[700] xl:font-[700]  sxl:font-[700]  lg:font-[700] md:font-[800] sm:font-[800] xd:font-[800]   w-full text-left flex flex-row justify-start items-start  ${trade?.maker_side ? ` ${isDarkMode ? 'text-[#088AB2]' : 'text-[#2CE]'}  ` : `  ${isDarkMode ? 'text-[#0E9384]' : 'text-[#2ED3B7]'}  `} `}>{parseFloat(trade?.uiSize?.toString())?.toFixed(9).replace(/\.?0*$/, '')}</div>
                          </div>
                          {/* <div className="flex !bg-transparent flex-[0.5] flex-col gap-1  items-start  justify-start w-full">
                            <div className="!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] text-[#777879] w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope">{t('asset')}</div>
                            <div className="!bg-transparent flex flex-row gap-1 items-center justify-start w-auto  text-right">
                              <div className="!bg-transparent  xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] text-[rgba(255,255,255,0.80)] w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] text-[rgba(255,255,255,0.80)]">
                              {tradePairInfo?.baseCoinInfo?.symbol + "/" +tradePairInfo?.quoteCoinInfo?.symbol}
                              </div>
                              <Button className="bg-[rgba(0,255,255,0.10)] cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center text-[rgba(0,255,255,0.40)]">
                                {trade?.maker_side ? 'SELL' : 'BUY '}
                              </Button>
                            </div>
                          </div> */}
                          <div className="flex !bg-transparent flex-[0.5] flex-col gap-1 xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-end sm:items-end xd:items-end justify-start w-full">
                            <div className={` ${isDarkMode ? ' text-[#777879]' : ' text-[#A5A5A6]'} !bg-transparent flex flex-row justify-start items-center gap-[0.5rem]  xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem]   w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}>
                              Price
                              <Button className={` ${isDarkMode ? 'bg-[#EFF7F8]' : 'bg-[rgba(255,255,255,0.06)]'}  cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center text-[#A5A5A6]`}>   {tradePairInfo?.quoteCoinInfo?.symbol} </Button>
                            </div>
                            <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem]   w-auto  font-manrope xxl:font-[700] bxl:font-[700] xl:font-[700]  sxl:font-[700]  lg:font-[700] md:font-[800] sm:font-[800] xd:font-[800]   ${trade?.maker_side ? ` ${isDarkMode ? 'text-[#088AB2]' : 'text-[#2CE]'}  ` : `  ${isDarkMode ? 'text-[#0E9384]' : 'text-[#2ED3B7]'}  `}  w-auto text-left`}>{parseFloat(trade?.uiPrice)?.toFixed(9).replace(/\.?0*$/, '')}</div>
                          </div>
                        </div>
                        <div className={` ${isDarkMode ? 'border-[#E3E8EF] border-[1px]' : 'border-[rgba(255,255,255,0.10)] border-[1px]'} w-full xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex `}> </div>
                        <div className='flex flex-row justify-between items-center xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.5] md:flex-[1]  sm:flex-[1] xd:flex-[1] w-full'>
                          <div className="flex !bg-transparent xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.5] md:flex-[0.5]  sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start justify-start w-full">
                            <div className={` ${isDarkMode ? ' text-[#777879]' : ' text-[#A5A5A6]'} xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem]    w-auto text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:inline sm:inline xd:inline`}>Time</div>
                            <div className={` ${isDarkMode ? 'text-[#4A4B4D]' : 'text-[rgba(255,255,255,0.80)]'} xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem]  w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] w-auto text-left`}>{formattedTime}</div>
                          </div>
                          <div className="!bg-transparent flex xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.6] lg:flex-[0.5] md:flex-[0.5]  sm:flex-[0.5] xd:flex-[0.5]  xxl:flex-col bxl:flex-col xl:flex-col sxl:flex-col lg:flex-col md:flex-row sm:flex-row xd:flex-row gap-1 xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-end md:items-end sm:items-end xd:items-end justify-end w-full">
                            <a
                              href={`https://explorer.aptoslabs.com/txn/${trade?.txn_version}?network=mainnet`}
                              target="blank"
                              className=" !bg-transparent flex    xxl:flex-col bxl:flex-col xl:flex-col sxl:flex-col lg:flex-col md:flex-col sm:flex-col xd:flex-col gap-1 xxl:!items-end bxl:!items-end xl:!items-end sxl:!items-end lg:!items-end md:!items-end sm:items-end xd:items-end justify-end w-full"
                            >
                              <div className={` ${isDarkMode ? ' text-[#777879]' : ' text-[#A5A5A6]'} xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem]   w-full text-right xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:inline sm:inline xd:inline`}>Transaction ID</div>
                              <div className="xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem]   w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] text-[#2ED3B7] w-full xxl:text-right bxl:text-right xl:text-right sxl:text-right lg:text-left md:text-right sm:text-right xd:text-right">{trade?.txn_version}</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>

                  <div className="!bg-transparent text-sm py-4 text-white-A700_e5 w-full flex flex-row justify-center items-center  text-center">

                    <Lottie
                      loop
                      animationData={Kanaloader}
                      play
                      className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                    />
                  </div>
                </>
              )}
            </List>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalTrades;
