import { Button, List } from '../components';
import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ThreeDots from '../assets/icons/three_dots.svg';
import { useTranslation } from 'react-i18next';

import ClickAwayListener from 'react-click-away-listener';
import { useStore } from '../store';
import { BUY, ENVIRONMENT, SELL, TradeTable, toDecimalPrice, toDecimalSize } from '@kanalabs/trade';
import { handleSignWeb3AuthTransaction, isObjectNonEmpty, onTokenAmountChange, preventPasteNegativeNumber } from '../utils/helper';
import close from '../assets/icons/close.svg';
import { useAptosContext } from '../providers/AptosWalletContext';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';
import { client, econia } from '../utils/Constants';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import NewMessageNotification from '../components/NewMessageNotification';
import useClock from '../hooks/useClock';
import ModifyTrade from './ModifyTrade';
import _ from 'lodash';


const AllTrades = () => {
  const { t } = useTranslation();
  const { marketDataInstance, needToUpdateGlobalTrades, sdk, registeredMarkets, address, tradePairInfo, isWalletConnected, updateNeedToUpdateGlobalTrades, updateIsLoading, isLoading: isLoadingFromStore, isDarkMode } = useStore();
  const { account, connected, signAndSubmitTransaction }: any = useAptosContext();
  const [isImageOne, setIsImageOne] = useState(false);
  const [isImageTwo, setIsImageTwo] = useState(false);
  const [globalTrades, setGlobalTrades] = useState<TradeTable[]>();
  const [yourOrders, setYourOrders] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancellingAllTrades, setCancellingAllTrades] = useState<boolean>(false)
  const [modifyOrderSizeValue, setModifyOrderSizeValue] = useState<any>('');
  const [modifiedTotalValue, setModifiedTotalValue] = useState<any>();
  const [selectedTradeForModify, setSelectedTradeForModify] = useState<any>();
  const [orderHistory, setOrderHistory] = useState<any>([]);
  const [isYourOrder, setIsYourOrder] = useState(true);
  const [isHistory, setIsHistory] = useState(false);
  const tempOrders: any = []


  const handleTabClick = () => {
    setIsYourOrder(true);
    setIsHistory(false);
  };

  const handleTabClick2 = () => {
    setIsHistory(true);
    setIsYourOrder(false);
  };


  
  const handleGetUserhistory = async () => {
    if (connected || isWalletConnected) {
      if (isObjectNonEmpty(marketDataInstance)) {
        try {
          const pairInfo = _.cloneDeep(tradePairInfo)
          const validateAddress = connected ? account.address : address
          let modifiedString;
          if(validateAddress.length === 66 && validateAddress[2] === '0'){
            modifiedString  = validateAddress.slice(0, 2) + validateAddress.slice(3);
          }else{
            modifiedString = account.address
          }
          const orderHistoryAll = await marketDataInstance.getOrderHistory(connected ? modifiedString: address, 'all');
          if (isObjectNonEmpty(orderHistoryAll)){
            if (orderHistoryAll.length > 0) {
              const combinedArrayHistory: any[] = orderHistoryAll;
              combinedArrayHistory.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
              combinedArrayHistory.map((trade: any) => {
                const uiSize: any = toDecimalSize({
                  size: new BigNumber(trade?.order_type === 'limit' ? (trade?.remaining_size + trade?.total_filled) : trade?.total_filled),
                  lotSize: marketDataInstance.market.lotSize,
                  baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
                })
                const uiRemainingSize: any = toDecimalSize({
                  size: new BigNumber(trade?.remaining_size),
                  lotSize: marketDataInstance.market.lotSize,
                  baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
                })
                const uiTotalFilled: any = toDecimalSize({
                  size: new BigNumber(trade?.total_filled),
                  lotSize: marketDataInstance.market.lotSize,
                  baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
                })
                const uiPrice: any = toDecimalPrice({
                  price: new BigNumber(trade?.order_type === 'limit' ? trade?.price : 0),
                  lotSize: marketDataInstance.market.lotSize,
                  tickSize: marketDataInstance.market.tickSize,
                  baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
                  quoteCoinDecimals: new BigNumber(pairInfo.quoteCoinInfo.decimals),
                })
                const uiAvgExecutedPrice: any = toDecimalPrice({
                  price: new BigNumber(trade?.order_type === 'limit' ? trade?.average_execution_price === null ? trade?.price : trade?.average_execution_price : trade?.average_execution_price),
                  lotSize: marketDataInstance.market.lotSize,
                  tickSize: marketDataInstance.market.tickSize,
                  baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
                  quoteCoinDecimals: new BigNumber(pairInfo.quoteCoinInfo.decimals),
                })
                trade.uiSize = uiSize;
                trade.uiPrice = uiPrice;
                trade.uiRemainingSize = uiRemainingSize;
                trade.completed = uiTotalFilled;
                trade.avgExecutedPrice = uiAvgExecutedPrice

              })
              setOrderHistory(combinedArrayHistory)
            }else{
              setOrderHistory([])
            }}
            else{
              setOrderHistory([])
            }
        } catch (e) {
          console.log("");
        }
      }
    } else {
      setOrderHistory([])
    }
  };

  const handleGetOpenOrdersForMarkets = (openOrders: any, pairInfo: any, marketDataInstanceForMarket: any, marketID: any) => {
    if (isObjectNonEmpty(openOrders)) {
      const orders: any = [];
      openOrders.bids.length > 0 &&
        openOrders.bids.map((bid: any, key: number) => {
          const uiSize: any = toDecimalSize({
            size: new BigNumber(bid?.size),
            lotSize: marketDataInstanceForMarket?.market?.lotSize,
            baseCoinDecimals: new BigNumber(pairInfo?.baseCoinInfo?.decimals),
          });
          const uiPrice: any = toDecimalPrice({
            price: new BigNumber(bid?.price),
            lotSize: marketDataInstanceForMarket?.market?.lotSize,
            tickSize: marketDataInstanceForMarket?.market?.tickSize,
            baseCoinDecimals: new BigNumber(pairInfo?.baseCoinInfo?.decimals),
            quoteCoinDecimals: new BigNumber(pairInfo?.quoteCoinInfo?.decimals),
          });
          bid.counterString = bid.counter.toString();
          bid.priceString = uiPrice.toString();
          bid.sizeString = uiSize.toString();
          bid.orderType = 'BID';
          bid.selected = false;
          bid.modifyClicked = false;
          bid.saveModifyClicked = false;
          bid.openOperations = false;
          bid.baseCoinInfo = pairInfo?.baseCoinInfo;
          bid.quoteCoinInfo = pairInfo?.quoteCoinInfo;
          bid.marketID = marketID;
          bid.marketInstance = marketDataInstanceForMarket;
          orders.push(bid);
        });
      openOrders.asks.length > 0 &&
        openOrders.asks.map((ask: any, key: number) => {
          const uiSize: any = toDecimalSize({
            size: new BigNumber(ask.size),
            lotSize: marketDataInstanceForMarket.market.lotSize,
            baseCoinDecimals: new BigNumber(pairInfo?.baseCoinInfo?.decimals),
          });
          const uiPrice: any = toDecimalPrice({
            price: new BigNumber(ask.price),
            lotSize: marketDataInstanceForMarket.market.lotSize,
            tickSize: marketDataInstanceForMarket.market.tickSize,
            baseCoinDecimals: new BigNumber(pairInfo?.baseCoinInfo?.decimals),
            quoteCoinDecimals: new BigNumber(pairInfo?.quoteCoinInfo?.decimals),
          });
          ask.counterString = ask.counter.toString();
          ask.priceString = uiPrice.toString();
          ask.sizeString = uiSize.toString();
          ask.orderType = 'ASK';
          ask.selected = false;
          ask.modifyClicked = false;
          ask.saveModifyClicked = false;
          ask.openOperations = false;
          ask.baseCoinInfo = pairInfo?.baseCoinInfo;
          ask.quoteCoinInfo = pairInfo?.quoteCoinInfo;
          ask.marketID = marketID;
          ask.marketInstance = marketDataInstanceForMarket;
          orders.push(ask);
        });
      const sortedOrders = [...orders].sort((a, b) => parseFloat(b.counterString) - parseFloat(a.counterString));
      return sortedOrders
    }
  }

  const handleGetUserTrades = async () => {
    if (isObjectNonEmpty(marketDataInstance) && connected) {
      try {
        const markets = [7, 8];
        await Promise.all(
          markets.map(async (item) => {
            const marketDataInstanceForMarket = await econia.markets(
              registeredMarkets.filter((market: any) => market.marketId === item)[0],
              ENVIRONMENT.MAINNET
            );
            try {
              await marketDataInstanceForMarket.getUserMarketAccount(connected ? account.address : address);
              const openOrders = await marketDataInstanceForMarket.getOpenOrders(connected ? account.address : address, 'open', item);
              const pairInfo = await marketDataInstanceForMarket.getTradePairInfo();
              const orders: any = handleGetOpenOrdersForMarkets(openOrders, pairInfo, marketDataInstanceForMarket, item);
              for (const element of orders) {
                tempOrders.push(element);
              }
            } catch (e) {
              console.log("")
            }
          })
        );
        setYourOrders(tempOrders)
        updateNeedToUpdateGlobalTrades(false);
      } catch (e) {
        return toast(<NewMessageNotification link={'none'} type={"error"} message={"Error Fetching Orders, Please Refresh"} />);

      }
    }
  };

  useEffect(() => {
    handleGetUserTrades();
    handleGetUserhistory();
  }, [isWalletConnected, connected, needToUpdateGlobalTrades, tradePairInfo]);

  useEffect(() => {
    if (connected === false && isWalletConnected === false) {
      setYourOrders([]);
    }
  }, [connected, isWalletConnected]);

  const handleCloseTrade = async (order: any) => {
    try {
      if (isLoadingFromStore) return
      const newArray = [...yourOrders]
      updateIsLoading(true)
      newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = true : item.selected = false)
      setYourOrders(newArray)
      const closeOrder = order.marketInstance.cancelOrder(order.orderType === 'ASK' ? SELL : BUY, order.marketOrderId.toBigInt().toString());
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(closeOrder, {
          gas_unit_price: "100",
          max_gas_amount: "6000"
        });
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, closeOrder)
        if (error) {
          const newArray = [...yourOrders]
          updateIsLoading(false)
          newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = false : item.selected = false)
          setYourOrders(newArray)
          return toast(<NewMessageNotification link={transactionHash} type={"error"} message={status} />);
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        const newArray = [...yourOrders]
        updateIsLoading(false)
        newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = false : item.selected = false)
        setYourOrders(newArray)
        toast(<NewMessageNotification link={hash} type={"success"} message='Order Cancelled Successfully' />,);
        updateNeedToUpdateGlobalTrades(true)
        return;
      }
      const newArrays = [...yourOrders]
      updateIsLoading(false)
      newArrays.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = false : item.selected = false)
      setYourOrders(newArrays)
      toast(<NewMessageNotification link={hash} type={"error"} message='Please try again' />);
    } catch (e: any) {
      const newArray = [...yourOrders]
      updateIsLoading(false)
      newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = false : item.selected = false)
      setYourOrders(newArray)
      if (e.name === 'Rejected') {
        return toast(<NewMessageNotification link={"none"} type={"error"} message={`Rejected by User`} />,);
      }
      const error = e as Error
      return toast(<NewMessageNotification link={"none"} type={"error"} message={error?.message} />);
    }
  };

  const handleCloseAllOrders = async () => {
    toast.dismiss()
    if (isLoadingFromStore) return
    if (yourOrders?.length === 0) {
      return
      // return toast(<NewMessageNotification link={"none"} type={"error"} message={"No Open Trades"} />);
    }
    try {
      setCancellingAllTrades(true)
      const cancelAllOrders = marketDataInstance.cancelAllOrders()
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(cancelAllOrders, {
          gas_unit_price: "100",
          max_gas_amount: "6000"
        });
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, cancelAllOrders)
        if (error) {
          return toast(<NewMessageNotification link={transactionHash} type={"error"} message={status} />);
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        toast(<NewMessageNotification link={hash} type={"success"} message='Your Order Cancelled Successfully' />,);
        updateNeedToUpdateGlobalTrades(true)
        setCancellingAllTrades(false)
        return;
      }
    } catch (e: any) {
      setCancellingAllTrades(false)
      if (e.name === 'Rejected') {
        return toast(<NewMessageNotification link={"none"} type={"error"} message={`Rejected by User`} />,);
      }
      const error = e as Error
      return toast(<NewMessageNotification link={"none"} type={"error"} message={error?.message} />);
    }
  }

  const handleModifyTrade = (order: any) => {
    if (isLoadingFromStore) return
    const newArray = [...yourOrders]
    newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.modifyClicked = !item.modifyClicked : item.modifyClicked = false)
    setYourOrders(newArray)
    setModifyOrderSizeValue('')
    setModifiedTotalValue('')
  }

  useEffect(() => {
    if (modifyOrderSizeValue === '') {
      setModifiedTotalValue(0);
      return;
    }
    const value = parseFloat(modifyOrderSizeValue) * parseFloat(selectedTradeForModify?.priceString);
    setModifiedTotalValue(value)
  }, [modifyOrderSizeValue])

  useEffect(() => {
    updateIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    updateIsLoading(cancellingAllTrades)
  }, [cancellingAllTrades])

  const handleDropdown = (order: any) => {
    if (isLoadingFromStore) return
    const newArray = [...yourOrders]
    newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.openOperations = !item.openOperations : item.openOperations = false)
    setYourOrders(newArray)
    setModifyOrderSizeValue('')
    setModifiedTotalValue('')
  }

  const handleClickAway = () => {
    if (isLoadingFromStore) return
    const newArray = [...yourOrders]
    newArray.find((item) => item.openOperations = false)
    setYourOrders(newArray)
  }

  const handleOpenOrderID = async (orderID: any) => {
    const getOrderIDResponseMaker = await marketDataInstance.getTransactionsForOrderId(orderID, 'maker');
    if (getOrderIDResponseMaker.length > 0) {
      window.open(`https://explorer.aptoslabs.com/txn/${getOrderIDResponseMaker[0]?.txn_version}?network=mainnet`, '_blank');
      return
    }
    const getOrderIDResponseTaker = await marketDataInstance.getTransactionsForOrderId(orderID, 'taker');
    if (getOrderIDResponseTaker.length > 0) {
      window.open(`https://explorer.aptoslabs.com/txn/${getOrderIDResponseTaker[0]?.txn_version}?network=mainnet`, '_blank');
      return
    }
  }
  return (
    <>
      <div className={` ${isDarkMode ? 'xxl:border-[1px]  xxl:border-[#E3E8EF]  bxl:border-[1px]  bxl:border-[#E3E8EF]  xl:border-[1px]  xl:border-[#E3E8EF]  sxl:border-[1px]  sxl:border-[#E3E8EF]  lg:border-none  md:border-none sm:border-none xd:border-none  ' : 'xxl:border-[1px]  xxl:border-[rgba(255,255,255,0.10)]  bxl:border-[1px]  bxl:border-[rgba(255,255,255,0.10)]  xl:border-[1px]  xl:border-[rgba(255,255,255,0.10)]  sxl:border-[1px]  sxl:border-[rgba(255,255,255,0.10)]  lg:border-none  md:border-none sm:border-none xd:border-none  '} !rounded-[1rem] flex  flex-row gap-4 items-center justify-between   !w-full md:flex-col sm:flex-col xd:flex-col`}>
        <div className={` ${isDarkMode ? 'xxl:bg-[#EFF7F8] bxl:bg-[#EFF7F8] xl:bg-[#EFF7F8] sxl:bg-[#EFF7F8] lg:bg-[#EFF7F8] md:bg-transparent sm:bg-transparent xd:bg-transparent ' : 'xxl:bg-[rgba(23,24,26,0.50)] bxl:bg-[rgba(23,24,26,0.50)] xl:bg-[rgba(23,24,26,0.50)] sxl:bg-[rgba(23,24,26,0.50)] lg:bg-[rgba(23,24,26,0.50)] md:bg-transparent sm:bg-transparent xd:bg-transparent '} flex flex-col items-start justify-center w-full  shadow-bs !rounded-[1rem]`}>
          <div className={` w-full   ${isDarkMode ? 'border-b-[#E3E8EF] ' : 'border-b-[rgba(255,255,255,0.10)] '}  !border-t-transparent  !border-r-transparent !border-l-transparent border border-solid   flex flex-row justify-start items-center w-full  h-[3rem] font-manrope`}>
            <div
              className={` h-[2.9rem]  cursor-pointer  ${isYourOrder ? ` ${isDarkMode ? ' bg-[#FFF] text-[#0C0C0D]  ' : ' bg-[#1f1e1e] text-[#FFF]  '} !font-[700] ` : ` ${isDarkMode ? ' bg-[#EFF7F8] text-[#777879]  ' : ' bg-[#111213] text-[#A5A5A6]  '}`}  !font-[400]  text-center xxl:!rounded-[1rem_0rem_0rem_0rem] bxl:!rounded-[1rem_0rem_0rem_0rem]  xl:!rounded-[1rem_0rem_0rem_0rem] sxl:!rounded-[1rem_0rem_0rem_0rem] lg:!rounded-[1rem_0rem_0rem_0rem] md:!rounded-[0rem] sm:!rounded-[0rem] xd:!rounded-[0rem] !justify-center !items-center xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-row  sm:!flex-row xd:!flex-row xxl:w-auto bxl:w-auto xl:w-auto sxl:w-auto lg:w-auto md:w-[50%]  xd:w-[50%] sm:w-[50%] xxl:text-[0.875rem] bxl:text-[0.875rem] xl:text-[0.875rem] sxl:text-[0.875rem] lg:text-[0.875rem] md:text-[0.875rem] sm:text-[0.75rem] xd:text-[0.75rem] !p-[0.875rem_1.8rem]`}
              onClick={handleTabClick}
            >
              Open Orders
            </div>
            <div
              onClick={handleTabClick2}
              className={` h-[2.9rem]  cursor-pointer  ${isHistory ? ` ${isDarkMode ? ' bg-[#FCFDFE] text-[#0C0C0D]  ' : ' bg-[#1f1e1e] text-[#FFF]  '}  !font-[700]  ` : ` ${isDarkMode ? ' bg-[#EFF7F8] text-[#777879] ' : ' bg-[#111213] text-[#A5A5A6]  '}`} !font-[400]   text-center xxl:!rounded-[0rem_0rem_0rem_0rem] bxl:!rounded-[0rem_0rem_0rem_0rem]   xl:!rounded-[0rem_0rem_0rem_0rem] sxl:!rounded-[0rem_0rem_0rem_0rem] lg:!rounded-[0rem_0rem_0rem_0rem] md:!rounded-[0rem] sm:!rounded-[0rem] xd:!rounded-[0rem] !justify-center !items-center xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-row  sm:!flex-row xd:!flex-row xxl:w-auto bxl:w-auto xl:w-auto sxl:w-auto lg:w-auto md:w-[50%]  xd:w-[50%] sm:w-[50%] xxl:text-[0.875rem] bxl:text-[0.875rem] xl:text-[0.875rem] sxl:text-[0.875rem] lg:text-[0.875rem] md:text-[0.875rem] sm:text-[0.75rem] xd:text-[0.75rem] !p-[0.875rem_1.8rem]`}
            >
              History
            </div>
          </div>
          {isYourOrder ?
            <div className="w-full ">
              {!isImageOne && yourOrders?.length > 0 && <div className={`h-[3rem] border-[1px]  ${isDarkMode ? 'bg-[#EFF7F8] !border-b-[#E3E8EF] ' : 'bg-transparent !border-b-[rgba(255,255,255,0.10)] '} border-r-transparent border-l-transparent border-t-transparent   px-4 flex flex-row  justify-between items-center 
              `}
              // onClick={() => { handleCloseAllOrders() }}
              //${isLoadingFromStore || yourOrders?.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}
              >
                <div className={`text-[0.75rem] font-[400] ${isDarkMode ? 'text-[#777879] ' : 'text-[#A5A5A6] '} `}>Order Details</div>

                {/* <div className="flex flex-row w-auto items-center justify-center   rounded-[0.5rem]  p-[0.5rem]">
                  {cancellingAllTrades ? <Lottie
                    loop
                    animationData={Kanaloader}
                    play
                    className="  h-[1.5rem] flex justify-center items-center"
                  /> :
                    <div className={` ${isDarkMode ? 'text-[#D92D20] ' : 'text-[#F04438] '}  text-[0.8rem] font-inter  font-[700]`}>Cancel all orders</div>}
                </div> */}
              </div>}
              <List className="flex flex-col  items-start w-full   overflow-y-auto " orientation="vertical">
                <div className={`flex  border-r-transparent border-l-transparent border-t-transparent border border-solid  xxl:h-[2.2rem] bxl:h-[2.2rem] xl:h-[2.2rem] sxl:h-[2.2rem] lg:h-[2.2rem] md:h-[0.5rem] sm:h-[0.5rem] xd:h-[0.5rem] !bg-transparent  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-[1] md:!flex-col sm:flex-col xd:flex-col  justify-between items-center w-full xxl:py-[0.125rem] bxl:py-[0.125rem] xl:py-[0.125rem] sxl:py-[0.125rem] lg:py-[0.125rem] md:py-0 sm:py-0 xd:py-0 h-[1.625rem] ${isDarkMode ? ' xxl:!border-b-[#E3E8EF] bxl:!border-b-[#E3E8EF] xl:!border-b-[#E3E8EF] sxl:!border-b-[#E3E8EF] lg:!border-b-[#E3E8EF] md:border-none sm:border-none xd:border-none ' : 'xxl:!border-b-[rgba(255,255,255,0.10)] bxl:!border-b-[rgba(255,255,255,0.10)] xl:!border-b-[rgba(255,255,255,0.10)] sxl:!border-b-[rgba(255,255,255,0.10)] lg:!border-b-[rgba(255,255,255,0.10)] md:border-none sm:border-none xd:border-none'}`}>
                  <div className="flex !bg-transparent xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.25] lg:flex-[0.25] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row xd:flex-row gap-2 items-center justify-between w-full xxl:py-0.5 bxl:py-0.5 xl:py-0.5 sxl:py-0.5 lg:py-0.5 md:py-0 sm:py-0 xd:py-0">
                    <div className="flex !bg-transparent   flex-col   items-start justify-start w-full pl-5">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} !bg-transparent w-auto xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden `}>{t('asset')}</div>
                    </div>
                  </div>
                  <div className='xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.4] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full flex flex-row items-center justify-between  xxl:py-1 bxl:py-1 xl:py-1 sxl:py-1 lg:py-1 md:py-0 sm:py-0 xd:py-0'>
                    <div className="flex !bg-transparent flex-[0.5]  flex-col   xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start justify-start w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem] ${isDarkMode ? ' text-[#777879] ' : ' text-[#A5A5A6] '} !bg-transparent  w-auto xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden`}>{t('quantity')}</div>
                    </div>
                    <div className="flex !bg-transparent  flex-[0.5]  flex-col   xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start justify-end w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem] ${isDarkMode ? ' text-[#777879] ' : ' text-[#A5A5A6] '} !bg-transparent   w-auto xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden`}>{t('limit_price')}</div>
                    </div>
                  </div>
                  <div className="flex !bg-transparent xxl:flex-[0.2] bxl:flex-[0.2] xl:flex-[0.2] sxl:flex-[0.2] lg:flex-[0.2] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row   items-center justify-between w-full    xxl:py-0.5 bxl:py-0.5 xl:py-0.5 sxl:py-0.5 lg:py-0.5 md:py-0 sm:py-0 xd:py-0">
                    <div className="flex !bg-transparent flex-[0.93]  flex-col   xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start justify-center w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} text-center   w-auto xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden`}>Total</div>
                    </div>
                  </div>
                  <div className="flex !bg-transparent xxl:flex-[0.15] bxl:flex-[0.15] xl:flex-[0.15] sxl:flex-[0.15] lg:flex-[0.15] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row   items-center justify-between w-full   py-1">
                    <div className="flex !bg-transparent pr-3 flex-[0.93]  flex-col   xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-end md:items-end sm:items-end xd:items-end justify-center w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} text-right   w-auto xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden`}>Action</div>
                    </div>
                  </div>
                </div>
                {yourOrders && yourOrders?.length > 0 ? (
                  yourOrders?.map((trade: any, key: number) => {
                    return (
                      <>
                        <div
                          key={key}
                          className={`md:mx-2 border-[1px] border-r-transparent border-l-transparent border-b-transparent sm:mx-2 xd:mx-2 md:mb-2 sm:mb-2 xd:mb-2 ${isDarkMode ? ' xxl:bg-[rgba(252,253,254,0.70)] bxl:bg-[rgba(252,253,254,0.70)] xl:bg-[rgba(252,253,254,0.70)] sxl:bg-[rgba(252,253,254,0.70)] lg:bg-[rgba(252,253,254,0.70)] md:bg-[#FCFDFE] sm:bg-[#FCFDFE]  xd:bg-[#FCFDFE] xxl:!border-t-[#E3E8EF] bxl:!border-t-[#E3E8EF] xl:!border-t-[#E3E8EF] sxl:!border-t-[#E3E8EF] lg:!border-t-[#E3E8EF] ' : ' xxl:!border-t-[rgba(255,255,255,0.10)] bxl:!border-t-[rgba(255,255,255,0.10)] xl:!border-t-[rgba(255,255,255,0.10)] sxl:!border-t-[rgba(255,255,255,0.10)] lg:!border-t-[rgba(255,255,255,0.10)] xxl:bg-transparent bxl:bg-transparent xl:bg-transparent sxl:bg-transparent lg:bg-transparent md:bg-[#17181A] sm:bg-[#17181A]  xd:bg-[#17181A]'} xxl:rounded-[0_0_1rem_1rem] bxl:rounded-[0_0_1rem_1rem] xl:rounded-[0_0_1rem_1rem] sxl:rounded-[0_0_1rem_1rem] lg:rounded-[0_0_1rem_1rem] md:rounded-[1rem] sm:rounded-[1rem] xd:rounded-[1rem]      md:!border-none sm:!border-none xd:!border-none border-r-transparent border-l-transparent border-b-transparent  border-r-transparent border-l-transparent border-b-transparent    flex flex-col gap-4 items-start justify-center  my-0 p-4  xxl:w-full bxl:w-full xl:w-full sxl:w-full lg:w-full md:w-[98%]   sm:w-[95.5%] xd:w-[95.5%]`}
                        >
                          <div className="flex md:gap-[1rem] items-center sm:gap-[1rem] xd:gap-[1rem] !bg-transparent xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-[1] md:!flex-col sm:flex-col xd:flex-col    w-full">
                            <div className="flex !bg-transparent xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.25] lg:flex-[0.25] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row xd:flex-row   items-center justify-between w-full py-1">
                              <div className="flex !bg-transparent   flex-col   items-start justify-start w-auto">
                                <div className={` xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] w-auto xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex ${isDarkMode ? 'text-[#A5A5A6] ' : 'text-[#777879] '}`}>{t('asset')}</div>
                                <div className="flex flex-row gap-1 xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem] items-center !bg-transparent justify-start w-auto">
                                  <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? ' text-[#4A4B4D] ' : ' text-[rgba(255,255,255,0.80)] '} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] w-full`}>{trade?.baseCoinInfo?.symbol + "/" + trade?.quoteCoinInfo?.symbol}</div>
                                  <Button className={` cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center  ${trade?.orderType === 'ASK' ? `${isDarkMode ? 'text-[#088AB2] bg-[#CFF9FE]' : 'text-[#088AB2] bg-[#121926]'}  ` : `  ${isDarkMode ? 'text-[#107569] bg-[#E4F2F3]' : 'text-[rgba(0,255,255,0.40)] bg-[rgba(0,255,255,0.10)]'}`}  `}>
                                    {trade?.orderType}
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className='xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.4] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full flex flex-row items-center justify-between'>
                              <div className="flex !bg-transparent flex-[0.5]  flex-col  xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start justify-start w-full">
                                <div className={` ${isDarkMode ? 'text-[#A5A5A6] ' : 'text-[#777879] '}  xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] w-auto xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex`}>{t('quantity')}</div>
                                <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] w-auto !bg-transparent`}>{parseFloat(trade?.sizeString)?.toFixed(9).replace(/\.?0*$/, '')}</div>
                              </div>
                              <div className="flex !bg-transparent  flex-[0.5]  flex-col   xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-end sm:items-end xd:items-end justify-end w-full">
                                <div className={` ${isDarkMode ? 'text-[#A5A5A6] ' : 'text-[#777879] '} xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] !bg-transparent text-xs w-auto xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex`}>{t('limit_price')}</div>
                                <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] !bg-transparent w-auto`}>{parseFloat(trade?.priceString)?.toFixed(9).replace(/\.?0*$/, '')}</div>
                              </div>
                            </div>
                            <div className="flex !bg-transparent xxl:flex-[0.2] bxl:flex-[0.2] xl:flex-[0.2] sxl:flex-[0.2] lg:flex-[0.2] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row   items-center justify-between w-full   py-1">
                              <div className="flex !bg-transparent   flex-col  xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start justify-end w-full">
                                <div className={`xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex  ${isDarkMode ? 'text-[#A5A5A6] ' : 'text-[#777879] '}  xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem]`}>Total</div>
                                <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] w-full xxl:text-left bxl:text-left xl:text-left sxl:text-left lg:text-left md:text-left sm:text-left xd:text-left md:font-[800] sm:font-[800] xd:font-[800]`}>
                                  {(trade?.priceString * trade?.sizeString)?.toFixed(9).replace(/\.?0*$/, '')}
                                </div>
                              </div>
                            </div>
                            <div className="flex !bg-transparent xxl:flex-[0.15] bxl:flex-[0.15] xl:flex-[0.15] sxl:flex-[0.15] lg:flex-[0.15] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:hidden sm:hidden xd:hidden   items-center justify-between w-full   py-1">
                              <div className="flex !bg-transparent   flex-col  xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-end md:items-start sm:items-start xd:items-start justify-end w-full">
                                <div className='flex flex-row items-center xxl:gap-[1rem] bxl:gap-[1rem] xl:gap-[1rem] sxl:gap-[1rem] lg:gap-[1rem] md:gap-[0.5rem] sm:gap-[0.5rem] xd:gap-[0.5rem] justify-center'>
                                  <Button
                                    className={`font-[600]  flex flex-row justify-center items-center   py-[0.3rem] bg-transparent  xxl:w-auto bxl:w-auto xl:w-auto sxl:w-auto lg:w-auto   md:w-full sm:w-full xd:w-full font-bold h-full leading-[normal] md:border-[1px] sm:border-[1px] xd:border-[1px] md:border-[#2ED3B7] sm:border-[#2ED3B7]  xd:border-[#2ED3B7]   rounded-lg text-center ${isDarkMode ? 'text-[#0E9384]' : 'text-[#2ED3B7]'} text-[0.75rem] ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                    onClick={() => handleModifyTrade(trade)}
                                  >
                                    Modify
                                  </Button>
                                  <div className={`flex !bg-transparent rounded-lg flex-[0.5] flex-col gap-1 xxl:items-center bxl:items-center xl:items-center sxl:items-center lg:items-center md:items-center sm:items-center xd:items-center justify-center xxl:w-auto bxl:w-auto xl:w-auto sxl:w-auto lg:w-auto  md:w-full sm:w-full xd:w-full   md:border-[1px] sm:border-[1px] xd:border-[1px] md:border-[#F04438] sm:border-[#F04438]  xd:border-[#F04438]  ${isDarkMode ? 'text-[#D92D20]' : 'text-[#F04438]'} font-[600] text-[0.75rem] `}>
                                    {trade?.selected ? <Lottie
                                      loop
                                      animationData={Kanaloader}
                                      play
                                      className=" flex justify-center items-center"
                                    /> : <div
                                      onClick={() => handleCloseTrade(trade)}
                                      className={`flex  ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}    h-auto rounded-[0.5rem]   py-[0.3rem]  flex-row justify-center items-center !w-auto gap-[0.5rem]`}
                                    >Delete
                                    </div>}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*  
                          {/*  
                          <div className='flex-[0.1]  flex flex-col justify-end items-end cursor-pointer xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden w-full'>

                            <img src={ThreeDots} alt='' onClick={() => handleDropdown(trade)} />
                            <div className='flex flex-row justify-end items-end w-full absolute mr-[-5rem] mb-[-9rem]'>
                              {
                                trade.openOperations &&
                                <ClickAwayListener onClickAway={handleClickAway}  >
                                  <div className='w-auto text-[#FFFFFFCC] gap-[1.5rem] py-[0.75rem] px-[1rem] font-[400] text-[0.75rem] font-manrope flex flex-col justify-start items-start border-[1px] rounded-[0.5rem] bg-[#1D1E20] border-[rgba(255,255,255,0.10)]'>
                                    <div onClick={() => handleModifyTrade(trade)}>Modify</div>
                                    <div>Execute now</div>
                                    {trade?.selected ? <Lottie
                                      loop
                                      animationData={Kanaloader}
                                      play
                                      className="  h-[1.5rem] flex justify-center items-center"
                                    /> :
                                      <div onClick={() => handleCloseTrade(trade)} className='text-[#F04438]'>Cancel</div>}
                                  </div>
                                </ClickAwayListener>
                              }

                             </div>

                          </div> */}
                            <div className='w-full xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex border-[rgba(255,255,255,0.10)] border-[1px]'> </div>
                            <div className='xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex md:flex-[1] sm:flex-[1] xd:flex-[1] w-full flex flex-row gap-[0.5rem] md:my-1 sm:my-1 xd:my-1'>
                              <div className="flex !bg-transparent flex-[0.5] flex-col gap-1 xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-end md:items-center sm:items-center xd:items-center justify-center w-full">
                                <Button
                                  className={`font-[800]  flex flex-row justify-center items-center px-[1rem] py-[0.3rem] bg-transparent  xxl:w-auto bxl:w-auto xl:w-auto sxl:w-auto lg:w-auto   md:w-full sm:w-full xd:w-full font-bold h-full leading-[normal] border-[1px]    rounded-lg text-center ${isDarkMode ? 'text-[#0E9384] border-[#0E9384]' : 'text-[#2ED3B7] border-[#2ED3B7]'} text-[0.75rem] ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                  onClick={() => handleModifyTrade(trade)}
                                >
                                  Modify
                                </Button>
                              </div>
                              <div className={`flex !bg-transparent rounded-lg flex-[0.5] flex-col gap-1 xxl:items-center bxl:items-center xl:items-center sxl:items-center lg:items-center md:items-center sm:items-center xd:items-center justify-center xxl:w-auto bxl:w-auto xl:w-auto sxl:w-auto lg:w-auto  md:w-full sm:w-full xd:w-full border-[1px]    ${isDarkMode ? 'text-[#D92D20] border-[#D92D20]' : 'text-[#F04438] border-[#F04438]'} font-[800] text-[0.75rem]`}>
                                {trade?.selected ? <Lottie
                                  loop
                                  animationData={Kanaloader}
                                  play
                                  className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                                /> : <div
                                  onClick={() => handleCloseTrade(trade)}
                                  className={`flex  ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}    h-auto rounded-[0.5rem] px-[1rem] py-[0.3rem]  flex-row justify-center items-center !w-auto gap-[0.5rem]`}
                                >Delete
                                </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          trade.modifyClicked &&
                          <ModifyTrade trade={trade} handleCloseModifyTrade={handleModifyTrade} />
                        }
                      </>
                    );
                  })
                ) : (
                  <div className={` w-full md:m-2 sm:m-2 xd:m-2   xxl:rounded-[0_0_1rem_1rem] bxl:rounded-[0_0_1rem_1rem] xl:rounded-[0_0_1rem_1rem] sxl:rounded-[0_0_1rem_1rem] lg:rounded-[0_0_1rem_1rem] md:rounded-[1rem] sm:rounded-[1rem] xd:rounded-[1rem] border-t-[1px] xxl:!border-t-[rgba(255,255,255,0.10)] bxl:!border-t-[rgba(255,255,255,0.10)] xl:!border-t-[rgba(255,255,255,0.10)] sxl:!border-t-[rgba(255,255,255,0.10)] lg:!border-t-[rgba(255,255,255,0.10)] md:!border-t-transparent sm:!border-t-transparent xd:!border-t-transparent border-r-transparent border-l-transparent border-b-transparent  flex flex-col gap-4 items-start justify-center  my-0 p-4  xxl:w-full bxl:w-full xl:w-full sxl:w-full lg:w-full md:w-[98%]   sm:w-[95.5%] xd:w-[95.5%] ${isDarkMode ? ' xxl:bg-[rgba(252,253,254,0.70)] bxl:bg-[rgba(252,253,254,0.70)] xl:bg-[rgba(252,253,254,0.70)] sxl:bg-[rgba(252,253,254,0.70)] lg:bg-[rgba(252,253,254,0.70)] md:bg-transparent sm:bg-transparent xd:bg-transparent ' : 'bg-transparent'} `}>
                    <div className={` ${isDarkMode ? 'text-[#4A4B4D] ' : 'text-[#A5A5A6]  '} text-sm font-[800] !bg-transparent py-4  font-manrope w-full flex flex-row justify-center items-center  text-center`}>
                      {connected ? 'No Open Orders.' : 'Connect your wallet to see your open orders.'}
                    </div>
                  </div>
                )}

              </List>
            </div> : <></>}
          {isHistory ?
            <div className="w-full  ">
              <List className="flex flex-col   items-start w-full  overflow-y-auto  " orientation="vertical">
                <div className={`!bg-transparent xxl:h-[2.2rem] bxl:h-[2.2rem] xl:h-[2.2rem] sxl:h-[2.2rem] lg:h-[2.2rem] md:h-[0.5rem] sm:h-[0.5rem] xd:h-[0.5rem] ${isDarkMode ? 'xxl:!border-b-[#E3E8EF] bxl:!border-b-[#E3E8EF] xl:!border-b-[#E3E8EF] sxl:!border-b-[#E3E8EF] lg:!border-b-[#E3E8EF]' : 'xxl:!border-b-[rgba(255,255,255,0.10)] bxl:!border-b-[rgba(255,255,255,0.10)] xl:!border-b-[rgba(255,255,255,0.10)] sxl:!border-b-[rgba(255,255,255,0.10)] lg:!border-b-[rgba(255,255,255,0.10)] '} border-r-transparent border-l-transparent border-t-transparent border border-solid   px-4 xxl:pt-[0.01rem] bxl:pt-[0.01rem] xl:pt-[0.01rem] sxl:pt-[0.01rem] lg:pt-[0.01rem] xxl:pb-[0.25rem] bxl:pb-[0.25rem] xl:pb-[0.25rem] sxl:pb-[0.25rem] lg:pb-[0.25rem] flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-[1] md:hidden sm:hidden xd:hidden  justify-between items-center xxl:w-full bxl:w-full xl:w-full sxl:w-full lg:w-full md:w-[98%]   sm:w-[95.5%] xd:w-[95.5%]  `}>
                  <div className="!bg-transparent flex xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.25] lg:flex-[0.25] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:hidden sm:hidden xd:hidden  items-center justify-start w-full py-0.5">
                    <div className="!bg-transparent flex  xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.7] lg:flex-[0.5] md:flex-[0.5] sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}  w-auto  text-left`}> Asset</div>
                    </div>
                    <div className="!bg-transparent flex  xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.3] lg:flex-[0.5] md:flex-[0.5] sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}  w-auto  text-left`}> Type</div>
                    </div>
                  </div>
                  <div className="!bg-transparent flex xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.22] lg:flex-[0.22] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:hidden sm:hidden xd:hidden  items-center justify-start w-full py-0.5">
                    <div className="!bg-transparent flex  xxl:flex-[0.5] bxl:flex-[0.5] xl:flex-[0.5] sxl:flex-[0.6] lg:flex-[0.6] md:flex-[0.5] sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}  w-auto  text-left`}> Price</div>
                    </div>
                    <div className="!bg-transparent flex  xxl:flex-[0.5] bxl:flex-[0.5] xl:flex-[0.5] sxl:flex-[0.4] lg:flex-[0.4] md:flex-[0.5] sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}  w-auto  text-left`}> Status</div>
                    </div>
                  </div>
                  <div className="!bg-transparent flex xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.23] lg:flex-[0.23] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:hidden sm:hidden xd:hidden  items-center justify-start w-full py-0.5">
                    <div className="!bg-transparent flex  flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}  w-auto  text-left`}> Amount</div>
                    </div>
                    <div className="!bg-transparent flex  flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}  w-auto  text-left`}> Completed</div>
                    </div>
                  </div>
                  <div className="!bg-transparent flex xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.3] lg:flex-[0.3] md:!flex-[1]   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:hidden sm:hidden xd:hidden  items-center justify-start w-full py-0.5">
                    <div className="!bg-transparent flex  flex-[0.7] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}  w-auto  text-left`}> Date</div>
                    </div>
                    <div className="!bg-transparent flex  flex-[0.3] flex-col gap-1 xxl:!items-center bxl:!items-center xl:!items-center sxl:!items-center lg:!items-end md:!items-start sm:!items-start xd:!items-start  w-full">
                      <div className={`xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.625rem] sm:text-[0.625rem] xd:text-[0.625rem]  ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}  w-auto  xxl:!text-center bxl:!text-center xl:!text-center sxl:!text-center lg:!text-justify md:!text-justify sm:!text-justify xd:!text-justify `}>Order ID</div>
                    </div>
                  </div>
                </div>
                {orderHistory && orderHistory?.length > 0 ? (
                  orderHistory.map((trade: any, key: number) => {
                    const datetime = new Date(trade?.created_at);
                    return (
                      <div
                        key={key}
                        className={`md:mx-2 sm:mx-2 xd:mx-2 md:mt-2 sm:mt-2 xd:mt-2    xxl:rounded-[0_0_1rem_1rem] bxl:rounded-[0_0_1rem_1rem] xl:rounded-[0_0_1rem_1rem] sxl:rounded-[0_0_1rem_1rem] lg:rounded-[0_0_1rem_1rem] md:rounded-[1rem] sm:rounded-[1rem] xd:rounded-[1rem] md:!border-none sm:!border-none xd:!border-none border-r-transparent border-l-transparent border-b-transparent   border border-solid  flex flex-col gap-4 items-start justify-center  my-0 p-4  xxl:w-full bxl:w-full xl:w-full sxl:w-full lg:w-full md:w-[98%]   sm:w-[95.5%] xd:w-[95.5%] ${isDarkMode ? ' xxl:bg-[rgba(252,253,254,0.70)] bxl:bg-[rgba(252,253,254,0.70)]  xl:bg-[rgba(252,253,254,0.70)] sxl:bg-[rgba(252,253,254,0.70)] lg:bg-[rgba(252,253,254,0.70)] md:bg-[#FCFDFE] sm:bg-[#FCFDFE]  xd:bg-[#FCFDFE] xxl:!border-t-[#E3E8EF] bxl:!border-t-[#E3E8EF] xl:!border-t-[#E3E8EF] sxl:!border-t-[#E3E8EF] lg:!border-t-[#E3E8EF] ' : 'xxl:!border-t-[rgba(255,255,255,0.10)] bxl:!border-t-[rgba(255,255,255,0.10)] xl:!border-t-[rgba(255,255,255,0.10)] sxl:!border-t-[rgba(255,255,255,0.10)] lg:!border-t-[rgba(255,255,255,0.10)] xxl:bg-transparent bxl:bg-transparent  xl:bg-transparent sxl:bg-transparent lg:bg-transparent md:bg-[#17181A] sm:bg-[#17181A]  xd:bg-[#17181A]'}`}
                      >
                        <div className=" !bg-transparent flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row   md:flex-col sm:flex-col xd:flex-col  justify-between items-center w-full md:gap-[1rem] sm:gap-[1rem] xd:gap-[1rem] ">
                          <div className="!bg-transparent flex xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.25] lg:flex-[0.25] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row items-center justify-start w-full py-1">
                            <div className="!bg-transparent flex  xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.7] lg:flex-[0.5] md:flex-[0.5] sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 items-start justify-start w-auto">
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}>Asset</div>
                              <div className={`!bg-transparent flex flex-row gap-1 items-center justify-start w-auto  text-right xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800]  `}>
                                {tradePairInfo?.baseCoinInfo?.symbol + "/" + tradePairInfo?.quoteCoinInfo?.symbol}
                                {/* className={` cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center ${trade?.direction === 'bid' ? `${isDarkMode ? 'text-[#0E9384] bg-[#E4F2F3]' : 'text-[rgba(0,255,255,0.40)] bg-[rgba(0,255,255,0.10)]'}  ` : `${isDarkMode ? 'text-[#088AB2] bg-[#CFF9FE]' : 'text-[#088AB2] bg-[#121926]'}`}`}
                             */}
                                <Button className={` cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center ${trade?.order_type === 'limit' ? trade?.direction === 'bid' ? `${isDarkMode ? 'text-[#0E9384] bg-[#E4F2F3]' : 'text-[rgba(0,255,255,0.40)] bg-[rgba(0,255,255,0.10)]'}` : `${isDarkMode ? 'text-[#088AB2] bg-[#CFF9FE]' : 'text-[#088AB2] bg-[#121926]'}` : trade?.direction === 'buy' ? ` ${isDarkMode ? 'text-[#0E9384] bg-[#E4F2F3]' : 'text-[rgba(0,255,255,0.40)] bg-[rgba(0,255,255,0.10)]'} ` : `${isDarkMode ? 'text-[#088AB2] bg-[#CFF9FE]' : 'text-[#088AB2] bg-[#121926]'}`} `}>
                                  {trade?.order_type === 'limit' ? trade?.direction === 'bid' ? 'BUY' : 'SELL' : trade?.direction === 'buy' ? 'BUY' : 'SELL'}
                                </Button>
                              </div>
                            </div>
                            <div className="!bg-transparent flex  xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.3] lg:flex-[0.5] md:flex-[0.5] sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-end sm:!items-end xd:!items-end  justify-start w-full">
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}> Type</div>
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800]   w-auto  `}>{trade?.order_type === 'limit' ? 'Limit' : 'Market'}</div>
                            </div>
                          </div>
                          <div className="!bg-transparent flex xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.22] lg:flex-[0.22] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row items-center justify-start w-full py-1">
                            <div className="!bg-transparent flex  xxl:flex-[0.5] bxl:flex-[0.5] xl:flex-[0.5] sxl:flex-[0.6] lg:flex-[0.6] md:flex-[0.5] sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start justify-start w-full">
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}> Price</div>
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800]   w-auto  text-left`}>{parseFloat(trade?.avgExecutedPrice)?.toFixed(9).replace(/\.?0*$/, '')}</div>
                            </div>
                            <div className="!bg-transparent flex  xxl:flex-[0.5] bxl:flex-[0.5] xl:flex-[0.5] sxl:flex-[0.4] lg:flex-[0.4] md:flex-[0.5] sm:flex-[0.5] xd:flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-end sm:!items-end xd:!items-end   w-full">
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}>Status</div>
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800]   w-auto `}>
                                {trade?.order_status[0].toUpperCase() + trade?.order_status.slice(1)}</div>
                            </div>
                          </div>
                          <div className="!bg-transparent flex xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.23] lg:flex-[0.23] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row items-center justify-start w-full py-1">
                            <div className="!bg-transparent flex  flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}>Amount</div>
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800]   w-auto `}>{parseFloat(trade?.uiSize?.toString())?.toFixed(9).replace(/\.?0*$/, '')}</div>
                            </div>
                            <div className="!bg-transparent flex  flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-end sm:!items-end xd:!items-end   w-full">
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}>Completed</div>
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem]  w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto text-left`}>{trade?.completed?.toString()}</div>
                            </div>
                          </div>
                          <div className={` w-full xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex ${isDarkMode ? ' !border-[#E3E8EF] ' : ' !border-[rgba(255,255,255,0.10)] '} border-[1px] `}> </div>
                          <div className="!bg-transparent flex xxl:flex-[0.25] bxl:flex-[0.25] xl:flex-[0.25] sxl:flex-[0.3] lg:flex-[0.3] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row items-center justify-start w-full py-1">
                            <div className="!bg-transparent flex  flex-[0.7] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start  w-full">
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] ${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'} w-auto  text-left xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope`}> Date</div>
                              <div className={`!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem] ${isDarkMode ? 'text-[#4A4B4D]' : ' text-[rgba(255,255,255,0.80)]'} w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800]   w-auto text-left`}>{datetime?.toLocaleString()}</div>
                            </div>
                            <div onClick={() => handleOpenOrderID(trade?.order_id?.toString())} className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} !bg-transparent flex  flex-[0.3] flex-col gap-1 xxl:!items-center bxl:!items-center xl:!items-center sxl:!items-center lg:!items-end md:!items-end sm:!items-end xd:!items-end  w-full`}>
                              <div className="!bg-transparent xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.75rem] xd:text-[0.75rem] text-[#777879] w-auto xxl:!text-right bxl:!text-right xl:!text-right sxl:!text-right lg:!text-right md:!text-right sm:!text-right xd:!text-right xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex font-manrope">Order ID</div>
                              <div className="xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.875rem] sm:text-[0.875rem] xd:text-[0.875rem]   w-auto  font-manrope xxl:font-[400] bxl:font-[400] xl:font-[400]  sxl:font-[400]  lg:font-[400] md:font-[800] sm:font-[800] xd:font-[800] text-[#2ED3B7]  xxl:!text-justify bxl:!text-justify xl:!text-justify sxl:!text-justify lg:!text-right md:!text-right sm:!text-right xd:!text-right">{trade?.order_id?.toString()?.slice(0, 10)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className={` md:m-2 sm:m-2 xd:m-2  ${isDarkMode ? ' xxl:bg-[rgba(252,253,254,0.70)] bxl:bg-[rgba(252,253,254,0.70)] xl:bg-[rgba(252,253,254,0.70)] sxl:bg-[rgba(252,253,254,0.70)] lg:bg-[rgba(252,253,254,0.70)] md:bg-transparent sm:bg-transparent xd:bg-transparent ' : 'bg-transparent'} xxl:rounded-[0_0_1rem_1rem] bxl:rounded-[0_0_1rem_1rem] xl:rounded-[0_0_1rem_1rem] sxl:rounded-[0_0_1rem_1rem] lg:rounded-[0_0_1rem_1rem] md:rounded-[1rem] sm:rounded-[1rem] xd:rounded-[1rem] xxl:!border-t-[rgba(255,255,255,0.10)] bxl:!border-t-[rgba(255,255,255,0.10)] xl:!border-t-[rgba(255,255,255,0.10)] sxl:!border-t-[rgba(255,255,255,0.10)] lg:!border-t-[rgba(255,255,255,0.10)] md:!border-none sm:!border-none xd:!border-none border-r-transparent border-l-transparent border-b-transparent  border-r-transparent border-l-transparent border-b-transparent   flex flex-col gap-4 items-start justify-center  my-0 p-4  xxl:w-full bxl:w-full xl:w-full sxl:w-full lg:w-full md:w-[98%]   sm:w-[95.5%] xd:w-[95.5%] w-full`}>
                      <div className={` ${isDarkMode ? 'text-[#4A4B4D]' : 'text-[#A5A5A6]'} text-sm font-[800] !bg-transparent py-4   font-manrope w-full flex flex-row justify-center items-center  text-center`}>
                        {connected ? 'No Orders' : 'Connect your wallet to see your orders.'}
                      </div>
                    </div>
                  </>
                )}
              </List>
            </div> : <></>}
        </div>
      </div >
    </>
  );
};

export default AllTrades;