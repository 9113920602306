import { Button, List } from '../components';
import React, { useEffect, useRef, useState } from 'react';
import { useAptosContext } from '../providers/AptosWalletContext';
import { useStore } from '../store';
import useClock from '../hooks/useClock';
import { toast } from 'react-toastify';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import { getPercentageAmount, getUiAmount, getUiAmountReverse, handleConnectedWalletNetwork, handleSignWeb3AuthTransaction, isObjectNonEmpty, onTokenAmountChange, preventPasteNegativeNumber } from '../utils/helper';
import { useTranslation } from 'react-i18next';
import { client } from '../utils/Constants';
import { BUY, SELL } from '@kanalabs/trade';
import NewMessageNotification from '../components/NewMessageNotification';
import aptos from '../assets/icons/AptosLogo.svg';
import usdc from '../assets/icons/usdc.svg';
import _ from 'lodash';
interface BuySellInterface {
  handleWalletConnect(): void;
  handleOpenDepositModal(): void;
  selectedPairFromOrderBook: any;
  handleSettingPairEmptyAgain(): void;
}

const BuySell = (props: BuySellInterface) => {
  const { t } = useTranslation();
  const { handleWalletConnect, handleOpenDepositModal, selectedPairFromOrderBook, handleSettingPairEmptyAgain } = props;
  const { marketDataInstance, updateNeedToUpdateGlobalTrades, needToUpdateGlobalTrades, tradePairInfo, orderBook, updateBalanceUpdate, balanceUpdate, updateIsLoading, isLoading: isLoadingFromStore, isWalletConnected, address: socialAddress, sdk, isDarkMode } = useStore();
  const { connected, signAndSubmitTransaction, account, wallet, disconnect }: any = useAptosContext();
  const [enteredBaseValue, setEnteredBaseValue] = useState<any>();
  const [enteredQuoteValue, setEnteredQuoteValue] = useState<any>();
  const [totalValue, setTotalValue] = useState<any>('');
  const date = useClock();
  const [typeOfOrder, setTypeOfOrder] = useState<string>('market');
  const [typeOfRootOrder, setTypeOfRootOrder] = useState<string>('BUY');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userinfo, setUserInfo] = useState<any>();
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  // const [aptBalance, setAptBalance] = useState<any>();
  const [tradePrice, setTradePrice] = useState<any>();
  const marketInputRef: any = useRef(null);

  const handleGetUserInfo = async () => {
    setOpenDeposit(false)
    if (connected || isWalletConnected) {
      try {
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
        setTotalValue(0);
        if (isObjectNonEmpty(marketDataInstance)) {
          const marketInfo = await marketDataInstance.getUserMarketAccount(connected ? account.address : socialAddress);
          if (marketInfo?.length > 0) {
            const user = marketInfo[0]
            const tradePairInfoResponse = _.cloneDeep(tradePairInfo)
            const userInfoBaseBalance = getUiAmountReverse(
              user?.base_available,
              tradePairInfoResponse?.baseCoinInfo?.decimals
            );
            const userInfoQuoteBalance = getUiAmountReverse(
              user?.quote_available,
              tradePairInfoResponse?.quoteCoinInfo?.decimals
            );
            user.baseBalance = userInfoBaseBalance
            user.baseDecimals = tradePairInfoResponse?.baseCoinInfo?.decimals;
            user.quoteBalance = userInfoQuoteBalance
            user.quoteDecimals = tradePairInfoResponse?.quoteCoinInfo?.decimals;
            setUserInfo(user)
          } else {
            setUserInfo(undefined)
          }
          updateNeedToUpdateGlobalTrades(false);
          updateBalanceUpdate(false)
        }
      } catch (e) {
        updateNeedToUpdateGlobalTrades(false);
        updateBalanceUpdate(false)
        setUserInfo(undefined)
      }
    } else {
      updateNeedToUpdateGlobalTrades(false);
      updateBalanceUpdate(false)
      setUserInfo(undefined)
    }
  };

  useEffect(() => {
    handleGetUserInfo()
  }, [marketDataInstance, connected, balanceUpdate, isWalletConnected, needToUpdateGlobalTrades, tradePairInfo]);

  const handleValidationBeforePlacingOrder = async () => {
    toast.dismiss();
    if (connected) {
      const { error } = handleConnectedWalletNetwork(wallet)
      if (error) {
        toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Switch Network From  Testnet To Mainnet  in ${wallet.adapter.name} extension and try again!`} />);
        disconnect()
        return 0;
      }
    }
    if (enteredQuoteValue == 0 && typeOfOrder == 'limit') {
      toast(<NewMessageNotification link={"none"} type={"error"} message='Please Enter Limit Price' />,);
      return 0;
    }
    if (enteredBaseValue == 0) {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Enter Quantity you want to ${typeOfRootOrder}`} />);
      return 0;
    }
    try {
      const marketInfo = await marketDataInstance.getUserMarketAccount(connected ? account.address : socialAddress);
      if (typeOfRootOrder === 'BUY') {
        const uiAmount = getUiAmount(
          typeOfOrder === 'market' ? totalValue : enteredQuoteValue,
          tradePairInfo.quoteCoinInfo.decimals
        );
        const marketUIAmount = getUiAmount(marketInfo[0].quote_available, tradePairInfo.quoteCoinInfo.decimals)
        if (marketUIAmount >= uiAmount) {
          return 1;
        } else {
          toast(<NewMessageNotification link={"none"} type={"error"} message={`Market Account does not have enough fund! Please Deposit`} />);
          return 0;
        }
      } else {
        const uiAmount = getUiAmount(
          enteredBaseValue,
          tradePairInfo.baseCoinInfo.decimals
        );
        const marketUIAmount = getUiAmount(marketInfo[0].base_available, tradePairInfo.baseCoinInfo.decimals)
        if (marketUIAmount >= uiAmount) {
          return 1;
        } else {
          toast(<NewMessageNotification link={"none"} type={"error"} message={`Market Account does not have enough fund! Please Deposit`} />);
          return 0;
        }
      }
    } catch (e) {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Deposit Some Funds To Market Account`} />);
      return 0;
    }
  };

  const handleAPTBalance = async () => {
    try {
      const resources = await client.getAccountResources(connected ? account.address : socialAddress)
      const coinStoreType = '0x1::coin::CoinStore';
      const coinResources = resources.filter((r: any) => r.type.startsWith(coinStoreType));
      const aptBalanceFromResource: any = {
        coinType: "",
        decimals: 0,
        balance: "",
        balanceInAptDecimalFormat: ""
      }
      coinResources?.map(async (res: any) => {
        if (res.type === ("0x1::coin::CoinStore<0x1::aptos_coin::AptosCoin>")) {
          aptBalanceFromResource.cointype = 'APT'
          aptBalanceFromResource.decimals = 8
          aptBalanceFromResource.balance = getUiAmountReverse(res?.data?.coin?.value, 8)
          aptBalanceFromResource.balanceInAptDecimalFormat = res?.data?.coin?.value
          // setAptBalance(aptBalanceFromResource)
        }
      })
      if (connected && parseInt(aptBalanceFromResource.balanceInAptDecimalFormat) < 600000) {
        toast(<NewMessageNotification link={"none"} type={"error"} message={`Insufficient APT Coins To Pay Transaction Fee, current APT balance is ${aptBalanceFromResource.balance} `} />);
        return 0;
      }
      else return 1
    } catch (e) {
      const error = e as Error
      if (error.message.includes('Account not found')) {
        toast(<NewMessageNotification link={'none'} type={"error"} message={`Account not initialized yet! please add some APT`} />);
      } else {
        toast(<NewMessageNotification link={"none"} type={"error"} message={`Transaction Failed`} />);
      }
      return 0
    }
  }

  const handlePlaceMarketOrder = async () => {
    if (isLoadingFromStore) return;
    try {
      setIsLoading(true);
      updateIsLoading(true);
      const marketData = await handleValidationBeforePlacingOrder();
      if (marketData === 0) {
        setIsLoading(false);
        return;
      }
      const aptBalance = await handleAPTBalance()
      if (aptBalance === 0) {
        setIsLoading(false);
        return;
      }
      const typeOfTransaction = typeOfRootOrder === 'BUY' ? BUY : SELL;
      const estimatedPrice = await marketDataInstance.getEstimatedPrice(enteredBaseValue, typeOfTransaction);
      const payload: any = await marketDataInstance.placeMarketOrder(typeOfTransaction);
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(payload, {
          gas_unit_price: "100",
          max_gas_amount: "6000"
        });
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
        if (error) {
          setIsLoading(false);
          toast(<NewMessageNotification link={transactionHash} type={"error"} message={status} />);
          return
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        toast(<NewMessageNotification link={hash} type={"success"} message={`Transaction Executed successfully`} />,);
        setIsLoading(false);
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
        updateNeedToUpdateGlobalTrades(true);
        handleGetUserInfo()
        return;
      } else {
        setIsLoading(false);
        toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`} />,);
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
      }
    } catch (e: any) {
      setIsLoading(false);
      setEnteredQuoteValue('');
      setEnteredBaseValue('');
      setTotalValue(0);
      if (e.name === 'Rejected') {
        return toast(<NewMessageNotification link={"none"} type={"error"} message={`Rejected by User`} />,);
      }
      const error = e as Error
      return toast(<NewMessageNotification link={"none"} type={"error"} message={error?.message} />);
    }
  };

  const handlePlaceLimitOrder = async () => {
    if (isLoadingFromStore) return;
    try {
      setIsLoading(true);
      const marketData = await handleValidationBeforePlacingOrder();
      if (marketData === 0) {
        setIsLoading(false);
        return;
      }
      const payload: any = await marketDataInstance.placeLimitOrder(
        typeOfRootOrder === 'BUY' ? BUY : SELL,
        enteredBaseValue,
        enteredQuoteValue
      );
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(payload, {
          gas_unit_price: "100",
          max_gas_amount: "6000"
        });
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
        if (error) {
          setIsLoading(false);
          toast(<NewMessageNotification link={hash} type={"error"} message={status} />);
          return
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        setIsLoading(false);
        toast(<NewMessageNotification link={hash} type={"success"} message={`Transaction Executed successfully`} />,);
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
        handleGetUserInfo()
        updateNeedToUpdateGlobalTrades(true);
        return;
      } else {
        setIsLoading(false);
        toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`} />,);
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
      }
    } catch (e: any) {
      setIsLoading(false);
      setEnteredQuoteValue('');
      setEnteredBaseValue('');
      setTotalValue('');
      if (e.name === 'Rejected') {
        return toast(<NewMessageNotification link={"none"} type={"error"} message={`Rejected by User`} />,);
      }
      const error = e as Error
      return toast(<NewMessageNotification link={"none"} type={"error"} message={error?.message} />);
    }
  };

  const handleOpenWalletConnect = () => {
    handleWalletConnect();
  };

  const handleEnteredValueForBase = async (enteredValue: any) => {
    if (enteredValue?.length > 8) {
      return
    }
    const validatedValue = onTokenAmountChange(enteredValue);
    setEnteredBaseValue(validatedValue);
  };

  const handleEnteredValueForQuote = async (enteredValue: any) => {
    if (enteredValue?.length > 8) {
      return
    }
    const validatedValue = onTokenAmountChange(enteredValue);
    setEnteredQuoteValue(validatedValue);
  };

  useEffect(() => {
    setEnteredBaseValue('')
    setTotalValue('')
    if (isObjectNonEmpty(selectedPairFromOrderBook)) {
      setEnteredQuoteValue(selectedPairFromOrderBook?.pair?.uiPrice)
      if (selectedPairFromOrderBook.type === 'ASK') {
        handleOnClickRootTab('SELL');
      } else {
        handleOnClickRootTab('BUY');
      }
      handleTypeOfOrder('limit');
      handleSettingPairEmptyAgain();
    }
    handleGetTradeVolume()
  }, [typeOfOrder, typeOfRootOrder, tradePairInfo, selectedPairFromOrderBook])

  const handleEnteredAmountAvailablityInMarketAccount = () => {
    if (userinfo !== undefined) {
      if (typeOfRootOrder === 'BUY') {
        const uiAmount = getUiAmount(
          totalValue,
          tradePairInfo.quoteCoinInfo.decimals
        );
        if (userinfo.quote_available >= uiAmount) {
          setOpenDeposit(false)
        } else {
          setOpenDeposit(true)
        }
      } else {
        const uiAmount = getUiAmount(
          enteredBaseValue,
          tradePairInfo.baseCoinInfo.decimals
        );
        if (userinfo.base_available >= uiAmount) {
          setOpenDeposit(false)
        } else {
          setOpenDeposit(true)
        }
      }
    }
  }

  const handleMax = () => {
    if (isLoadingFromStore) return;
    if (userinfo.quoteBalance === 0) return;
    if (userinfo.baseBalance === 0) return;
    if (typeOfRootOrder === 'BUY') {
      const amount: any = getPercentageAmount('Max', userinfo?.quoteBalance, userinfo.quoteDecimals, tradePairInfo?.quoteCoinInfo?.symbol, 'Deposit');
      if (amount === 'false') {
        return toast(<NewMessageNotification link={"none"} type={"error"} message={"Insufficient Balance To Make Max Transaction"} />);
      }
      setEnteredQuoteValue(amount?.toString())
    } else {
      const amount: any = getPercentageAmount('Max', userinfo?.baseBalance, userinfo.baseDecimals, tradePairInfo?.baseCoinInfo?.symbol, 'Deposit');
      if (amount === 'false') {
        return toast(<NewMessageNotification link={"none"} type={"error"} message={"Insufficient Balance To Make Max Transaction"} />);
      }
      setEnteredBaseValue(amount?.toString())
    }
  }

  useEffect(() => {
    if (totalValue === '' || totalValue === 0)
      setOpenDeposit(false)
    handleEnteredAmountAvailablityInMarketAccount();
  }, [totalValue, typeOfRootOrder])

  useEffect(() => {
    if (totalValue !== '' || totalValue !== 0) {
      handleGetTradeVolume()
    }
  }, [totalValue, orderBook, typeOfRootOrder])

  useEffect(() => {
    if (typeOfOrder === 'limit') {
      if (enteredQuoteValue === '' || enteredBaseValue === '') {
        setTotalValue('');
        return;
      }
      const value = parseFloat(enteredBaseValue) * parseFloat(enteredQuoteValue);
      setTotalValue(value)
    }
  }, [enteredQuoteValue, enteredBaseValue]);

  useEffect(() => {
    if (typeOfOrder === 'market') {
      if (enteredBaseValue === '') {
        setTotalValue('');
        return;
      }
      const timeoutId = setTimeout(() => handleGetTotalValue(enteredBaseValue), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [enteredBaseValue])

  const handleGetTotalValue = async (value: any) => {
    toast.dismiss()
    try {
      setIsLoading(true);
      if (isObjectNonEmpty(marketDataInstance)) {
        const tradeVolume = await marketDataInstance.getMarketPrice();
        if (isObjectNonEmpty(tradeVolume)) {
          if (typeOfRootOrder === 'BUY') {
            setTotalValue(parseFloat(tradeVolume?.bestAskPrice) * parseFloat(value));
            setIsLoading(false);
          } else {
            setTotalValue(parseFloat(tradeVolume?.bestBidPrice) * parseFloat(value));
            setIsLoading(false);
          }
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please try again after sometime`} />);
    }
  };

  const handleOnClickRootTab = (type: string) => {
    setTypeOfRootOrder(type);
  };

  const handleGetTradeVolume = async () => {
    if (isObjectNonEmpty(marketDataInstance)) {
      const tradeVolume = await marketDataInstance.getMarketPrice();
      setTradePrice(tradeVolume)
    }
  }

  const handleTypeOfOrder = (type: string) => {
    setTypeOfOrder(type);
  };

  useEffect(() => {
    updateIsLoading(isLoading)
  }, [isLoading])

  const handleOpenDeposit = () => {
    handleOpenDepositModal()
  }
  useEffect(() => {
    if (marketInputRef.current) {
      marketInputRef.current.focus();
    }
  }, [isLoading])
  useEffect(() => {
    if (typeOfOrder === 'market') {
      if (typeOfRootOrder === 'BUY') {
        const updatedPayReceiveValue = enteredBaseValue * parseFloat(tradePrice?.bestAskPrice)
        setTotalValue(updatedPayReceiveValue);
      } else if (typeOfRootOrder === 'SELL') {
        const updatedPayReceiveValue = enteredBaseValue * parseFloat(tradePrice?.bestBidPrice)
        setTotalValue(updatedPayReceiveValue);
      }
    }
  }, [tradePrice])
  return (
    <div className={`${isDarkMode ? 'xxl:border-[1px]  xxl:border-[#E3E8EF]  bxl:border-[1px]  bxl:border-[#E3E8EF]  xl:border-[1px]  xl:border-[#E3E8EF]  sxl:border-[1px]  sxl:border-[#E3E8EF]  lg:border-none  md:border-none sm:border-none xd:border-none xxl:bg-[#FCFDFE] bxl:bg-[#FCFDFE] xl:bg-[#FCFDFE] sxl:bg-[#FCFDFE] lg:bg-[#FCFDFE] md:bg-transparent sm:bg-transparent xd:bg-transparent' : 'xxl:border-[1px]  xxl:border-[rgba(255,255,255,0.10)]  bxl:border-[1px]  bxl:border-[rgba(255,255,255,0.10)]  xl:border-[1px]  xl:border-[rgba(255,255,255,0.10)]  sxl:border-[1px]  sxl:border-[rgba(255,255,255,0.10)]  lg:border-none  md:border-none sm:border-none xd:border-none xxl:bg-[#17181A] bxl:bg-[#17181A] xl:bg-[#17181A] sxl:bg-[#17181A] lg:bg-[#17181A] md:bg-transparent sm:bg-transparent xd:bg-transparent'} xxl:h-[30rem] bxl:h-[30rem] xl:h-[30rem] sxl:h-[30rem] lg:h-[30rem] md:h-full sm:h-full xd:h-full   w-full    rounded-[1rem] `}>
      <div className="  z-[0] flex flex-col gap-4  shadow-[0rem_2.5rem_2rem_-3.313rem_rgba(0,0,0,0.06)]   border-none    w-full ">
        <div className=" flex flex-col   items-center justify-between ">
          <div className={`${isDarkMode ? '!border-b-[#E3E8EF]  !bg-[#EFF7F8] !text-[#777879] ' : ' !text-[#A5A5A6] !border-b-[rgba(255,255,255,0.10)]  !bg-[#111213]'}  !border-t-transparent !border-r-transparent !border-l-transparent border border-solid  flex flex-row justify-between items-center   xxl:!rounded-[1rem_1rem_0rem_0rem] bxl:!rounded-[1rem_1rem_0rem_0rem] xl:!rounded-[1rem_1rem_0rem_0rem] sxl:!rounded-[1rem_1rem_0rem_0rem] lg:!rounded-[1rem_1rem_0rem_0rem] md:!rounded-[0rem] sm:!rounded-[0rem] xd:!rounded-[0rem]     md:h-auto     w-full`}>
            <div onClick={() => {
              handleOnClickRootTab('BUY');
            }}
              className={`${typeOfRootOrder === 'BUY' ? ` your_trade text-center xxl:!rounded-[1rem_0rem_0rem_0rem] bxl:!rounded-[1rem_0rem_0rem_0rem] xl:!rounded-[1rem_0rem_0rem_0rem] sxl:!rounded-[1rem_0rem_0rem_0rem] lg:!rounded-[1rem_0rem_0rem_0rem] md:!rounded-[0rem] sm:!rounded-[0rem] xd:!rounded-[0rem]  text-[14px] font-inter !font-[800]  ${isDarkMode ? 'bg-[#FCFDFE]   text-[#0C0C0D]' : 'bg-[rgba(255,255,255,0.06)] text-[#fff]   '} ` : ` !font-[400]   text-[14px] font-inter  ${isDarkMode ? 'bg-[#EFF7F8]   text-[#777879]' : 'bg-[#111213] text-[#A5A5A6]   '}`}  flex flex-row justify-center items-center cursor-pointer  w-[50%] `}
            >
              {t('buy')}
            </div>
            <div
              onClick={() => {
                handleOnClickRootTab('SELL');
              }}
              className={`${typeOfRootOrder === 'SELL' ? ` your_trade text-center xxl:!rounded-[0rem_1rem_0rem_0rem] bxl:!rounded-[0rem_1rem_0rem_0rem] xl:!rounded-[0rem_1rem_0rem_0rem] sxl:!rounded-[0rem_1rem_0rem_0rem] lg:!rounded-[0rem_1rem_0rem_0rem] md:!rounded-[0rem] sm:!rounded-[0rem] xd:!rounded-[0rem]  text-[14px] font-inter  !font-[800] ${isDarkMode ? 'bg-[#FCFDFE]   text-[#0C0C0D]' : 'bg-[rgba(255,255,255,0.06)] text-[#fff]   '} ` : ` !font-[400]  text-[14px] font-inter ${isDarkMode ? 'bg-[#EFF7F8]   text-[#777879]' : 'bg-[#111213] text-[#A5A5A6]   '}`}   flex flex-row justify-center items-center w-[50%] cursor-pointer`}
            >
              {t('sell')}
            </div>
          </div>
          <div className="flex flex-col gap-2 items-center !justify-between w-full h-full p-[0.5rem] ">
            <div className="flex flex-row h-8  items-center justify-between rounded-[1rem] !w-full ">
              <div className="flex flex-row items-center justify-start  ">
                <div className={`text-left text-[#777879] text-[12px]  font-[400] `}>Order Type</div>
              </div>
              <div className="flex flex-row   gap-2 items-center justify-start py-2 ">
                <div

                  className={`${typeOfOrder === 'market' ? typeOfRootOrder === 'SELL' ? `${isDarkMode ? '!border-[#088AB2]  bg-[#CFF9FE]  text-[#4A4B4D]' : '!border-[#088AB2]  bg-[#0C0C0D] text-[#FFF]'} ` : ` ${isDarkMode ? ' text-[#4A4B4D]   bg-[#E4F2F3] !border-[#0E9384] ' : ' text-[#FFFFFF]   bg-[#0C0C0D] !border-[#2ED3B7]'}  border-[1px] ` : ` ${isDarkMode ? 'bg-[#FCFDFE] border-[#E3E8EF] text-[#4A4B4D]' : 'bg-[#17181A] border-[rgba(255,255,255,0.10)] text-[#fff]'}`
                    } cursor-pointer border-[1px]   text-center text-[12px] font-[500] w-auto p-[0.5rem_0.8rem] rounded-[0.5rem] `}
                  onClick={() => {
                    handleTypeOfOrder('market');
                  }}
                >
                  {t('market')}
                </div>
                <div
                  className={`${typeOfOrder === 'limit' ? typeOfRootOrder === 'SELL' ? `${isDarkMode ? '!border-[#088AB2]  bg-[#CFF9FE] text-[#4A4B4D] ' : '!border-[#088AB2]  bg-[#0C0C0D] text-[#FFF] '} ` : ` ${isDarkMode ? ' text-[#4A4B4D]   bg-[#E4F2F3] !border-[#0E9384] ' : ' text-[#FFFFFF]   bg-[#0C0C0D] !border-[#2ED3B7]'}  border-[1px] ` : ` ${isDarkMode ? 'bg-[#FCFDFE] border-[#E3E8EF] text-[#4A4B4D]' : 'bg-[#17181A] border-[rgba(255,255,255,0.10)] text-[#fff]'}`
                    } cursor-pointer border-[1px]   text-center text-[12px] font-[500] w-auto p-[0.5rem_0.8rem] rounded-[0.5rem]  `}

                  onClick={() => {
                    handleTypeOfOrder('limit');
                  }}
                >
                  {t('limit')}
                </div>
              </div>
            </div>
            <div className="w-full  flex flex-col !justify-between items-center h-full">
              <div className='w-full  flex flex-col  gap-[0.5rem] '>
                <div className={` ${isDarkMode ? 'bg-[#F2F9F9] border-[#E3E8EF]' : 'bg-[#111213] border-[rgba(255,255,255,0.10)]'} w-full  border-[1px] rounded-[1rem]  p-[0.5rem] flex flex-col items-start justify-start gap-[0.5rem] `}>
                  <div className={` flex ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'}   border-none rounded-[0.5rem] flex-col items-start justify-center px-[1rem] py-[0.8rem] rounded-[1rem] w-full sm:w-full`}>
                    <div className="flex flex-col items-center justify-start w-full bg-transparent">
                      <div className="flex flex-row gap-4 h-full items-center justify-start w-full bg-transparent">
                        <div className={`flex flex-row justify-start items-center gap-[0.5rem]  ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'} w-[50%] text-[12px] font-manrope font-[400]  text-left`}>Price  <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`}>{tradePairInfo?.quoteCoinInfo?.symbol}</div></div>
                        {(typeOfOrder === 'market') ? tradePrice !== undefined && <input className={` ${isDarkMode ? ' !text-[#000] ' : ' !text-[#FFF] '} cursor-not-allowed text-2xl w-[50%] bg-transparent font-manrope   outline-none text-[18px] font-[400]     text-right  `}
                          value={tradePrice && typeOfRootOrder === 'BUY' ? parseFloat(tradePrice?.bestAskPrice)?.toFixed(9).replace(/\.?0*$/, '') : parseFloat(tradePrice?.bestBidPrice)?.toFixed(9).replace(/\.?0*$/, '')} disabled /> :
                          <input
                            className={` text-2xl w-[50%] bg-transparent font-manrope ${isDarkMode ? ' !text-[#000] ' : ' !text-[#FFF] '} outline-none text-[18px] font-[400]     text-right  `}
                            placeholder="0.00"
                            min="0"
                            disabled={isLoading}
                            value={enteredQuoteValue}
                            autoFocus={true}
                            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                            onPaste={preventPasteNegativeNumber}
                            onChange={(e) => {
                              handleEnteredValueForQuote(e.target.value);
                            }}
                          />
                        }
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row justify-between items-center w-full px-[0.5rem]'>
                    <div className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'} font-manrope font-[400] text-[10px] w-auto pb-1 flex flex-row justify-start items-center gap-[0.5rem]`}>Balance <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`}>{userinfo?.quoteBalance ? userinfo?.quoteBalance?.toFixed(9).replace(/\.?0*$/, '') : 0.00} {tradePairInfo?.quoteCoinInfo?.symbol}</div> </div>
                    {/* {typeOfRootOrder === 'BUY' && typeOfOrder === 'limit' && <div className={`${isDarkMode ? 'bg-[#E4F2F3] text-[#0E9384]' : 'bg-[rgba(0,255,255,0.10)] text-[rgba(0,255,255,0.40)]'} bg-[#121926] text-[#088AB2]  cursor-pointer    font-manrope text-[10px]  rounded-[0.5rem] p-[0.125rem_0.5rem] font-[700]`} onClick={() => handleMax()}>MAX</div>}
                 */}
                  </div>
                </div>
                <List className="flex flex-col gap-2 items-start w-full bg-transparent" orientation="vertical">
                  <div className={` ${isDarkMode ? 'bg-[#F2F9F9] border-[#E3E8EF]' : 'bg-[#111213] border-[rgba(255,255,255,0.10)]'} w-full  border-[1px] rounded-[1rem]  p-[0.5rem] flex flex-col items-start justify-start gap-[0.5rem] `}>
                    <div className={` flex ${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#0C0C0D]'}   border-none rounded-[0.5rem] flex-col items-start justify-center px-[1rem] py-[0.8rem] rounded-[1rem] w-full sm:w-full`}>
                      <div className="flex flex-col items-center justify-start w-full bg-transparent">
                        <div className="flex flex-row gap-4 h-full items-center justify-start w-full bg-transparent">
                          <div className={`flex flex-row justify-start items-center gap-[0.5rem]  ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'} w-[50%] text-[12px] font-manrope font-[400]  text-left`}>Amount  <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`}>{tradePairInfo?.baseCoinInfo?.symbol}</div></div>
                          <input
                            ref={marketInputRef}
                            className={` text-2xl w-[50%] bg-transparent font-manrope ${isDarkMode ? 'text-[#000]' : 'text-[#FFF]'} outline-none text-[18px] font-[400]  text-right  `}
                            placeholder="0.00"
                            min="0"
                            disabled={isLoading}
                            autoFocus={true}
                            value={enteredBaseValue}
                            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                            onChange={(e) => { handleEnteredValueForBase(e.target.value) }}
                            onPaste={preventPasteNegativeNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full px-[0.5rem]'>
                      <div className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'} font-manrope font-[400] text-[10px] w-auto pb-1 flex flex-row justify-start items-center gap-[0.5rem]`}>Balance <div className={`${isDarkMode ? 'text-[#777879]' : 'text-[#A5A5A6]'}`}>{userinfo?.baseBalance ? userinfo?.baseBalance?.toFixed(9).replace(/\.?0*$/, '') : 0.00} {tradePairInfo?.baseCoinInfo?.symbol}</div> </div>
                      {/* {typeOfRootOrder === 'SELL' && typeOfOrder === 'limit' && <div className={`${isDarkMode ? 'bg-[#E4F2F3] text-[#088AB2]' : 'bg-[#121926] text-[#088AB2]'} bg-[#121926] text-[#088AB2]  cursor-pointer    font-manrope text-[10px]  rounded-[0.5rem] p-[0.125rem_0.5rem] font-[700]`} onClick={() => handleMax()}>MAX</div>}
                    */}
                    </div>
                  </div>
                  <div className={` ${isDarkMode ? ' border-[#E3E8EF]' : ' border-[rgba(255,255,255,0.10)] '} bg-transparent   border-[1px] rounded-[1rem]  flex flex-col gap-2 items-center justify-start   py-[0.5rem] px-[1rem]  w-full flex flex-col gap-[0.5rem]`}>
                    <div className="flex  flex-row  items-center justify-between bg-transparent w-full">
                      <span className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'} text-[0.75rem] font-manrope font-[400] text-left w-full`}>{typeOfRootOrder === 'BUY' ? 'You Pay' : 'You Receive'}</span>
                      <input
                        className={` text-2xl w-[50%] px-1 bg-transparent font-manrope ${isDarkMode ? '!text-[#4A4B4D]' : '!text-[rgba(255,255,255,0.80)]'}  outline-none text-[0.75rem] font-[400]   text-right  `}
                        value={totalValue && totalValue?.toFixed(9).replace(/\.?0*$/, '')}
                        readOnly
                        placeholder='0.00'
                      />
                      <span className={` ${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'}  text-[12px] font-manrope  font-[400]`}>{tradePairInfo?.quoteCoinInfo?.symbol}</span>
                    </div>
                    <div className="flex  flex-row  items-center justify-between bg-transparent w-full ">
                      <span className={`${isDarkMode ? 'text-[#A5A5A6]' : 'text-[#777879]'}  !w-[40%] text-[0.72rem] font-manrope font-[400] text-left w-full`}>Estimated Fees</span>
                      <span className={`${isDarkMode ? '!text-[#4A4B4D]' : '!text-[rgba(255,255,255,0.80)]'} !w-[60%] text-[0.7rem] font-manrope  font-[400] flex flex-row items-end justify-end text-right`}> {connected ? 0.006 : 0} APT</span>
                    </div>
                  </div>
                </List>
              </div>
            </div>
            <div className='h-2.5'>{openDeposit && <div className=" text-[#F97066] text-xs w-auto"> Insufficient {`${typeOfRootOrder === 'BUY' ? tradePairInfo?.quoteCoinInfo?.symbol : tradePairInfo?.baseCoinInfo?.symbol}`}</div>}</div>
            <Button
              onClick={() => {
                !connected && !isWalletConnected ? handleOpenWalletConnect() : openDeposit ? handleOpenDeposit() : typeOfOrder === "market" ? handlePlaceMarketOrder() : handlePlaceLimitOrder();
              }}
              className={`${isLoadingFromStore ? 'cursor-not-allowed ' : 'cursor-pointer'} && ${typeOfRootOrder === 'BUY' ? 'buy-button' : 'buy-button-blue'}  w-full `}
            >
              <div className={`${isDarkMode ? 'buy-button-inner_blue' : 'buy-button-inner'} w-full h-full py-[0.938rem] flex flex-row justify-center items-center    font-bold leading-[normal] py-[0.938rem] rounded-[1rem] text-center text-gray-900 text-xl w-full`}>
                {isLoading ? (
                  <Lottie
                    loop
                    animationData={Kanaloader}
                    play
                    className="w-[6.5rem] h-[1.2rem] flex justify-center items-center"
                  />
                ) : <span className={`${typeOfRootOrder === 'BUY' ? ` ${isDarkMode ? 'button-text_dark' : 'button-text'} ` : ` ${isDarkMode ? 'button-text-blue_dark' : 'button-text-blue'}`}`}>
                  {!connected && !isWalletConnected ? 'Connect Wallet' : openDeposit ? 'Deposit' : typeOfRootOrder === 'BUY' ? 'Place buy order' : 'Place sell order'}
                </span>
                }
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySell;
